import { defineStore } from 'pinia';
import cusStorage from '@/utils/cusStorage';
import imageSource from '@/utils/imageSource';

// 避免同時有多個彈窗或展開彈出
const closeOtherPop = (self, closeList) => {
  closeList.forEach((statusStr) => {
    self[statusStr] = false;
  });
};

export const useSiteStore = defineStore({
  id: 'site',
  state: () => ({
    isLogin: false,
    // memberLevel: 1,
    scrollOffset: 0,
    appRoot: null,
    scrollEle: null,
    backgroundImage: null,
    showSearchPop: false,
    showMobileMenuContent: false,
    showMobileMusicPlayer: false,
    showMobileFunction: false,
    deviceType: 'init',
    autoFocusInput: false,
    token: '',
    memberInfo: {},
    loginExpireTime: null,
    searchBackgroundImage: imageSource(''),
    // for 歌單、收藏、專輯
    showMobilePlaylistFunction: false,
    mobilePlaylistFunctionData: {},
    mobilePlaylistFunctionList: [],
    // for 曲目
    showMobilePlaylistTrackFunction: false,
    mobilePlaylistTrackFunctionData: {},
    mobilePlaylistTrackFunctionList: [],
    mobilePlaylistTrackFunctionType: [],
  }),

  actions: {
    setIsLogin(status) {
      this.isLogin = status;
    },
    handleLogout() {
      this.isLogin = false;
      this.token = '';
      this.memberInfo = {};
      cusStorage.removeItem('token');
      cusStorage.removeItem('token', null, true); // remove sessionStorage
      cusStorage.removeItem('memberInfo');
      cusStorage.removeItem('memberInfo', null, true);
      cusStorage.removeItem('loginExpireTime');
      // cusStorage.setItem('musicPlayerStyle', 'full');
    },
    setToken(token, setToSessionStorage) {
      this.token = token;
      this.isLogin = true;
      cusStorage.setItem('token', token, setToSessionStorage);
    },
    setLoginExpireTime(value) {
      this.loginExpireTime = value;
      cusStorage.setItem('loginExpireTime', value);
    },
    setMemberInfo(memberInfo) {
      this.isLogin = true;
      this.memberInfo = memberInfo;
      cusStorage.setItem('memberInfo', memberInfo);
    },
    setScrollOffset(status) {
      this.scrollOffset = status;
    },
    setAppRoot(ele) {
      this.appRoot = ele;
    },
    setScrollEle(ele) {
      this.scrollEle = ele;
    },
    setBackgroundImage(image) {
      this.backgroundImage = image;
    },
    setSearchBackgroundImage(image) {
      this.searchBackgroundImage = image;
    },
    toggleShowSearchPop(status) {
      this.showSearchPop = status;
      closeOtherPop(this, ['showMobileMenuContent', 'showMobileMusicPlayer', 'showMobileFunction']);

      if (status) {
        this.autoFocusInput = true;
      } else {
        this.autoFocusInput = false;
      }
    },

    toggleShowMobileMenuContent(status) {
      this.showMobileMenuContent = status;
      // closeOtherPop(this, ['showSearchPop', 'showMobileMusicPlayer', 'showMobileFunction']);
      closeOtherPop(this, ['showMobileMusicPlayer', 'showMobileFunction']);
    },
    toggleShowMobileMusicPlayer(status) {
      this.showMobileMusicPlayer = status;
      // closeOtherPop(this, ['showMobileMenuContent', 'showSearchPop', 'showMobileFunction']);
      closeOtherPop(this, ['showMobileMenuContent', 'showMobileFunction']);
    },
    toggleShowMobileFunction(status) {
      this.showMobileFunction = status;
      // closeOtherPop(this, ['showMobileMenuContent', 'showSearchPop']);
      closeOtherPop(this, ['showMobileMenuContent']);
    },

    // for 歌單、收藏、專輯
    toggleShowMobilePlaylistFunction(status) {
      this.showMobilePlaylistFunction = status;
    },
    setMobilePlaylistFunctionData(data) {
      this.mobilePlaylistFunctionData = data;
    },
    setMobilePlaylistFunctionList(list) {
      this.mobilePlaylistFunctionList = list;
    },
    // for 曲目
    toggleShowMobilePlaylistTrackFunction(status) {
      this.showMobilePlaylistTrackFunction = status;
    },
    setMobilePlaylistTrackFunctionData(data) {
      this.mobilePlaylistTrackFunctionData = data;
    },
    setMobilePlaylistTrackFunctionList(list) {
      this.mobilePlaylistTrackFunctionList = list;
    },
    setMobilePlaylistTrackFunctionType(type) {
      this.mobilePlaylistTrackFunctionType = type;
    },

    // setAutoFocusInput(status) {
    //   this.autoFocusInput = status;
    // },

    setDeviceType() {
      const width = document.body.clientWidth;

      const typeCollect = [];

      const rwdTyepMatching = {
        320: 'init',
        576: 'mobile',
        768: 'portrait',
        1024: 'tablet',
        1440: 'sm-pc',
        1600: 'pc',
        2560: 'lg-pc',
      };

      const widthList = [320, 576, 768, 1024, 1440, 1600, 2560];

      widthList.forEach((w) => {
        if (width >= w) {
          typeCollect.push(rwdTyepMatching[String(w)]);
        }
      });

      this.deviceType = typeCollect;
    },
  },
});

export const a = '';
