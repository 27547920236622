import request from '@/api';
import { uploadImage } from '@/api/gql/uploadImage.gql';

export const uploadImageAPI = async (payload) => {
  const res = await request(uploadImage, payload, 'file');

  return res;
};

export const a = 0;
