import { restfulReq } from '@/api';

// export const downloadAPI = async (trackUid) => {
//   const res = await restfulReq({
//     baseURL: '/track/downloadTrack',
//     data: {
//       trackUid,
//     },
//     auth: true,
//     method: 'POST',
//     responseType: 'blob',
//   });

//   return res;
// };

export const downloadAllAPI = async (payload) => {
  const res = await restfulReq({
    baseURL: '/track/downloadTracks',
    data: payload,
    auth: true,
    method: 'POST',
    responseType: 'blob',
    // onDownloadProgress(args) {
    //   console.log('args...', args);
    //   // Do whatever you want with the Axios progress event
    // },
  });

  console.log('res', res);

  return res;
};

export const a = 0;
