export default {
  400001: '帐号或密码不正确',
  400002: '用户当前状态不符',
  400003: '此邮箱已注册过',
  400004: '新密码不一致',
  400005: '旧密码错误',
  400006: '用户不存在或被停用',
  400007: '图片格式不支援，需为 jpg, jpeg, png',
  400008: '无法取得音乐档案',
  400009: '已在收藏中',
  400010: '全部曲目已在收藏中',
  400011: 'Oops!出错了!',
  400012: '下載數量已達限額，无法下载',
  400013: '有效期间不符合，无法下载',
  400014: '无此歌曲的下载权限',
  400015: '没有下载内容',
  400016: '下载档案不存在，无法下载',
  400017: '下载不合法，不允许下载该类型',
  401001: '权限验证信息缺失或不合法',
  401002: '帐号不存在或被冻结',
  403001: '权限不匹配',
  404001: 'Oops!出错了!',
  404002: 'Oops!出错了!',
  413001: '上传档案容量过大，限制1MB',
  500001: 'Oops!出错了!',
  500002: 'Oops!出错了!',
  500004: 'Oops!出错了!',
};
