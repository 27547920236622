<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-message-provider placement="bottom">
      <div class="app-root" @scroll="handleScroll" ref="appRoot">
        <!-- <transition name="fade" >
          <router-view />
        </transition> -->

        <AppContent>

          <!-- <template v-if="ready"> -->
          <router-view v-slot="{ Component }">
            <transition name="fade">
              <component :is="Component" />
            </transition>
          </router-view>

          <music-player v-show="showMusicPlayer" />

          <Transition name="slide-fade">
            <!-- v-if 為了觸發 內部 onMounted 之後的自動 focus -->
            <menu-search-pop v-if="showSearchPop" />
          </Transition>
          <Transition name="slide-fade">
            <mobile-menu-content v-show="showMobileMenuContent" />
          </Transition>
          <mobile-music-player v-show="showMobileMusicPlayer && showMusicPlayer" />
          <!-- <mobile-function v-show="showMobileFunction" /> -->

          <mobile-playlist-function />
          <mobile-playlist-track-function />
          <!-- </template> -->
        </AppContent>
      </div>
    </n-message-provider>
  </n-config-provider>
</template>

<script>
import { defineComponent, onMounted, ref, computed, watch, onUnmounted } from 'vue';
import { NConfigProvider, NMessageProvider } from 'naive-ui';
import { debounce } from 'lodash-es';
import { useRoute } from 'vue-router';

import { useI18n } from 'vue-i18n';
import MusicPlayer from '@/components/Global/MusicPlayer/index.vue';
import MenuSearchPop from '@/layout/MenuSearchPop.vue';
import AppContent from '@/layout/AppContent.vue';
import MobileMenuContent from '@/layout/MobileMenuContent.vue';
import MobileMusicPlayer from '@/components/Global/MusicPlayer/MobileMusicPlayer.vue';
import { useSiteStore } from '@/store/site';
import MobilePlaylistFunction from '@/components/Global/MusicPlayer/MobilePlaylistFunction.vue';
import MobilePlaylistTrackFunction from '@/components/Global/MusicPlayer/MobilePlaylistTrackFunction.vue';
// import useAuth from '@/hooks/useAuth';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useSearchStore } from '@/store/search';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import useRequest from '@/hooks/useRequest';
import { getAnnouncementAPI } from '@/api/getAnnouncement';
import usePopInfo from '@/hooks/usePopInfo';
import cusStorage from '@/utils/cusStorage';
// import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({

  name: 'App',
  components: {
    MusicPlayer,
    NConfigProvider,
    NMessageProvider,
    MenuSearchPop,
    MobileMenuContent,
    MobileMusicPlayer,
    MobilePlaylistFunction,
    MobilePlaylistTrackFunction,
    AppContent,
  },

  setup() {
    const { t } = useI18n();
    const ready = ref(false);
    const timer = ref(null);
    const siteStore = useSiteStore();
    const searchStore = useSearchStore();
    const route = useRoute();
    const newMusicPlayerStore = useNewMusicPlayerStore();
    const { request } = useRequest(getAnnouncementAPI);
    const content = 'veu meta test content...';
    const { announcementPopInfo } = usePopInfo();
    const announcementInfo = ref(null);

    // const { handleAuth } = useAuth();
    // handleAuth();
    const appRoot = ref(null);
    const themeOverrides = {
      common: {
        primaryColor: '#BA2424',
        primaryColorHover: '#BA2424',
      },
    };

    // const musicPlayerStore = useMusicPlayerStore();
    // musicPlayerStore.init();

    // const musicPlayerVisible = computed(() => musicPlayerStore.playQueue?.length);

    const reCalcWindowWidthDebounce = debounce(() => {
      siteStore.setDeviceType();
    }, 100);

    // 手機版會將網頁狀態列計入高度，會導致一些滑動bug，所以手動設置一個
    function set100VhVar() {
      const vh = window.innerHeight / 100;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const showMusicPlayer = computed(() => !['validate'].includes(route.name));
    const routeName = computed(() => route.name);

    const handleMessage = () => {
      ready.value = true;
    };

    const showAnnouncement = () => {
      if (!announcementInfo.value) {
        return;
      }

      if (announcementInfo.value.getAnnon?.content) {
        const storageUid = cusStorage.getItem('announcementUid');

        if (storageUid === announcementInfo.value.getAnnon.updateUid) {
          return;
        }

        announcementPopInfo({
          desc: announcementInfo.value.getAnnon.content,
          data: {
            uid: announcementInfo.value.getAnnon.updateUid,
            dontShowAgain: t('global.dontShowAgain'),
          },
        });
      }
    };

    const handleAnnouncement = async () => {
      try {
        const [, res] = await request();
        announcementInfo.value = res;
        // showAnnouncement('api');
      } catch (e) {
        console.log('e', e);
      }
    };

    onMounted(() => {
      siteStore.setAppRoot(appRoot.value);
      siteStore.setDeviceType();
      window.addEventListener('resize', reCalcWindowWidthDebounce);

      set100VhVar();
      handleMessage();
      // console.log(route.name, route.name === 'explore-albums-info', route.query?.tu, isNumber(route.query?.t));
      // newMusicPlayerStore.init();

      // 馬上執行，route 還抓不到內容
      timer.value = setTimeout(() => {
        newMusicPlayerStore.init();
      }, 400);

      handleAnnouncement();
    });

    const showMobileMusicPlayer = computed(() => siteStore.showMobileMusicPlayer);

    onUnmounted(() => {
      clearTimeout(timer.value);
    });

    watch(showMobileMusicPlayer, (newVal) => {
      if (newVal) {
        document.body.classList.add('prevent-scroll-reload');
      } else {
        document.body.classList.remove('prevent-scroll-reload');
      }
    });

    watch(() => routeName.value, () => {
      showAnnouncement('watch');
    });

    return {
      appRoot,
      themeOverrides,
      showMusicPlayer,
      showSearchPop: computed(() => siteStore.showSearchPop),
      showMobileMenuContent: computed(() => siteStore.showMobileMenuContent),
      showMobileFunction: computed(() => siteStore.showMobileFunction),
      showMobileMusicPlayer,
      searchStore,
      content,
      // musicPlayerVisible,
      ready,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/styles/share/animate.scss';

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  color: $c-white;
  z-index: 1000;
}

.ly-full-header-layout {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // display: flex;
  // flex-direction: column;
}

.ly-fh {
  &__header {
    width: 100%;
    // height: 52px;
    // position: fixed;
    // z-index: 100;
    // top: 0;
    // left: 0;
  }

  &__ab-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    // @include padding(60px 0 0);

    // &__aside {
    //   flex: none;
    //   width: 200px;
    //   height: 100%;
    //   overflow-y: auto;
    // }

    &__body {
      flex: 1;
      width: 100%;
      height: 100%;
      // overflow-y: auto;
    }
  }
}
</style>
