<template>
  <div class="c-share function-icon" @click="share">
    <tooltip :label="$t('myMusic.shareTrack')">
      <div class="function-icon" >
        <div class="function-icon__img function-icon__img--share"></div>
        <span class="function-icon__label" v-if="label"> {{$t('myMusic.share')}} </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <model-base-layout :title="$t('myMusic.shareTrack')" decoLine>
        <share-music :data="data" @close="close"/>
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref,
  // watch
} from 'vue';
import { NModal } from 'naive-ui';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import ShareMusic from '@/components/Global/Popup/ShareMusic.vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
// import { getTrackAPI } from '@/api/getTrack';
import Tooltip from '@/components/Global/Tooltip.vue';
// import useRequest from '@/hooks/useRequest';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'Share',
  components: {
    NModal,
    ModelBaseLayout,
    ShareMusic,
    Tooltip,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    isPlayMusic: {
      type: Boolean,
      defualt: false,
    },

  },
  setup(props) {
    // const { request: reqTrack, response: trackData } = useRequest(getTrackAPI);
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();
    const modelStatus = ref(false);

    // watch(trackData, (v) => {
    //   const [, res] = v;
    //   if (res?.getTrack) {
    //     musicPlayerStore.setShareData('albumUid', res?.getTrack?.album?.albumUid);
    //   }
    // });

    const share = () => {
      if (props.isPlayMusic) {
        const trackUid = props.data?.trackUid || newMusicPlayerStore.musicInfo.trackUid;
        const albumUid = props.data?.albumUid || newMusicPlayerStore.musicInfo.albumUid;
        newMusicPlayerStore.setShareData(albumUid, trackUid);
      }
      modelStatus.value = true;
    };

    const close = () => {
      modelStatus.value = false;
    };

    return {
      modelStatus,
      share,
      close,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
