<template>
  <div class="c-share-music">
    <div class="action-row">
      <div class="start-time">
        <n-checkbox v-model:checked="startTime.set" :label="$t('myMusic.fromWhere')" />
        <div class="start-time__field">
          <!-- 00:00 ~ 99:59 有超過 100分鐘的歌的話 之後再改-->
          <input class="start-time__field__text" v-model="startTime.time" v-maska="{
            mask: 'TT:AB',
            tokens: { T: { pattern: /[0-9]/ }, A: { pattern: /[0-5]/ }, B: { pattern: /[0-9]/ } },
          }" @keydown.stop />
        </div>
      </div>
      <div class="pc-btn-wrap pc-show">
        <div class="btn btn--email">
          <mi-button icon="email" @click.prevent="handleEmail">
            <span class="btn__text">{{ $t('global.email') }}</span>
          </mi-button>
        </div>
        <div class="btn btn--copy-link">
          <mi-button icon="link" @click.prevent="handleCopy">
            <span class="btn__text">{{ $t('myMusic.copyLink') }}</span>
          </mi-button>
        </div>
      </div>
    </div>
    <p class="err-msg" v-if="errMsg">{{ $t('myMusic.pleaseEnterCurrectTimeFormat') }}（MM:SS）</p>
    <div class="music-link">
      <input class="music-link__input" v-model="musicLink" disabled @keydown.stop />
      <!-- v-model="musicLink" -->
    </div>
    <div class="mobile-wrap mobile-show">
      <div class="btn btn--email">
        <mi-button icon="email" @click.prevent="handleEmail">
          <span class="btn__text">{{ $t('global.email') }}</span>
        </mi-button>
      </div>
      <div class="btn btn--copy-link">
        <mi-button icon="link" @click="handleCopy">
          <span class="btn__text">{{ $t('myMusic.copyLink') }}</span>
        </mi-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { NCheckbox, useMessage } from 'naive-ui';
import { useI18n } from 'vue-i18n';
import { useClipboard } from '@vueuse/core';
import MiButton from '@/components/Global/MiButton.vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import useMusicPlayer from '@/hooks/useMusicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'ShareMusic',
  components: {
    NCheckbox,
    MiButton,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const message = useMessage();
    const route = useRoute();
    const { handleFromExternalTrack } = useMusicPlayer();
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    const startTime = reactive({
      set: false,
      time: '00:00',
    });

    watch([() => route?.query?.tu, () => route?.query?.t], ([trackUid]) => {
      handleFromExternalTrack(trackUid);
    });

    // console.log('musicPlayerStore.shareTrack.albumUid', musicPlayerStore.shareTrack.albumUid);
    // console.log('props.data.originUid', props.data.originUid);

    // props.data?.url 是主題用的
    const albumUid = computed(() => props.data?.url || props.data.track?.album?.albumUid || props.data.albumUid || props.data?.album?.albumUid || props.data.originUid || newMusicPlayerStore.shareData.albumUid);
    const trackUid = computed(() => props.data.track?.trackUid || props.data.trackUid || props.data?.track?.trackUid || newMusicPlayerStore.shareData.trackUid);

    console.log('albumUid', albumUid.value, props.data.track?.album?.albumUid, props.data.originUid, props.data.albumUid);
    console.log('trackUid', trackUid.value);

    // const domain = reactive('http://pointgroup.com/mat749-1');
    const domain = window.location.origin;

    // console.log('1111', albumUid.value);
    // console.log('2222', trackUid.value);
    const preUrl = computed(() => `${domain}/explore/albums-info/${albumUid.value}?tu=${trackUid.value}`);

    const formatTimeToSecond = (time) => {
      const [mins, seconds] = time.split(':');

      return Number(mins * 60) + Number(seconds);
    };

    const musicLink = computed(() => {
      if (startTime.set) {
        return `${preUrl.value}&t=${formatTimeToSecond(startTime.time)}`;
      }

      return preUrl.value;
    });

    const errMsg = computed(() => {
      const regExp = /^\d{2}:\d{2}$/;
      return !regExp.test(startTime.time);
    });

    const copyLink = computed(() => {
      let shareLink = '';
      console.log('startTime.set', startTime.set);
      if (startTime.set) {
        shareLink = `${domain}/explore/albums-info/${albumUid.value}?tu=${trackUid.value}&t=${formatTimeToSecond(startTime.time)}`;
      } else {
        shareLink = `${domain}/explore/albums-info/${albumUid.value}?tu=${trackUid.value}`;
      }

      return shareLink;
    });

    const { copy } = useClipboard({ source: copyLink });

    const handleCopy = () => {
      copy().then(() => {
        message.success(t('msg.copySuccess'));
        emit('close');
      });
    };

    const handleEmail = () => {
      let shareLink = '';
      if (startTime.set) {
        shareLink = `${domain}/explore/albums-info/${albumUid.value}?tu=${trackUid.value}&t=${formatTimeToSecond(startTime.time)}`;
      } else {
        shareLink = `${domain}/explore/albums-info/${albumUid.value}?tu=${trackUid.value}`;
      }

      const bodyEle = document.querySelector('body');
      const aLinkEle = document.createElement('a');

      const emailBody = encodeURIComponent(`${t('myMusic.shareTrackEmailWording.t2')} \n ${shareLink} \n\n ${t('myMusic.shareTrackEmailWording.t3')}`);

      aLinkEle.setAttribute(
        'href',
        `mailto:?subject=${t('myMusic.shareTrackEmailWording.t1')} ${encodeURIComponent(
          // 主題內的
          props.data?.targetTitle ||
          // 專案選曲內-我的曲目的
          props.data?.track?.trackTitleDisplay ||
          // 播放器的
          props.data?.trackName ||
          // 品牌內的
          props.data?.tracks?.[0]?.trackTitleDisplay ||
          props.data?.trackTitleDisplay ||
          props.data?.track?.trackName ||
          props.data?.title,
        )}&body=${emailBody}`,
      );
      aLinkEle.style.display = 'none';
      bodyEle.appendChild(aLinkEle);
      aLinkEle.click();
      bodyEle.removeChild(aLinkEle);

      emit('close');
    };

    return {
      startTime,
      musicLink,
      errMsg,
      handleEmail,
      preUrl,
      message,
      t,
      handleCopy,
      copyLink,
      albumUid,
      trackUid,
    };
  },
  // methods: {

  //   handleCopy(e, link) {
  //     console.log('this.musicLink', link.value);
  //     this.$copyText(link).then(
  //       () => {
  //         this.message.success(this.t('msg.copySuccess'));
  //         // this.$emit('close');
  //       },
  //       () => {
  //         this.message.error(this.t('msg.copyFail'));
  //       },
  //     );
  //   },
  // },
});
</script>
<style lang="scss" scoped>
.c-share-music {
  margin-top: 20px;
  @include padding(0 24px);
}

.action-row {
  @include flex(space-between);
}

.start-time {
  @include flex();

  &__field {
    width: 45px;
    // margin-left: 15px;
    position: relative;

    &::after {
      content: '';
      background-color: $c-assist4;
      @include position(tl, 100%, 0);
      width: 45px;
      height: 1px;
    }

    &__text {
      width: 45px;
      border: 0 transparent;
      outline: 0 transparent;
      text-align: center;
      padding: 0;
      @include font-style($c-black, 14, normal, 0.7px, 26px);
    }
  }
}

.btn {
  &--email {}

  &--copy-link {}

  &__text {
    margin-left: 12px;
  }

  &__icon {}

  &+& {
    margin-left: 10px;
  }
}

.music-link {
  margin-top: 30px;
  margin-bottom: 20px;
  @include flex();
  @include padding(0 24px);
  height: 40px;
  border-radius: 20px;
  background-color: $c-assist1;

  &__input {
    width: 100%;
    @include font-style($c-assist6, 14, normal, 0.7px, 18px);
    border: 0px transparent;
    outline: 0px transparent;
    background-color: transparent;
  }
}

.err-msg {
  color: $c-error;
}

.pc-show {
  display: none;
}

.mobile-wrap {
  @include flex();
}

@media screen and (min-width: $tablet) {
  .c-share-music {
    margin-top: 45px;
  }

  .pc-show {
    display: block;
  }

  .mobile-show {
    display: none;
  }

  .pc-btn-wrap {
    @include flex();
  }
}
</style>
