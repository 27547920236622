export default {
  title: '授权服务',
  cookiesTitle: 'COOKIES 政策',
  termsTitle: '使用条款',
  privacyTitle: '隐私权政策',
  customization: '定制化服务',
  contents: [
    {
      title: '授权介紹',
      desc: [
        '超过百万首曲目高品质配乐，强大的搜寻筛选工具，各式授权组合，配合您的制作预算',
      ],
    },
    {
      title: '单曲授权 NeedleDrop',
      desc: [
        '需要特定音乐来衬托您的作品吗？我们拥有全台湾最大的音乐曲库，各类型音乐曲风，成为您创作上最强后盾，欢迎随时线上试听挑选，让作品更加完美。',
      ],
    },
    {
      title: '年租式授权 Blanket License',
      desc: [
        '提供多元化的年租式方案(专辑或曲数)，满足利用人不同的使用需求。(如电视台、广播电台、录音室、线上游戏、网红及Podcast…等数位媒体)',
      ],
    },
    {
      title: '专案授权 Project License',
      desc: ['量身打造适合您的超值专案价。无论是纪录片、戏剧节目、形象影片、有声书、电影、Podcast…等配乐授权需求，请立即联络我们 !'],
    },
    {
      title: '音效素材 Sound Effect',
      desc: ['曾为您的特殊音效素材不足感到烦恼吗？我们提供各类型的音效素材，让您一次付费便能⾧期使用，马上联系我们。'],
    },
    {

      title: '百货公司、商家、门市音乐授权 In Store Music',
      desc: [
        '舒适的购物环境能吸引消费者进入，好的音乐能让消费者停留更久的时间，不论是运动风、奢华风、儿童馆 或是周年庆、特殊节日活动...等，我们都能提供客制化的需求，规划配合环境的音乐，让您的商场与拥有独一无二的魅力。',
      ],
    },
    {
      title: '作曲服务 Customized Composing Service',
      desc: [
        '由业界著名配乐作曲家团队，为您作曲。节目片头，电视台频道包装、企业Jingle、游戏、广告配乐、电视剧配乐、主题曲、插曲、片尾曲，好沟通，创意新颖。',
      ],
    },
    {
      title: 'Point Music X 音乐串流平台 共同合作 供个人线下耹听!',
      desc: [
        '您是否很想收藏某一首喜爱的广告音乐或是影视配乐，却无从入手? 我们听见广大小耳朵的心声了! 特别整理出您绝对有印象的热门配乐歌单! 请透过各大线上音乐串流平台的订阅服务，马上将喜爱的音乐下载变成您的专属手机铃声或来电答铃。',
      ],
    },
  ],
};
