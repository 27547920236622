<template>
  <div class="c-queue">
    <div class="close">
      <img class="close__icon" src="@/assets/searchResults/icon_popup_close.svg" @click="closeQueue" />
    </div>

    <div class="c-queue__content">
      <div class="header-wrap">
        <div class="header-wrap__content">
          <div class="header-wrap__content__left">
            <p class="title">{{$t('myMusic.playQueue')}}</p>
            <p class="count">({{ queueLength }})</p>
          </div>
          <div class="header-wrap__content__right">
            <function-icons
              :data="functionConfig.functionIcons"
              @update:activeFunctionName="handleDelete"
              v-if="memberLevel > 1"
            />
            <!-- <add-playlists-btn :data="functionConfig.addPlaylistsBtn" v-if="memberLevel > 2"/> -->
            <add-all-to-Playlist-btn :data="functionConfig.addAllToPlaylistBtn" v-if="memberLevel > 2" />
            <!-- <download-btn :data="functionConfig.downloadBtn" v-if="true" /> -->
          </div>
        </div>
      </div>
      <div class="my-music-list-wrap">
        <my-music-list ref="queueList" :config="listConfig" :contentData="contentData" remainRowHeight/>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from 'vue';
// import AddPlaylistsBtn from '@/components/Global/AddPlaylistsBtn.vue';
// import DownloadBtn from '@/components/Global/DownloadBtn.vue';
import FunctionIcons from '@/components/MyMusic/FunctionIcons.vue';
import MyMusicList from '@/components/Global/MusicPlayer/VersionQueueList/index.vue';
import { functionConfig, listConfig } from '@/config/queueConfig';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import useMemberLevel from '@/hooks/useMemberLevel';
import AddAllToPlaylistBtn from '@/components/Global/AddAllToPlaylistBtn.vue';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'Version',
  components: {
    AddAllToPlaylistBtn,
    // AddPlaylistsBtn,
    // DownloadBtn,
    MyMusicList,
    FunctionIcons,
  },
  props: {
    queue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:queue'],
  setup(props, { emit }) {
    const queueList = ref(null);
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();
    const { memberLevel } = useMemberLevel();
    const closeQueue = () => {
      emit('update:queue', false);
    };

    const handleDelete = () => {
      newMusicPlayerStore.handleRemoveFromPlayQueue();
      queueList.value.handleResetIsChecked();
    };

    return {
      memberLevel,
      queueList,
      handleDelete,
      functionConfig,
      listConfig,
      contentData: computed(() => newMusicPlayerStore.queueList),
      closeQueue,
      queueLength: computed(() => newMusicPlayerStore.queueList.length || 0),
    };
  },
});
</script>
<style lang="scss" scoped>
.c-queue {
  width: 100%;
  background-color: $c-assist12;
  @include position(tl, -60vh, 0);
  height: 60vh;

  &__content {
    @include max-width(1300);
    @include padding(60px 0 100px);
    @include flex(flex-start, flex-start, column);
    width: 100%;
    height: 100%;
  }
}

.close {
  @include position(tr, 0, 0);
  width: 55px;
  height: 55px;
  @include flex(center);
}

.header-wrap {
  width: 100%;
  flex: none;
  height: auto;

  &__content {
    @include flex(space-between);

    &__left {
      @include flex();
    }

    &__right {
      @include flex();
    }
  }
}

.title {
  @include font-style($c-white, 24, bold, 1.2px, 30px);
}

.count {
  @include font-style($c-assist4, 14, bold, 0.7px, 26px);
  margin-left: 18px;
}

.my-music-list-wrap {
  width: 100%;
  height: 100%;
  flex: 1;
  margin-top: 48px;
}
</style>
