<template>
  <div class="sign-in">
    <div class="sign-in__content" @click="handleLogin">
      <p class="sign-in__content__text">{{ $t('auth.signin') }}</p>
      <img class="sign-in__content__icon" src="@/assets/profile/icon_signin.svg" />
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'SignOut',
  setup() {
    const siteStore = useSiteStore();
    const router = useRouter();

    const showMobileMenuContent = computed(() => siteStore.showMobileMenuContent);
    const handleLogin = () => {
      router.push({
        name: 'sign-in',
      });
      siteStore.toggleShowMobileMenuContent(!showMobileMenuContent.value);
    };

    return {
      handleLogin,
    };
  },
});
</script>
<style lang="scss" scoped>
.sign-in {
  flex: none;
  width: 50%;
  @include flex(flex-start);

  &__content {
    @include padding(0 10px);
    @include inline-flex(space-between);
    background-color: $c-main;
    width: 100px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;

    &__text {
      @include font-style($c-white, 12, normal, 0px, 16px);
    }

    &__icon {
      width: 13px;
      margin-left: 5px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .sign-in {
    @include flex(center, center);

    &__content {
      @include padding(36px 0 30px 0);
      @include flex();
      background-color: transparent;
      width: auto;

      &__text {
        @include font-style($c-assist4, 14, normal, 0.8px, 18px);
      }

      &__icon {
        width: 20px;
        margin-left: 12px;
      }
    }
  }
}
</style>
