// 有 editType和versionType 才顯示諮詢
const getSubInfo = (editType, versionType, withDash = true) => {
  const editVersionExist = editType && versionType;
  const editVersionText = withDash ? ` - ${editType}/${versionType}` : `(${editType}/${versionType})`;
  const subInfo = editVersionExist ? editVersionText : '';

  return subInfo;
};

export default getSubInfo;
