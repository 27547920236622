<template>
  <div class="c-note function-icon" @click="handleModelStatus(true)">
    <tooltip :label="$t('myMusic.addNote')">
      <div class="function-icon">
        <div class="function-icon__img function-icon__img--note--has-note" v-if="hasNote"></div>
        <div class="function-icon__img function-icon__img--note--white" v-else-if="isWhite"></div>
        <div class="function-icon__img function-icon__img--note" v-else></div>
        <span class="function-icon__label" v-if="label"> {{ $t('myMusic.addEditNote') }} </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false" :style="{ 'max-width': '800px' }">
      <model-base-layout :title="`${$t('myMusic.addNote')} - ${trackTitle}${getSubInfo(editType, versionType, false)}`">
        <!-- data.tracks[0]?.trackTitleDisplay || -->

        <add-note :type="type" :data="data" @close="handleModelStatus" :extraType="extraType" />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal, useMessage } from 'naive-ui';
import { useI18n } from 'vue-i18n';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import AddNote from '@/components/Global/Popup/AddNote.vue';
import Tooltip from '@/components/Global/Tooltip.vue';
import { useCacheStore } from '@/store/cache';
import { useSiteStore } from '@/store/site';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import getSubInfo from '@/utils/getSubInfo';
import formatTrackSubInfo from '@/utils/formatTrackSubInfo';

export default defineComponent({
  name: 'Note',
  components: {
    NModal,
    ModelBaseLayout,
    AddNote,
    Tooltip,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    extraType: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const cacheStore = useCacheStore();
    const siteStore = useSiteStore();
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();
    const modelStatus = ref(false);
    const { t } = useI18n();
    const message = useMessage();

    const handleModelStatus = (status = true) => {
      if (!siteStore.isLogin) {
        message.error(t('auth.registerForFullFeatures'));
        return;
      }

      modelStatus.value = status;
    };

    const editType = computed(() => {
      return props.data?.editType || props.data?.track?.editType || '';
    });

    const versionType = computed(() => {
      return props.data?.versionType || props.data?.track?.versionType || '';
    });

    const hasNote = computed(() => {
      if (!siteStore.isLogin) return false;

      let trackUid = props.data?.trackUid || props.data?.originUid || props.data?.track?.trackUid || '';

      if (props.data?.tracks && Array.isArray(props.data?.tracks) && trackUid === '') {
        if (props.data.tracks[0].trackUid) {
          trackUid = props.data.tracks[0].trackUid || '';
        }
      }

      return cacheStore.trackWithNotes.includes(trackUid);
    });

    // const hasNote = false;

    // onMounted(() => {
    //   if (0) {
    //     // cacheStore.initTrackWithNotes(1);
    //   }
    // });

    const trackTitle = computed(() => {
      let title =
        // themes 內有 title 和 targetTitle，title 會被用在標題，不是曲目標題，所以要優先使用 targetTitle
        props.data.targetTitle ||
        props.data.trackTitleDisplay ||
        props.data.trackTitle ||
        props.data.title ||
        props.data.track?.trackTitleDisplay ||
        '';
      let subInfo = '';

      if (Array.isArray(props.data?.tracks) && props.data?.tracks.length && title === '') {
        title = props.data?.tracks[0].trackTitleDisplay;
        subInfo = formatTrackSubInfo({
          editType: props.data?.tracks[0]?.editType,
          versionType: props.data?.tracks[0]?.versionType,
        });
      }

      if (title === '') title = newMusicPlayerStore.musicInfo.trackName || '';

      return `${title}${subInfo ? `(${subInfo})` : ''}`;
    });

    return {
      modelStatus,
      handleModelStatus,
      hasNote,
      trackTitle,
      editType,
      versionType,
      getSubInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
