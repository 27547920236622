<template>
  <div class="c-model-base-layout">
    <div class="pop-title">
      <p class="pop-title__text" v-if="!cusHeader">{{ title }}</p>
      <div :class="{ 'pop-title__deco-line': decoLine }"></div>
    </div>

    <div class="pop-content" :class="{
        'pop-content--content-padding': contentPadding
      }">
      <slot />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MusicFuncion',
  props: {
    title: {
      type: String,
      default: '',
    },
    decoLine: {
      type: Boolean,
      default: false,
    },
    cusHeader: {
      type: Boolean,
      default: false,
    },
    contentPadding: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="scss" scoped>
.pop-title {
  width: 100%;

  &__text {
    @include padding(0 48px 0 24px);
    @include font-style($c-black, 18, bold, 0.9px, 26px);
  }

  &__deco-line {
    @include padding(16px 0 0 0);
    border-bottom: 1px solid $c-assist7;
  }
}

.pop-content {

  &--content-padding {
    @include padding(20px 24px);

  }
}
</style>
