<template>
  <div class="c-share-playlists function-icon"  @click="share">
    <tooltip :label="$t('myMusic.viewActivityRecord')">
      <div class="function-icon">
        <div class="function-icon__img function-icon__img--share-playlists"></div>
        <span class="function-icon__label" v-if="label"> {{$t('myMusic.viewActivityRecord')}} </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <model-base-layout :title="$t('myMusic.viewActivityRecord')" decoLine>
        <activity-share-record :data="data" :type="type" />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal } from 'naive-ui';
import ActivityShareRecord from '@/components/Global/Popup/ActivityShareRecord.vue';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'ViewActivityRecords',
  components: {
    NModal,
    ModelBaseLayout,
    ActivityShareRecord,
    Tooltip,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    label: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },

  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();
    const modelStatus = ref(false);

    const share = () => {
      modelStatus.value = true;
    };

    return {
      modelStatus,
      share,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
