<template>
  <div class="c-add-to-favorite function-icon" v-if="data" @click="addToFavorite">
    <tooltip :label="$t('myMusic.addToFavourite')">
      <div class="function-icon">
        <!-- 白色 手機播放器中使用-->
        <div class="function-icon__img function-icon__img--star--white" v-if="isWhite"></div>
        <!-- 灰色 -->
        <div class="function-icon__img function-icon__img--star" v-else></div>
        <span class="function-icon__label" v-if="label"> {{ $t('myMusic.addToFavourite') }}</span>
      </div>
    </tooltip>
  </div>
</template>
<script>
import { defineComponent, watch } from 'vue';
import { useMessage } from 'naive-ui';
import { useI18n } from 'vue-i18n';
import Tooltip from '@/components/Global/Tooltip.vue';
import { addAccountFavouriteContentAPI } from '@/api/myMusicPlaylists';
import useRequest from '@/hooks/useRequest';
import { firstLetterUpperCase } from '@/utils/firstLetterUpperCase';
import useAccountPlaylistList from '@/hooks/useAccountPlaylistList';
import { useSiteStore } from '@/store/site';
// import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'AddToFavorite',
  components: {
    Tooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    isFavourite: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { request, response } = useRequest(addAccountFavouriteContentAPI);
    const message = useMessage();
    const { fetchAllPlaylist } = useAccountPlaylistList();
    const siteStore = useSiteStore();

    const addToFavorite = async () => {
      if (!siteStore.isLogin) {
        message.error(t('auth.registerForFullFeatures'));
        return;
      }

      let uid = '';

      switch (props.type) {
        case 'TRACK':
          uid = props.data.trackUid || props.data.track.trackUid;
          break;
        case 'ALBUM':
          uid = props.data.albumUid || props.data.originUid;
          break;
        case 'PLAYLIST':
          break;

        default:
          break;
      }

      await request({
        in: {
          originUid: uid,
          type: firstLetterUpperCase(`${props.type.toLowerCase()}s`),
        },
      });

      fetchAllPlaylist();
    };

    watch(response, () => {
      const [err, res] = response.value;

      if (err) {
        // message.error(err.message);
        return;
      }

      if (res) {
        message.success(t('msg.addSuccess'));
      }
    });

    return {
      addToFavorite,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
