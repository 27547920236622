<template>
  <div class="c-add-to-playlist function-icon">
    <div class="function-icon" @click="open('edit-playlist-name')">
      <div class="function-icon__img function-icon__img--note"></div>
      <span class="function-icon__label" v-if="label"> {{$t('myMusic.editName')}} </span>

      <n-modal v-model:show="modelStatus['edit-playlist-name']" preset="dialog" :show-icon="false">
        <model-base-layout :title="`${$t('myMusic.editName')}-${data.name}`" decoLine>
          <edit-playlist-name :data="data" @close="close" isMobile/>
        </model-base-layout>
      </n-modal>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal } from 'naive-ui';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
// import Add from '@/components/Global/Popup/Add.vue';
import EditPlaylistName from '@/components/Global/Popup/EditPlaylistName.vue';

export default defineComponent({
  name: 'AddToPlaylist',
  components: {
    NModal,
    ModelBaseLayout,
    EditPlaylistName,
    // Add,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modelStatus = reactive({
      'edit-playlist-name': false,
    });
    // const musicPlayerStore = useMusicPlayerStore();

    const open = (key) => {
      modelStatus[key] = true;
    };

    const close = (key) => {
      modelStatus[key] = false;
    };

    return {
      modelStatus,
      open,
      close,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
