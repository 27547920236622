<template>
  <div class="c-search-box-item" @click="handleSearch">
    <img class="c-search-box-item__cover" :src="imageSource(data.artworkInfo || data.cover)" />
    <div class="c-search-box-item__info">
      <p class="c-search-box-item__info__name">{{ data.albumTitleDisplay || data.trackTitleDisplay || data.name }}</p>
      <p class="c-search-box-item__info__id">{{ data?.albumCode || data.album?.albumCode }} {{ data?.trackNumber &&
        `-${data?.trackNumber || ''}` }}</p>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import imageSource from '@/utils/imageSource';
import { useSearchStore } from '@/store/search';

export default defineComponent({
  name: 'SearchBoxItem',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['closePop'],
  setup(props, { emit }) {
    const router = useRouter();
    const searchStore = useSearchStore();

    const handleSearch = () => {
      let uid = '';

      switch (props.type) {
        case 'tracks':
          uid = props.data.trackUid;
          break;
        case 'albums':
          uid = props.data.albumUid;
          break;
        case 'playlists':
          uid = props.data.playlistUid;
          break;

        default:
          break;
      }

      router.push({
        name: 'search',
        query: {
          keyword: searchStore.searchValue.keyword || '',
          type: props.type,
          uid,
        },
      });

      emit('closePop');
    };

    return {
      imageSource,
      handleSearch,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-search-box-item {
  @include flex(flex-start, space-between, row);
  width: 100%;
  cursor: pointer;

  &__cover {
    width: 70px;
    height: 70px;
  }

  &__info {
    margin-left: 16px;
    @include flex(flex-start, flex-start, column);

    &__name {
      @include font-style($c-white, 14, bold, 0.8px, 26px);
      @include word-ellipsis(2)
    }

    &__id {
      @include font-style($c-assist2, 14, normal, 0.8px, 18px);
    }
  }
}
</style>
