import request from '@/api';
import { getHighlightPlaylist, getPlaylistList, getPlaylist, getPlaylistTracks, getPlaylistBasicInfo, getSearchPlaylist,
  getPlaylistOnlyTracks,
} from '@/api/gql/getPlaylists.gql';

export const getHighlightPlaylistAPI = async (payload) => {
  const res = await request(getHighlightPlaylist, payload);

  return res;
};

export const getPlaylistListAPI = async (payload) => {
  const res = await request(getPlaylistList, payload);

  return res;
};

export const getPlaylistAPI = async (payload) => {
  const res = await request(getPlaylist, payload);

  return res;
};

export const getPlaylistBasicInfoAPI = async (payload) => {
  const res = await request(getPlaylistBasicInfo, payload);

  return res;
};

export const getPlaylistTracksAPI = async (payload) => {
  const res = await request(getPlaylistTracks, payload);

  return res;
};

export const getSearchPlaylistAPI = async (payload) => {
  const res = await request(getSearchPlaylist, payload);

  return res;
};

export const getPlaylistOnlyTracksAPI = async (payload) => {
  // const res = await request(getSearchPlaylist, payload);
  const res = await request(getPlaylistOnlyTracks, payload);

  return res;
};
