const parseQuery = (urlQuery) => {
  // console.log('urlQuery', urlQuery);
  // const urlQuery = location.search;

  // 沒有查詢參數直接返回空物件
  if (urlQuery === '') {
    return {};
  }

  // 清除問號
  const urlQueryStr = urlQuery.slice(1);

  // 正則
  const regExp = /(\w+)=(\w+)/g;

  let result;

  // 要組成的key value pairs
  const keyValueObj = {};

  while (result !== null) {
    result = regExp.exec(urlQueryStr);

    // 不等於null才執行
    if (result !== null) {
      // 如果已經有這個屬性，轉為陣列;不然就直接賦值ㄋ
      if (result[1] in keyValueObj) {
        // 如果以有的屬性是陣列，直接推進去; 不然就將字串轉為陣列，在推進去
        if (Array.isArray(keyValueObj[result[1]])) {
          keyValueObj[result[1]].push(result[2]);
        } else {
          const convertToArr = keyValueObj[result[1]].split();
          convertToArr.push(result[2]);
          keyValueObj[result[1]] = convertToArr;
        }
      } else {
        const [, b, c] = result;

        keyValueObj[b] = c;
      }
    }
  }

  return keyValueObj;
};

export default parseQuery;
