import previewImg from '@/assets/global/default.png';

const imageDomainUrl = process.env.VUE_APP_IMAGE_DOMAIN;

const imageSource = (src, defaultImg) => {
  if (!src) {
    return defaultImg || previewImg;
  }

  if (src.includes('http')) {
    return src;
  }

  return encodeURI(`${imageDomainUrl}${src}`);
};

export default imageSource;
