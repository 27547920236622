export default {
  keepLogin: '保持登入',
  forgotPassword: '忘記密碼?',
  noAccount: '還沒有加入會員嗎？',
  forgetPwd: '忘記密碼',
  alreadyAMember: '已經是會員?',
  resetEmailSentTo: '已將密碼重設信發送至',
  linkExpired: '連結已失效',
  home: '首頁',
  isValidating: '驗證中',
  loginAndSendAgainMsg: '請登入您的帳號後選擇重新發送驗證連結通知信！',
  validateLinkExpired: '驗證連結已失效',
  resetPwd: '密碼重設',
  siteIntro: '成為音韶會員，您可以：',
  siteIntroDesc1: '線上完整試聽超過百萬首曲目',
  siteIntroDesc2: '建立喜愛歌單無上限並與他人分享',
  siteIntroDesc3: '使用影音結合體會功能搭配您的影片',
  byRegistering: '我同意',
  termsOfUse: '使用條款',
  and: '和',
  privacyPolicy: '隱私權政策',
  valiateEmailSentTo: '已將驗證連結發送至',
  emailIsAccount: '電子信箱即為您的登入帳號',
  sendValidateLinkToEmail: '已發送驗證連結至您的信箱，',
  clickForCompleteSignup: '請點擊信內連結以完成註冊。',
  signin: '登入',
  signup: '註冊',
  registerForFullFeatures: '註冊成為會員後，方可享有完整功能',

};
