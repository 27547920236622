import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import usePopInfo from '@/hooks/usePopInfo';
import { useSiteStore } from '@/store/site';
import cusStorage from '@/utils/cusStorage';

export default () => {
  const router = useRouter();
  const route = useRoute();
  const { errorPopInfo } = usePopInfo();
  const toLogin = () => {
    router.push({ name: 'sign-in' });
  };
  const siteStore = useSiteStore();
  const isLogin = computed(() => siteStore.isLogin);

  const loginFirst = (callback) => {
    if (!isLogin.value) {
      errorPopInfo({
        desc: '請先登入',
        callback: toLogin,
        isOverlayUseCallback: false,
      });
      cusStorage.setItem('loginPageRecord', route);

      return;
    }

    callback();
  };

  return {
    loginFirst,
  };
};
