<template>
  <div class="c-mobile-function" :class="{
    'c-mobile-function--active': showMobilePlaylistFunction,
  }" @click.stop="close">
    <div class="c-mobile-album-function" :class="{
        'c-mobile-album-function--active': showMobilePlaylistFunction,
      }">
      <div class="c-mobile-album-function__content">
        <div class="header">
          <div class="header__album-info">
            <cover-name-code-normal :data="currPlaylistData" showCode
              v-if="currPlaylistData.extraType === 'page-search-album'" />

            <template v-else>
              <span class="header__album-info__text">{{ currPlaylistData.name }}</span>
              <img class="header__album-info__img" v-show="currPlaylistData.isFavourite"
                src="@/assets/myMusic/icon_favourite.svg" />
            </template>
          </div>
          <div class="header__close">
            <img class="header__close__img" src="@/assets/mobile/icon_menu_close.svg" @click="close" />
          </div>
        </div>

        <ul class="function-list">
          <!-- 查看活動紀錄-->
          <li class="function-list__item" v-if="functionList.includes('view-activity-records') && memberLevel > 2">
            <view-activity-records label :data="currPlaylistData" />
          </li>
          <!-- 從收藏刪除 -->
          <li class="function-list__item"
            v-if="functionList.includes('remove-playlist') && memberLevel > 2 && !currPlaylistData.isFavourite">
            <remove-playlist label :data="currPlaylistData" />
          </li>
          <!-- 編輯歌單 -->
          <li class="function-list__item"
            v-if="functionList.includes('edit-playlists') && memberLevel > 2 && !currPlaylistData.isFavourite">
            <edit-playlists label :data="currPlaylistData" />
          </li>

          <!-- 加入我的曲目-->
          <!-- <li class="function-list__item" v-if="functionList.includes('add') && memberLevel > 2">
            <add-to-my-tracks label />
          </li> -->
          <!-- 從收藏刪除 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('remove-from-favorite') && memberLevel > 2">
            <add-to-favorite label />
          </li> -->

          <!-- 加入專案 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('add-to-playlists') && memberLevel > 2">
            <add-to-my-tracks label />
          </li> -->
          <!-- <li class="function-list__item" v-if="functionList.includes('add-to-my-albums') && memberLevel > 2">
            <add-to-my-albums label />
          </li> -->
          <!-- 分享 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('share') && memberLevel > 2">
            <share label />
          </li> -->

          <!-- 從我的歌單中刪除 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('remove-playlists') && memberLevel > 2">
            <remove-playlists label />
          </li> -->
          <!-- 新增/查看筆記 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('note')&& memberLevel > 2">
            <note label :data="data"/>
          </li> -->
          <!-- 專輯資訊 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('album-info')">
            <album-info label />
          </li> -->
          <!-- 搬移至歌單 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('move-to-playlists')&& memberLevel > 2">
            <move-to-playlists label />
          </li> -->
          <!-- 前往專輯頁面 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('to-album-page')">
            <to-album-page label :data="data"/>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import CoverNameCodeNormal from '@/components/Global/MusicPlayer/Fragment/CoverNameCodeNormal.vue';
// import AddToMyTracksFavorite from '@/components/Global/MusicPlayer/Functions/AddToMyTracksFavorite.vue';
// import AddToMyTracks from '@/components/Global/MusicPlayer/Functions/AddToMyTracks.vue';
// import AddToMyAlbums from '@/components/Global/MusicPlayer/Functions/AddToMyAlbums.vue';
// import Note from '@/components/Global/MusicPlayer/Functions/Note.vue';
// import Share from '@/components/Global/MusicPlayer/Functions/Share.vue';
// import ToAlbumPage from '@/components/Global/MusicPlayer/Functions/ToAlbumPage.vue';
// import AlbumInfo from '@/components/Global/MusicPlayer/Functions/AlbumInfo.vue';
// import RemovePlaylists from '@/components/Global/MusicPlayer/Functions/RemovePlaylists.vue';
import EditPlaylists from '@/components/Global/MusicPlayer/Functions/EditPlaylists.vue';
// import RemoveFromFavorite from '@/components/Global/MusicPlayer/Functions/RemoveFromFavorite.vue';
// import MoveToPlaylists from '@/components/Global/MusicPlayer/Functions/MoveToPlaylists.vue';
import ViewActivityRecords from '@/components/Global/MusicPlayer/Functions/ViewActivityRecords.vue';
import RemovePlaylist from '@/components/Global/MusicPlayer/Functions/RemovePlaylist.vue';
import { useSiteStore } from '@/store/site';
import useMemberLevel from '@/hooks/useMemberLevel';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'MobilePlaylistFunction',
  components: {
    CoverNameCodeNormal,
    ViewActivityRecords,
    RemovePlaylist,
    // AddToMyTracksFavorite,
    // AddToMyTracks,
    // AddToMyAlbums,
    // Note,
    // Share,
    // ToAlbumPage,
    // AlbumInfo,
    EditPlaylists,
    // RemovePlaylists,
    // RemoveFromFavorite,
    // MoveToPlaylists,
  },
  props: {
    showFunction: {
      type: Boolean,
      default: false,
    },
    // functionList: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  emits: ['update:showFunction'],
  // props, { emit }
  setup() {
    const siteStore = useSiteStore();
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();
    const { memberLevel } = useMemberLevel();

    const data = computed(() => ({
      cover: newMusicPlayerStore.musicInfo.cover,
      targetTitle: newMusicPlayerStore.musicInfo.trackName,
      note: newMusicPlayerStore.musicInfo.creator,
      order: newMusicPlayerStore.musicInfo.trackNumber,
      albumUid: newMusicPlayerStore.musicInfo.albumUid,
      trackUid: newMusicPlayerStore.musicInfo.trackUid,
      trackTitleDisplay: newMusicPlayerStore.musicInfo.trackName,
    }));

    const showMobilePlaylistFunction = computed(() => siteStore.showMobilePlaylistFunction);
    const currPlaylistData = computed(() => siteStore.mobilePlaylistFunctionData);
    const functionList = computed(() => siteStore.mobilePlaylistFunctionList);

    const close = () => {
      siteStore.toggleShowMobilePlaylistFunction(false);
    };

    return {
      data,
      close,
      showMobilePlaylistFunction,
      memberLevel,
      currPlaylistData,
      functionList,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-mobile-function {
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 0.2);
  position: fixed;
  top: 100%;
  left: 0;
  z-index: $zi-mobile-function;
  transition: 0.3s;
  animation: fadeOut 0.4s forwards;

  &--active {
    display: inline-block;
    animation: fadeIn 0.3s forwards;
  }
}

.c-mobile-album-function {
  width: 100%;
  height: auto;
  background-color: rgba($c-white, 0.8);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  transition: 0.3s;
  @include position(tl, 100%, 0);
  animation: contentSlideOut 0.3s forwards;

  &--active {
    animation: contentSlideIn 0.3s forwards;
    background-color: rgba($c-white, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: 0%;
  }

  to {
    opacity: 1;
    top: 0%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    top: 0%;
  }

  99% {
    top: 0%;
  }

  100% {
    opacity: 0;
    top: 100%;
  }
}

@keyframes contentSlideIn {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes contentSlideOut {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

.header {
  @include flex(space-between);
  border-bottom: 1px solid $c-assist7;
  @include padding(13px 20px);

  &__album-info {
    @include flex();

    &__text {
      display: inline-block;
      widht: 100%;
      @include font-style($c-black, 14, medium, 0.8px, 22px);
      @include word-ellipsis();
    }

    &__img {
      margin-left: 10px;
    }
  }

  &__close {
    width: 18px;

    &__img {
      width: 100%;
      cursor: pointer;
    }
  }
}

.function-list {
  @include padding(20px);

  &__item {
    &+& {
      margin-top: 20px;
    }
  }
}
</style>
