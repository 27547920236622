<template>
  <div class="c-edit-palylist-name">
    <div class="name">
      <input class="name__input" placeholder="" v-model="editName" @keydown.stop />
    </div>

    <div class="btn-wrap">
      <mi-button @click="submit">{{ $t('global.save') }}</mi-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, watch, ref, inject } from 'vue';
import { useMessage } from 'naive-ui';
import { useI18n } from 'vue-i18n';
import MiButton from '@/components/Global/MiButton.vue';
import { updateAccountPlaylistAPI } from '@/api/myMusicPlaylists';
import useRequest from '@/hooks/useRequest';

export default defineComponent({
  name: 'EditPlaylistName',
  components: {
    MiButton,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const editName = ref(props.data.name);
    const message = useMessage();
    const { request, response } = useRequest(updateAccountPlaylistAPI);

    const reloadAccountPlaylist = inject('reloadAccountPlaylist');

    const submit = async () => {
      await request({
        in: {
          accountPlaylistUid: props.data.accountPlaylistUid,
          name: editName.value,
          publicStatus: false,
        },
      });
    };

    watch(response, () => {
      const [err, res] = response.value;

      if (err) {
        // message.error(err.message);
        return;
      }

      if (res) {
        message.success(t('msg.saveSuccess'));

        // mobile 的 function 是透過 props 來的 (因為觸發彈窗方式不同，無法透過 provider 丟下來)
        if (props.isMobile) {
          props.data.reloadAccountPlaylist();
        } else {
          reloadAccountPlaylist();
        }

        emit('close', 'edit-playlist-name');
      }
    });

    return {
      submit,
      editName,
    };
  },
});
</script>
<style lang="scss" scoped>
.name {
  margin: 36px 45px 0;
  @include padding(0 25px);
  @include flex();
  border: 1px solid #a0a0a0;
  border-radius: 25px;
  height: 50px;

  &__input {
    @include font-style($c-black, 14, normal, 0.8px, 22px);
    border: 0 transparent;
    outline: 0 transparent;
    background-color: transparent;
    width: 100%;

    &::placeholder {
      @include font-style($c-assist3, 14, normal, 0.8px, 22px);
    }
  }
}

.btn-wrap {
  margin-top: 50px;
  margin-bottom: 20px;
  @include flex(center);
}
</style>
