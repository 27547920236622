import { ref, watch } from 'vue';
import { v4 as uuid } from 'uuid';
import { debounce } from 'lodash-es';
import useRequest from '@/hooks/useRequest';
import { getTrackAPI, getTrackDetailAPI } from '@/api/getTrack';
import { useSiteStore } from '@/store/site';
import { useMusicPlayerStore } from '@/store/musicPlayer';
import useGetTrackFile from '@/hooks/useGetTrackFile';
import trackWaveSource from '@/utils/trackWaveSource';
// import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default () => {
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isAppend = ref(false);
  const { request: reqTrack } = useRequest(getTrackAPI);
  const { request: reqTrackDetail, response: trackDetail } = useRequest(getTrackDetailAPI);
  const musicPlayerStore = useMusicPlayerStore();
  const site = useSiteStore();
  const { getTrackFileUrl } = useGetTrackFile();
  // const newMusicPlayerStore = useNewMusicPlayerStore();

  /**
   * watch trackURL 資料
   * 1. 將 URL 塞入現正播放
   * 2. 若 shareTrack.startAt 有值, 將播放器只到指定秒數
   * 3. 播放
   * 4. 清空 shareTrack.startAt
   */

  const formatTitle = (editType, versionType) => {
    if (editType && versionType) return `${editType} / ${versionType}`;
    return editType || versionType;
  };

  const formatTrackDetail = (data) => ({
    duration: data?.duration,
    trackUid: data?.trackUid,
    artworkInfo: data?.artworkInfo,
    id: `${data?.albumCode || ''}-${data?.trackNumber || ''}`,
    title: formatTitle(data?.editType, data?.versionType),
    albumInfo: {
      albumCoverUrl: data?.artworkInfo,
      albumName: data?.album?.albumTitleDisplay,
      albumUid: data?.album?.albumUid,
      songName: data?.trackTitleDisplay,
      creator: data?.albumCode,
      duration: data?.duration,
      trackUid: data?.trackUid,
      trackWave: trackWaveSource(data?.trackWave),
      trackNumber: data?.trackNumber,
      isMasterVersion: data?.isMasterVersion,
      uuid: data?.uuid || uuid(),
    },
  });

  watch(trackDetail, (v) => {
    const [, res] = v;
    musicPlayerStore.handleAddToVersions(
      res?.getTrackDetail?.trackList
        .filter(({ isMasterVersion }) => !isMasterVersion)
        .map((item) => formatTrackDetail({ albumUid: res?.getTrackDetail?.detail?.album?.albumUid, ...item })),
    );
  });

  const getVersions = (trackUid) => {
    reqTrackDetail({ in: trackUid });
  };

  const getTrackFile = async ({ trackUid, accountUid }) => {
    const fileUrl = await getTrackFileUrl({ trackUid, accountUid });

    musicPlayerStore.setMusicFile(fileUrl);
    if (musicPlayerStore.shareTrack.startAt) {
      musicPlayerStore.setForceToTime(musicPlayerStore.shareTrack.startAt);
      if (isFirefox) {
        musicPlayerStore.setShareStartAt(null);
        return;
      }
    }

    musicPlayerStore.handlePlayStatus(true);
    musicPlayerStore.setShareStartAt(null);
  };

  const getAlbumUid = async (trackUid) => {
    const [, res] = await reqTrack({ in: trackUid });
    return res?.getTrack?.album?.albumUid;
  };

  /**
   * 播放音樂
   * 1. 停止播放
   * 2. 取得曲目資料
   * 3. 將曲目資訊塞入現正播放
   * ( 若無 albumInfo 無資料，則直接播放 playQueue[0] )
   */
  const methodPlayMusic = async (albumInfo = musicPlayerStore?.playQueue[0]?.albumInfo) => {
    if (!albumInfo?.albumUid) {
      albumInfo.albumUid = await getAlbumUid(albumInfo?.trackUid);
    }
    getVersions(albumInfo.trackUid);

    musicPlayerStore.handlePlayStatus(false);
    if (musicPlayerStore.info?.musicFileUrl && albumInfo.uuid === musicPlayerStore.info.uuid && albumInfo.trackUid === musicPlayerStore.info.trackUid) {
      if (!musicPlayerStore.info.isMasterVersion) getVersions(albumInfo.trackUid);
      musicPlayerStore.handlePlayStatus(true);
      return;
    }

    if (albumInfo) {
      getVersions(albumInfo.trackUid);

      getTrackFile({ trackUid: albumInfo.trackUid, accountUid: site?.memberInfo?.accountUid });
      musicPlayerStore.handleSetInfo({
        ...albumInfo,
        ...(albumInfo?.isMasterVersion === false ? { uuid: musicPlayerStore.info.uuid } : {}),
      });
    }
  };

  const playMusic = debounce(methodPlayMusic, 0);

  const methodStopMusic = () => {
    musicPlayerStore.handlePlayStatus(true);
    musicPlayerStore.handlePlayStatus(false);
  };
  const stopMusicPlayer = debounce(methodStopMusic, 0);

  const formatTrack = (data) => ({
    duration: data?.duration,
    id: `${data?.albumCode || ''}-${data?.trackNumber || ''}`,
    title: data?.trackTitleDisplay,
    trackUid: data?.trackUid,
    artworkInfo: data?.album?.artworkInfo,
    albumInfo: {
      isMasterVersion: data?.isMasterVersion,
      albumCoverUrl: data?.album?.artworkInfo,
      albumName: data?.album?.albumTitleDisplay,
      albumUid: data?.albumUid || data?.album?.albumUid,
      songName: data?.trackTitleDisplay,
      creator: data?.album?.albumCode,
      duration: data?.duration,
      trackUid: data?.trackUid,
      trackWave: trackWaveSource(data?.trackWave),
      trackNumber: data?.trackNumber,
      uuid: uuid(),
    },
  });

  const getTrack = async (trackUid) => {
    if (!trackUid) return;
    const [, res] = await reqTrack({ in: trackUid });
    if (res?.getTrack) {
      const data = res.getTrack;
      if (!res?.getTrack?.isMasterVersion) return;
      musicPlayerStore.handleAddToPlayQueue([formatTrack(data)]);
    }
  };

  /**
   * 處理從主題來的曲目
   * 1. 用 originUid 跟 getTrack 拿資料
   */
  // FIXME: 改名
  const handleFromExternalTrack = async (originUid) => {
    if (!originUid) return;
    await getTrack(originUid);
    playMusic();
  };

  return {
    isAppend,
    formatTrack,
    reqTrack,
    getTrack,
    reqTrackDetail,
    stopMusicPlayer,
    playMusic,
    handleFromExternalTrack,
    getVersions,
    formatTrackDetail,
  };
};
