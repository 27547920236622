export default {
  // firstView
  aboutUs: '關於我們',
  aboutDesc1: '音韶為台灣最大版權音樂代理商，',
  aboutDesc2: '超過百萬首音樂為您提供多樣化選擇，',
  aboutDesc3: '齊全、豐富的音樂，滿足您各類需求。',

  // agent
  agentRedTitle: '台灣最大版權音樂代理商',
  agentDesc: '音韶文化於民國86年成立，以代理版權音樂為主要業務，目前為全台灣最大的版權音樂代理公司。集合全世界知名品牌，提供最豐富的音樂曲庫為您的影片增添更好的靈感及創意。',
  serviceSearch: '線上音樂搜尋',
  serviceExclusive: '獨家音樂授權',
  serviceCommercial: '音樂代理洽談',

  // experience
  experienceRedTitle: '探索音樂，創造感動體驗',
  explore: '探索',
};
