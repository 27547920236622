export default {
  albums: 'Albums',
  tracks: 'Tracks',
  playlists: 'Playlists',
  empty: '',
  id: 'ID',
  remove: 'Remove',
  playlist: 'Project Music Selection',
  searchHistory: 'Search History',
  playedHistory: 'Played History',
  downloadHistory: 'Download History',
  myNote: 'My Note',
  previewTool: 'Preview Tool',
  searchAllProject: 'Search All Projects',
  myTracks: 'My Tracks',
  myAlbums: 'My Albums',
  favouriteTracks: 'Track Collection',
  favouriteAlbums: 'Album Collection',
  version: 'Version Type',
  count: 'Count',
  divide: 'Stem',
  modifyDate: 'Modify Date',
  trackName: 'Track Name',
  title: 'Name',
  length: 'Length',
  viewActivityRecord: 'View activity records',
  keyword: 'Keyword',
  filterTag: 'Filter Tag',
  searchDate: 'Search Date',
  playedDate: 'Play Date',
  preserve30Days: 'The records will be preserved for 30 days.',
  versionAndEdit: 'Edit Type / Version Type',
  trackInfo: 'Tracks Info',
  toAlbumPage: 'Go to Album Page',
  toPlaylistPage: 'Go to Playlist Page',
  addToQueue: 'Add to Play Queue',
  downloadDate: 'Free Download Expired Date',
  noteCount: 'Note Count',
  updateDate: 'Update date',
  selectedPreviewTracks: 'Please select preview track in your project',
  clickToPlayPosition: 'Click the progress bar for play start position',
  searchTrackName: 'Search Track Title in your project',
  syncPreview: 'Preview Synchronize',
  mediaFile: 'Video File',
  wave: 'Waves',
  desc: 'Description',
  addToPlaylist: 'Add to Project',
  allPlaylist: 'All Projects',
  previeToolDesc1: '1. Add a track in your playlist - my tracks',
  previeToolDesc2: '2. Upload your video',
  previeToolDesc3: '3. Preview the result of the video and tracks immediately',
  startUse: 'Start',
  seeAll: 'View All',
  playlistName: 'Playlist Name',
  projectName: 'Project Name',
  albumName: 'Album Name',
  lyric: 'Lyric',
  move: 'Move Playlist',
  copy: 'Copy Playlist',
  delete: 'Delete',
  invite: 'Share Playlist',
  downloadPlaylist: 'Download Playlist',
  searchTrackNamePlaceholder: 'Search track name',
  notice: 'The records will be preserved for 30 days.',
  uploadVideo: 'Upload Video',
  pleaseUploadVideo: 'Please upload video',
  pleaseSelectTrack: 'Please select track',
  addToProject: 'Add to Project',
  moveToPlaylists: 'Move to Playlist',
  seeTrackNote: 'View Track Note',
  activityRecords: 'Activity Records',
  editName: 'Edit Name',
  addToFavourite: 'Add to Collection',
  albumInfo: 'Album Info',
  download: 'Download',
  addNote: 'Add Note',
  addEditNote: 'Add/View Note',
  removeFromFavourite: 'Remove from Collection',
  removeCurrTrack: 'Remove This Track',
  removeCurrProject: 'Remove This Project',
  removePlaylist: 'Remove Playlist',
  shareTrack: 'Share Track',
  share: 'Share',
  sharePlaylist: 'Share Playlist',
  shareAlbum: 'Share Album',
  albumIsOffShelf: 'This album has been removed.',
  trackIsOffShelf: 'This track has been removed.',
  playQueue: 'Play Queue', // PLAY QUEUE
  versionEdits: 'Version Type', // VERSION & EDITS
  add: 'Add',
  addNewPlaylist: 'New Project Title',
  noteContent: 'Note Content',
  editNote: 'Edit Note',
  searchNote: 'Search Note',
  pleaseEnterCurrectTimeFormat: 'Please enter correct time format',
  copyLink: 'Copy link',
  shareTrackEmailWording: {
    t1: 'Invite you to listen.',
    t2: 'Hey! I found great music.',
    t3: 'Come and listen.',
  },
  createProject: 'Create new project',
  waves: 'Waves',
  tags: 'Tags',
  description: 'Description',
  detailDescription: 'Description',
  copyDataLabel: {
    trackTitle: 'Track Title',
    composer: 'Composer',
    publisher: 'Publisher',
    albumTitle: 'Album Title',
    albumCode: 'Album Code',
    trackNumber: 'Track ID',
    label: 'Labels',
    labelCode: 'Label Code',
  },
  with: 'With',
  without: 'Without',
  submitSave: 'Save',
  fromWhere: 'from',
  play: 'Play',
  rename: 'Rename',
  ignoreAlbum: 'Album is not available and will be ignored.',
  ignoreTrack: 'Track is not available and will be ignored.',
  removeNoteSucceed: 'Remove Succeed',
};
