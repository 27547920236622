// 前台
export default [

  {
    path: '/',
    component: () => import(/* webpackChunkName: "LayoutWithHeader" */ '@/layout/LayoutWithHeader.vue'),
    redirect: '/',
    meta: {
      title: '',
      key: 'front',
    },
    children: [
      {
        path: 'about-us',
        name: 'front-about-us',
        component: () => import(/* webpackChunkName: "AboutUs" */ '@/views/Front/AboutUs.vue'),
        meta: {
          title: '關於我們',
          layout: {

          },

        },
      },
      {
        path: 'contact',
        name: 'front-contact',
        component: () => import(/* webpackChunkName: "Contact" */ '@/views/Front/Contact.vue'),
        meta: {
          title: '聯絡我們',
          layout: {

          },
        },
      },
      {
        path: 'search',
        name: 'search',
        component: () => import(/* webpackChunkName: "Search" */ '@/views/Search/Search.vue'),
        meta: {
          title: '搜尋',

        },
      },
      {
        path: 'test123',
        name: 'test123',
        component: () => import(/* webpackChunkName: "Test123" */ '@/views/Test/Test123.vue'),
        meta: {
          title: 'test',

        },
      },
    ],
  },
];
