const labelMapping = {
  playlists: 'myMusic.allPlaylist',
  'search-history': 'myMusic.searchHistory',
  'play-history': 'myMusic.playedHistory',
  'download-history': 'myMusic.downloadHistory',
  'my-note': 'myMusic.myNote',
  'preview-tool': 'myMusic.previewTool',
  move: 'myMusic.move',
  copy: 'myMusic.copy',
  delete: 'myMusic.remove',
  invite: 'myMusic.invite',
  download: 'myMusic.downloadPlaylist',
};

export default labelMapping;
