const exploreType = {
  themes: '主題',
  moods: '氣氛',
  genres: '曲風',
  playlists: '歌單',
  albums: '專輯',
  labels: '品牌',
};

export default {
  leaveMessageSuccess: '留言已送出',
  submitSuccess: '成功送出',
  test: '測試',
  aboutPoint: '關於 音韶',
  contactUs: '有疑問嗎？請聯絡我們。',
  exploreType,
  fullMatch: '完全比對',
  keywordPlaceholder: '請輸入關鍵字...',
  learnMore: '查看更多',
  menulist: {
    explore: '探索',
    licensing: '授權服務',
    contact: '聯絡我們',
  },
  noData: '目前沒有資料',
  pagination: {
    prev: '上一頁',
    next: '下一頁',
    to: '前往頁面',
    of: '之',
  },
  rights: '音韶文化事業有限公司版權所有',
  searchPlaceholder: '搜尋關鍵字、曲風、情緒、樂器…',
  siteMap: {
    explore: {
      title: '探索',
      sub: exploreType,
    },
    'my-profile': {
      title: '會員專區',
      sub: {
        'my-profile': '我的帳號',
        playlists: '專案選曲',
        'search-history': '搜尋歷史',
        'play-history': '播放紀錄',
        'download-history': '下載紀錄',
        'preview-tool': '影音結合體會',
      },
    },
    licensing: {
      title: '法律聲明',
      sub: {
        terms: '使用條款',
        privacy: '隱私權政策',
        cookies: 'COOKIES 政策',
      },
    },
    other: {
      title: '關於',
      sub: {
        licensing: '授權服務',
        aboutUs: '關於我們',
        contact: '聯絡我們',
      },
    },
  },

  // form
  save: '儲存',
  email: '電子信箱',
  pleaseEntryEmail: '請輸入電子信箱',
  pleaseEnterCorrectEmailFormat: '請輸入正確的電子信箱格式',
  name: '姓名',
  pleaseEnter: '請輸入姓名',
  appellation: '性別',
  male: '先生',
  female: '小姐',
  mobile: '手機',
  pleaseEnterMobile: '請輸入手機',
  tel: '電話',
  pleaseEnterPhone: '範例 0287874775',
  phoneFormatErrorMsg: '電話號碼格式錯誤',
  city: '所在國家',
  pleaseSelectCountry: '請選擇所在國家',
  cityCounty: '居住城市/地區',
  pleaseSelectCityCounty: '請選擇居住城市/地區',
  businessType: '業務屬性',
  pleaseEnterBusinessType: '請選擇業務屬性',
  position: '職位',
  pleasePosition: '請輸入職位',
  companyName: '公司名稱',
  pleaseEnterCompanyName: '請輸入公司名稱',
  companyCountry: '公司國家',
  pleaseEntercompanyCountry: '請輸入公司國家',
  companyCity: '公司城市/地區',
  pleaseEnterCompanyCity: '請輸入公司城市/地區',
  companyAddress: '公司地址',
  pleaseEnterCompanyAddress: '請輸入公司地址',
  country: '國家',
  pwPlacehodler: '請輸入英數字、符號組合的 8-20 個字元',
  emailBox: '電子信箱',
  emailBoxPlaceholder: '請輸入電子信箱',
  nationality: '國籍',
  pleaseSelectNationality: '請選擇國籍',
  password: '密碼',
  passwordPlaceholder: '請輸入密碼',
  oldPw: '舊密碼',
  newPw: '新密碼',
  confirmNewPw: '確認新密碼',
  confirmPw: '確認密碼',
  submit: '送出',
  pwdRuleMsg: '密碼不符合規則：英文、數字、符號{specialCharactor}',
  pleaseEnterName: '請輸入名稱',
  notAllowSameAsOldPwd: '請勿與舊密碼相同',
  pwdNotConsist: '輸入密碼不一致',

  // 驗證錯誤訊息
  errPhoneFormat: '電話號碼格式錯誤',

  // other
  addSuccess: '加入成功',
  cancel: '取消',
  all: '全部',
  confirm: '確認',
  menu: {
    myProfile: 'MY ACCOUNT 我的帳號',
    explore: 'EXPLORE 探索',
    licensing: 'LICENSING 授權服務',
    contact: 'CONTACT 聯絡我們',
    aboutUs: 'ABOUT US 關於我們',
    myMusic: 'MEMBERS ONLY 會員專區',
  },
  myMusic: '會員專區',
  unexpcetedError: '非預期錯誤',
  defaultErrorMsg: '當前無法使用',
  sortBy: {
    desc: '上架時間(由新至舊)',
    asc: '上架時間(由舊至新)',
    name: '名稱(A-Z)',
    albumcode: '專輯編號(由多至少)',

  },
  albums: '專輯',
  pointCulture: '音韶文化',
  breadcrumb: {
    home: '首頁',
    explore: '探索',
  },
  edit: '編輯',
  back: '返回',
  startPlaying: '開始播放',
  switchDisplayMode: '播放器模式切換',
  downloadCompressing: '檔案壓縮下載中，請等待至完成再進行其他操作',
  dontShowAgain: '不要再顯示',
  version: '版本',
  draggbleSort: '拖曳排序',
  copyTrackInfo: '複製曲目資訊',
  copyLyrics: '複製歌詞',
  removeFilter: '清除條件',
};
