import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/router/routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      exact: true,
      name: 'home',
      component: () => import(/* webpackChunkName: "Home" */ '@/views/Home/index.vue'),
      meta: {
        title: '首頁',
        key: 'home',
      },
    },
    ...routes,
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const scrollEle = document.querySelector('.ly-fh__ab-wrapper');
  if (scrollEle) {
    scrollEle.scrollTo({
      top: 0,
      left: 0,
    });
  }

  next();
});

export default router;
