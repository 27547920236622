export default {
  contactUs: 'Contact Us',
  leaveMessage: 'Leave Message',
  contactInfo: 'Contact Information',
  name: 'Name',
  appellation: 'Gender',
  male: 'Male',
  female: 'Female',
  email: 'Email',
  questionType: 'Type of Question',
  questionContent: 'Content',
  pleaseEnterEmail: 'Please enter email',
  pleaseEnterQuestion: 'Please enter question',
  pleaseSelectQuestionType: 'Please select type of question',
  submit: 'Submit',
  contact: 'Contact Information',
  pointAddress: '4F., No. 165, Sec. 4, Bade Rd., Songshan Dist., Taipei City 105053 , Taiwan (R.O.C.)',
  quesOptAuthz: 'About Authorization',
  quesOptPick: 'Music select Aid',
  quesOptCompose: 'Customize Composing',
  quesOptInquiry: 'Inquiry',
  quesOptOperate: 'Web Operating',
  quesOptOpinion: 'Suggestion and Feedback',
  quesOptScore: 'Customize Post Production',
  quesOptOther: 'Others',
};
