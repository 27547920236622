import { defineStore } from 'pinia';
import { getAccountNoteAllAPI } from '@/api/myMusicNotes';

export const useCacheStore = defineStore({
  id: 'cacheStore',
  state: () => ({
    tagOptions: [],
    myMusicExpandPlayHistory: [],
    myMusicExpandDownloadHistory: [],
    tags: {},
    tagsUidKeys: [],
    isCalledTrackWithNotes: false,
    trackWithNotes: [], // 有筆記的 trackUidList
  }),

  actions: {
    storeTagOptions(opts) {
      this.tagOptions = opts;
    },
    saveMyMusicExpandPlayHistory(list) {
      this.myMusicExpandPlayHistory = list;
    },
    saveMyMusicExpandDownloadHistory(list) {
      this.myMusicExpandDownloadHistory = list;
    },
    saveTags(type, data) {
      if (type === 'object') {
        this.tags[data.trackUid] = data.formatTagList;
      }

      if (type === 'array') {
        data.forEach((item) => {
          this.tags[item.trackUid] = item.formatTagList;
        });
      }

      this.tagsUidKeys = Object.keys(this.tags);
    },
    cleanTagsCache() {
      if (this.tagsUidKeys.length > 3000) {
        this.tags = null; // 讓 gc 回收
        this.tags = [];
      }

      this.tagsUidKeys = Object.keys(this.tags);
    },
    // 避免重複 call
    initTrackWithNotes() {
      if (this.isCalledTrackWithNotes) return;
      this.isCalledTrackWithNotes = true;

      this.trackWithNotesReq();
    },
    // 初始化後可能因為修改或刪除筆記，需要更新有筆記的Id
    async trackWithNotesReq() {
      const [err, res] = await getAccountNoteAllAPI({
        in: {
          keyword: '',
          trackUid: '',
        },
      });

      if (err) {
        console.log('err...', err);
        return;
      }

      this.trackWithNotes = res.getAccountNoteAll.map((item) => item.originUid);
    },

  },
});

export const a = 0;
