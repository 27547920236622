export default {
  accountData: '帳號資料',
  changePw: '更改密碼 ',
  preferSetting: '偏好設定 ',
  pointClient: '音韶客服',
  memberType: '會員類型',
  downloadAuth: '下載權限',
  downloadCount: '已下載數量/可下載數量',
  contractPeriod: '契約起訖期間',
  emailNotice: '電子信箱即為您的登入帳號，如有異動需求請聯繫我們',
  verified: '已驗證',
  unverify: '未驗證',
  sendVerifyEmail: '發送驗證信',
  verifyEmailSent: '驗證信已發送',
  accountModifySuccess: '帳號資料修改成功',
  changePwSuccess: '密碼更改成功',
  defaultDownloadExt: '下載格式',
  searchResultPrefer: '搜尋結果偏好',
  wave: '波形',
  desc: '描述',
  tag: '標籤',
  csNotice1: '如果您有任何疑問，請聯繫下列專屬客服。或前往',
  csNoticeContact: '聯絡我們',
  csNotice2: '留下您的問題，我們會盡快與您聯絡！',
  cs: '客服',
  saveSuccess: '儲存成功',
  notSupportMobile: '手機不支援此頁面',
  modify: '更換',
  formerMember: '正式會員',
  normalMember: '一般會員',
  inReview: '審核中',
  unverified: '尚未驗證',
  waitForAudit: '待審核',
  fileLimit1mb: '檔案大小不得超過 1MB',
  updateAvatarSuccess: '更新頭像成功',
  myAccount: '我的帳號',
  downloadPeriod: '限制下載期間',
  unlimited: '無限制',
  askingDownloadFormat: '格式詢問',
  downloadAlbum: '下載專輯',
  downloadPlaylist: '下載歌單',
  downloadTrack: '下載曲目',
  selectDownloadFormat: '請選擇格式',
  downloadFormat: '下載格式',
  musicPlayerStyle: '播放模式偏好',
  musicPlayerStyleFull: '展開模式',
  musicPlayerStyleSimple: '簡易模式',
  musicPlayerStyleCircle: '圓形模式',
};
