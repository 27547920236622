export default {
  tags: '標籤',
  tracks: '曲目',
  albums: '專輯',
  playlists: '歌單',
  lyrics: '歌詞',
  composers: '作曲者',
  filterCondtions: '篩選條件',
  length: '長度',
  availableTags: '可選擇標籤',
  removeAll: '清除全部',
  searchType: '搜索類別',
  firstLayer: {
    instruments: '樂器',
    tempos: '速度',
    countryList: '國家',
    editType: '版本長度',
    versionType: '版本類型',
    musicStyles: '曲風',
    moods: '氣氛',
    musicFor: '音樂用途',
    eras: '年代',
    albumCompany: '品牌',
  },
  searchConditionChange: '搜尋條件已變更，請重新執行搜尋',
  pleaseFillSearchCondition: '請填寫/選擇搜尋條件',
  noData: '查無資料',
  searchResult: '搜尋結果',
  pleaseEnterKeyword: '請輸入關鍵字',
  selectedTags: '已選擇標籤{count}個',
};
