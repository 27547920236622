export default {
  about:
    'Point Culture is the largest production music agent in Taiwan, with over millions of collections of music, we are here to provide the best service in the industry.',
  explore: 'INSPIRATION FOR YOUR EXPLORATIONS',
  hot: {
    title: 'Hot',
    Albums: 'Albums',
    Playlists: 'Playlists',
    Genres: 'Music Styles',
    Moods: 'Moods',
  },
  inherit: {
    title: 'KEEP THE MUSIC GOING',
    text: 'Create your free account, start exploring music, list your own collection and create a touching experience.',
  },
  sloganA: 'We are the largest production music agent in Taiwan, more than one million music, providing a variety of commercial use options,',
  sloganB: 'Comprehensive music to meet the needs of various music scoring.',
  learnMore: 'Learn More',
};
