<template>
  <div class="c-leave-self-checking-confirm">
    <p class="text"> {{ msg }} </p>

    <div class="never-show-again">
      <n-checkbox v-model:checked="neverShow" @update:checked="handleRecordAnnounecementUid">
        {{ data.dontShowAgain }}
      </n-checkbox>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, ref,
} from 'vue';
import { NCheckbox } from 'naive-ui';
import cusStorage from '@/utils/cusStorage';

export default defineComponent({
  components: {
    NCheckbox,
  },
  props: {
    msg: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['closeDialog'],
  setup(props, { emit }) {
    const neverShow = ref(false);
    const closeDialog = () => {
      emit('closeDialog');
    };

    const handleRecordAnnounecementUid = (val) => {
      if (val) {
        cusStorage.setItem('announcementUid', props.data.uid);
      } else {
        cusStorage.removeItem('announcementUid');
      }
    };

    return {
      closeDialog,
      neverShow,
      handleRecordAnnounecementUid,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-leave-self-checking-confirm {
  @include flex(center, center, column);
}

.text {
  @include font-style($c-main, 14, 700);
}

.btn-wrap {
  margin-top: 30px;
}

.never-show-again {
  margin-top: 10px;
}
</style>

<style lang="scss">
// fixme: 太多了吧，有空再整理
.n-checkbox .n-checkbox-box {
  background-color: #BA2424;
  border-color: #BA2424;
}

.n-checkbox .n-checkbox-box {
  background-color: #BA2424;
  border-color: #BA2424;
}

.n-checkbox .n-checkbox-box .n-checkbox-box__border {
  border-color: #BA2424;

  &:hover {
    border-color: #BA2424;
  }
}

.n-checkbox.n-checkbox--checked .n-checkbox-box {
  background-color: #BA2424;
  border-color: #BA2424;
}

.n-checkbox.n-checkbox--checked .n-checkbox-box {
  background-color: #BA2424;
  border-color: #BA2424;
}

.n-checkbox.n-checkbox--checked .n-checkbox-box .n-checkbox-box__border {
  border-color: #BA2424;

  &:hover {
    border-color: #BA2424;
  }
}

.n-checkbox:focus:not(:active) .n-checkbox-box .n-checkbox-box__border {
  box-shadow: 0 0 0 2px transparent;
  border-color: #BA2424;
}

.n-checkbox.n-checkbox--checked:focus:not(:active) .n-checkbox-box .n-checkbox-box__border {
  box-shadow: 0 0 0 0px transparent;
  border-color: #BA2424;
}

.n-checkbox .n-checkbox__label {

  &:hover {
    color: #BA2424;
  }
}
</style>
