<template>
  <div class="c-share function-icon" @click="handleDownload">
    <tooltip :label="$t('myMusic.download')">
      <div class="function-icon">
        <div class="function-icon__img function-icon__img--download"></div>
        <span class="function-icon__label" v-if="label"> {{ $t('myMusic.download') }} </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false" @after-leave="reset">
      <model-base-layout :title="$t('myMusic.download')" decoLine contentPadding>
        <p>{{ $t('member.downloadTrack') }}: {{ trackNameDisplay }}</p>

        <div class="select-wrap">

          <n-form :model="downloadForm" :rules="rules" ref="downloadFormRef">
            <n-form-item path="downloadFormat" :label="$t('member.downloadFormat')">

              <n-radio-group v-model:value="downloadForm.downloadFormat" name="radiogroup1">
                <n-space>
                  <n-radio value="MP3">MP3</n-radio>
                  <n-radio value="WAV">WAV</n-radio>
                </n-space>
              </n-radio-group>
            </n-form-item>
          </n-form>
        </div>

        <div class="btn-wrap">
          <div class="btn-wrap__btn">
            <mi-button type="border" @click="closeModal">
              <span>{{ $t('global.cancel') }}</span>
            </mi-button>
          </div>
          <div class="btn-wrap__btn" @click="submitDownloadFormat">
            <mi-button>
              <span>{{ $t('myMusic.download') }}</span>
            </mi-button>
          </div>
        </div>
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, watch, ref } from 'vue';
// computed
import {
  NModal,
  NSpace,
  NRadioGroup,
  NRadio,
  NForm,
  NFormItem,
} from 'naive-ui';
import { useI18n } from 'vue-i18n';
import MiButton from '@/components/Global/MiButton.vue';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
// import { useI18n } from 'vue-i18n';
// import { useGtm } from '@gtm-support/vue-gtm';
import Tooltip from '@/components/Global/Tooltip.vue';
import { downloadAllAPI } from '@/api/download';
import useDownloadFile from '@/hooks/useDownloadFile';
// import formatBlob from '@/utils/formatBlob';
// import { defaultErrorMsg } from '@/config/messageConfig';
// import { useSiteStore } from '@/store/site';
// import { getTrackAPI } from '@/api/getTrack';
import useRequest from '@/hooks/useRequest';
// import useGtm from '@/hooks/useGtm';
import usePopInfo from '@/hooks/usePopInfo';
import genMessage from '@/utils/handleMessage';
import { useSiteStore } from '@/store/site';
import { getTrackAPI } from '@/api/getTrack';

export default defineComponent({
  name: 'Download',
  components: {
    Tooltip,
    NModal,
    ModelBaseLayout,
    NSpace,
    NRadioGroup,
    NRadio,
    MiButton,
    NForm,
    NFormItem,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { fileDownload } = useDownloadFile();
    const { request } = useRequest(getTrackAPI);
    const { errorPopInfo } = usePopInfo();
    const siteStore = useSiteStore();
    const modelStatus = ref(false);
    const trackName = ref('');
    const trackNameDisplay = ref('');
    const downloadFormRef = ref();
    const downloadFormat = ref('');
    const downloadForm = ref({
      downloadFormat: '',
    });
    const rules = {
      downloadFormat: {
        required: true,
        message: t('member.selectDownloadFormat'),
      },
    };

    const closeModal = () => {
      modelStatus.value = false;
    };

    const fetchTrackData = async () => {
      const trackUid = props.data?.trackUid || props.data?.track?.trackUid;
      const [, trackRes] = await request({ in: trackUid });

      const {
        albumCode,
        trackNumber,
        trackTitleDisplay,
      } = trackRes.getTrack;

      trackName.value = `${albumCode}_${Number(trackNumber)}_${trackTitleDisplay}`;
      trackNameDisplay.value = `${trackTitleDisplay} (${albumCode}_${Number(trackNumber)})`;
    };

    const download = async (downloadType) => {
      const trackUid = props.data?.trackUid || props.data?.track?.trackUid;

      // 太多地方有下載，每個點可點擊下載的地方給的 props 內容不同，所以重新取得曲目詳細資料，避免非預期錯誤（props給的資訊不足之類的）
      // const [, trackRes] = await request({ in: trackUid });

      // const {
      //   albumCode,
      //   trackNumber,
      //   trackTitleDisplay,
      // } = trackRes.getTrack;

      // const fileName = `POINT_${albumCode}_${Number(trackNumber)}_${trackTitleDisplay}`;
      // const fileName = `POINT_${trackName.value}`;

      // 不是錯誤，只是個提醒（最初命名用於錯誤）
      errorPopInfo({
        desc: t('global.downloadCompressing'),
      });

      const [, res] = await downloadAllAPI(
        {
          tracks: [{
            trackUid,
            title: 'track', // 沒啥用
          }],
          folderName: '',
          downloadType,
        },
      );

      // 錯誤時可以blob轉成json
      const isJsonBlob = (data) => data instanceof Blob && data.type === 'application/json';

      if (isJsonBlob(res.data)) {
        const responseObj = await res.data.text();
        const { errors } = JSON.parse(responseObj);

        genMessage(errors[0].message, errors[0].extensions);
        return;
      }

      const contentDisposition = res.headers['content-disposition'];

      fileDownload(res?.data, contentDisposition);

      // fileDownload(res?.data, fileName, downloadType.toLowerCase());
    };

    const reset = () => {
      downloadForm.value.downloadFormat = '';
    };

    const handleDownload = () => {
      fetchTrackData();
      if (siteStore.memberInfo.downloadTypeRequest) {
        modelStatus.value = true;
        return;
      }

      download(siteStore.memberInfo.downloadType);
    };

    const handleValidateClick = async () => {
      const errors = await downloadFormRef.value.validate();

      return errors;
    };

    const submitDownloadFormat = async () => {
      const error = await handleValidateClick();

      if (error) {
        return;
      }

      download(downloadForm.value.downloadFormat);
      closeModal();
    };

    // fetchTrackData();

    return {
      modelStatus,
      closeModal,
      handleDownload,
      downloadFormat,
      downloadForm,
      downloadFormRef,
      handleValidateClick,
      rules,
      submitDownloadFormat,
      reset,
      trackName,
      trackNameDisplay,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';

.btn-wrap {
  margin-top: 50px;
  @include flex(flex-end);

  &__btn {

    &+& {
      margin-left: 10px;
    }
  }
}

.select-wrap {
  margin-top: 20px;
}
</style>
