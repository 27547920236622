<template>
  <div class="c-download-btn" v-if="data.visible">

    <tooltip :label="$t('myMusic.download')">
      <mi-button icon="download" @click="handleDownload">

        <!-- <img class="download-icon" src="@/assets/global/icon_download.svg" /> -->
        <!-- <span class="download-text">Download</span> -->
      </mi-button>
    </tooltip>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import MiButton from '@/components/Global/MiButton.vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'DownloadBtn',
  components: {
    MiButton,
    Tooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const handleDownload = () => {

    };

    return {
      handleDownload,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-download-btn {
  margin-left: 12px;
}
.download-icon {
  width: 25px;
}

.download-text {
  margin-left: 5px;
}
</style>
