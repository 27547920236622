export default {
  // firstView
  aboutUs: '关于我们',
  aboutDesc1: '音韶为台湾最大版权音乐代理商，',
  aboutDesc2: '超过百万首音乐为您提供多样化选择，',
  aboutDesc3: '齐全、丰富的音乐，满足您各类需求。',

  // agent
  agentRedTitle: '台湾最大版权音乐代理商',
  agentDesc: '音韶文化于西元1997年成立，以代理版权音乐为主要业务，目前为全台湾最大的版权音乐代理公司。汇集全世界知名品牌，提供最丰富的音乐曲库为您的影片增添更好的灵感及创意。',
  serviceSearch: '线上音乐搜索',
  serviceExclusive: '独家音乐授权',
  serviceCommercial: '音乐代理洽谈',

  // experience
  experienceRedTitle: '探索音乐，创造感动体验',
  explore: '搜索',
};
