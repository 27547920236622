export default {
  loginSuccess: '登录成功',
  validateSuccess: '电子邮箱验证成功！',
  resetPwdSuccess: '重设密码成功！',
  registerSuccess: '注册成功',
  removeConfirm: '确定删除 ?',
  logoutConfirm: '确定登出 ?',
  removeSuccess: '删除成功',
  addSuccess: '加入成功',
  createSuccess: '新增成功',
  addNoteSuccess: '新增笔记成功',
  updateNoteSuccess: '更新笔记成功',
  saveSuccess: '储存成功',
  removeNoteSuccess: '删除笔记成功',
  copySuccess: '复制成功',
  copyFail: '复制失败',
};
