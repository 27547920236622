<template>
  <div
    class="c-label-item"
    :class="{ 'c-label-item--is-search': isSearch, 'c-label-item--is-search-pop': isSearchPop, 'c-label-item--allow-click': route.name === 'search' || allowClick }"
    @click="addLabel(label)"
  >
    <p class="c-label-item__text">
      {{ label.label }}
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useSearchStore } from '@/store/search';

export default defineComponent({
  name: 'LabelItem',
  props: {
    label: {
      type: Object,
      default: () => ({}),
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isSearchPop: {
      type: Boolean,
      default: false,
    },
    searchTagImmediately: {
      type: Boolean,
      default: false,
    },
    allowClick: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closePop'],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const searchStore = useSearchStore();

    const addLabel = (tag) => {
      // 只有 搜尋頁面 和 搜尋pop 可以點

      if (props.allowClick) {
        router.push({
          name: 'search',
          query: {
            type: 'tracks',
            keyword: searchStore.searchValue.keyword,
            includeTags: JSON.stringify([tag.tagUid]),
          },
        });

        emit('closePop');
      }

      if (route.name === 'search') {
        searchStore.setIncludeTags(tag);
      }
    };

    return {
      addLabel,
      route,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-label-item {
  height: 25px;
  @include padding(0 5px);
  @include inline-flex(center);
  border: 1px solid $c-main;
  margin-right: 10px;
  margin-bottom: 10px;

  &--is-search {
    height: 34px;
    @include padding(0 10px);

    .c-label-item__text {
      @include font-style($c-assist6, 14, 600, normal, 18px);
    }
  }

  &--is-search-pop {
    height: 34px;
    @include padding(0 10px);
    background-color: $c-main;

    .c-label-item__text {
      @include font-style($c-white, 14, 600, normal, 18px);
    }
  }

  &--allow-click {
    cursor: pointer;

  }

  &__text {
    @include font-style($c-main, 12, 600, normal, 18px);
  }
}
</style>
