<template>
  <div class="c-mobile-explore">
    <ul class="menu-explore-list">
      <li class="menu-explore-list__item" v-for="(item, idx) of menuExplore" :key="item.label">
        <div
          class="item-box"
          :style="{ 'background-image': `url(${menuExploreImg[idx + 1]})` }"
          @click="toPage(item.routeType)"
        >
          <p class="item-box__label">{{ item.label }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';
import { menuExplore, menuExploreImg } from '@/config/menuExplore';

export default defineComponent({
  name: 'MobileMenuExplore',
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();

    const toPage = (type) => {
      if (type) {
        router.push({
          name: 'explore-type',
          params: {
            type,
          },
        });
        siteStore.toggleShowMobileMenuContent(false);
      }
    };

    return {
      menuExplore,
      menuExploreImg,
      toPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.menu-explore-list {
  @include flex();
  flex-wrap: wrap;
  margin: 0 -3.5px;

  &__item {
    flex: none;
    width: 50%;
    @include padding(0 3.5px);
    margin-top: 7px;
    cursor: pointer;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

.item-box {
  // @include padding(0 3.5px);
  @include flex(center);
  width: 100%;
  height: 60px;
  background-size: cover;
  background-position: center;

  &__label {
    @include font-style($c-white, 18, 700, 0, 24px);
  }
}
</style>
