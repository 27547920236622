export default {
  // firstView
  aboutUs: 'ABOUT US',
  aboutDesc1: 'WE ARE THE LARGEST PRODUCTION MUSIC AGENT IN TAIWAN.',
  aboutDesc2: 'Over one million music, providing a variety of commercial use options,',
  aboutDesc3: 'Comprehensive music to meet the needs of various music scoring.',

  // agent
  agentRedTitle: 'THE LARGEST PRODUCTION MUSIC AGENT IN TAIWAN.',
  agentDesc: 'Point Culture was established in 1997. Initially, it was mainly engaged in the agency of copyrighted music. Currently, we are the largest production music agent in Taiwan, including a lot of world-renowned brands. Also providing the richest music library to help you get more inspiration and creative featured your projects.',
  serviceSearch: 'Music search online',
  serviceExclusive: 'Exclusive music license agreements',
  serviceCommercial: 'Music by agent negotiation',

  // experience
  experienceRedTitle: 'Explore music and create a touching experience',
  explore: 'Explore',
};
