<template>

  <div class="c-music-player" :class="[`c-music-player--${styleType}`]" v-show="isShowMusicPlayer">
    <Transition name="slide-fade-bottom">
      <queue v-show="expandStatus.queue" v-model:queue="expandStatus.queue" />
    </Transition>
    <Transition name="slide-fade-bottom">
      <version v-show="expandStatus.version" v-model:version="expandStatus.version" />
    </Transition>
    <div class="c-music-player__content">
      <!-- mobile -->
      <div class="mobile-music-player" @click="openMobileMusicPlayer">
        <div class="mobile-music-player__content">
          <div class="mobile">
            <div class="mobile__album-cover">
              <img class="mobile__album-cover__img" :src="albumCover" @click="toAlbumPage" />
            </div>
            <div class="mobile__main">
              <div class="mobile__main__music-info">
                <music-info style-type="mobile" />
              </div>
              <div class="mobile__main__music-controll" @click.stop>
                <music-controll style-type="mobile" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- above tablet 有三種形式 -->

      <!-- {{musicFileUrl}} -->
      <circle-style @click="switchStyleType" v-show="styleType === 'circle' && isShowMusicPlayer" />
      <div class="standard-style" :class="[`standard-style--${styleType}`]" v-show="styleType !== 'circle' && isShowMusicPlayer">
        <audio style="display: none" ref="musicPlayerRef" controls :src="musicFile.url" @timeupdate="handleTimeupdate"
          @ended="handleOnEnded">
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <div class="dashboard">
          <div class="dashboard__album-cover" v-show="styleType === 'full'">
            <router-link :to="{ path: `/explore/albums-info/${musicInfo.albumUid}` }">
              <img class="dashboard__album-cover__img" :src="albumCover" @click="toAlbumPage" />
            </router-link>
          </div>
          <div class="dashboard__main" :class="`dashboard__main--${styleType}`">
            <div class="dashboard__main__upper">
              <div class="dashboard__main__upper__music-info">
                <music-info :style-type="styleType" />
              </div>
              <div class="dashboard__main__upper__music-controll">
                <music-controll :style-type="styleType" />
              </div>
              <div class="dashboard__main__upper__music-function">
                <music-function :showPlay="false" :data="currentPlayTrackInfo"
                  :functionList="['download', 'note', 'share']" />
              </div>
            </div>
            <div class="dashboard__main__bottom" :class="`dashboard__main__bottom--${styleType}`">
              <div class="dasboard__main__bottom__music-sound">
                <music-volume :style-type="styleType" v-model:version="expandStatus.version"
                  v-model:queue="expandStatus.queue" />
              </div>
            </div>
          </div>
          <div class="dashboard__switch-icon" :class="`dashboard__switch-icon--${styleType}`">
            <tooltip :label="$t('global.switchDisplayMode')" :placement="styleType === 'full' ? 'right' : 'center'">
              <img class="dashboard__switch-icon__img" src="@/assets/icon/playbar_switch.svg" @click="switchStyleType" />
            </tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  reactive,
  watch,
  onMounted,
  // watchEffect
} from 'vue';
// import { v4 as uuid } from 'uuid';
import { useRoute, useRouter } from 'vue-router';

import CircleStyle from '@/components/Global/MusicPlayer/CircleStyle.vue';
import MusicControll from '@/components/Global/MusicPlayer/MusicControll.vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';
import MusicInfo from '@/components/Global/MusicPlayer/MusicInfo.vue';
import MusicVolume from '@/components/Global/MusicPlayer/MusicVolume.vue';
import Version from '@/components/Global/MusicPlayer/Version.vue';
import Queue from '@/components/Global/MusicPlayer/Queue.vue';
import { useSiteStore } from '@/store/site';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import imageSource from '@/utils/imageSource';
import useMusicPlayer from '@/hooks/useMusicPlayer';
import useGetTrackFile from '@/hooks/useGetTrackFile';
import parseQuery from '@/utils/parseQuery';
import Tooltip from '@/components/Global/Tooltip.vue';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import cusStorage from '@/utils/cusStorage';

export default defineComponent({
  name: 'MuiscPlayer',
  components: {
    Tooltip,
    CircleStyle,
    MusicControll,
    MusicFunction,
    MusicInfo,
    MusicVolume,
    Version,
    Queue,
  },
  setup() {
    /* eslint-disable no-unused-vars */
    const defaultPlayerStyle = cusStorage.getItem('musicPlayerStyle');
    const init = ref(false);
    const isFetchingTrackUrl = ref(false);
    const shareInit = ref(false);
    const route = useRoute();
    const { formatTrackDetail, reqTrackDetail, playMusic, reqTrack, formatTrack } = useMusicPlayer();
    const { getTrackFileUrl } = useGetTrackFile();
    const audioPlayer = ref(null);
    const siteStore = useSiteStore();
    // const musicPlayerStore = useMusicPlayerStore();
    const styleType = ref(defaultPlayerStyle || 'full');
    const musicPlayerRef = ref();
    const expandStatus = reactive({
      version: false,
      queue: false,
    });
    const newMusicPlayerStore = useNewMusicPlayerStore();
    const router = useRouter();
    const isShowMusicPlayer = computed(() => {
      return newMusicPlayerStore.isShowMusicPlayer;
    });
    const currentPlayTrackInfo = computed(() => {
      return {
        trackName: newMusicPlayerStore?.currentPlayTrackInfo?.track?.trackName,
        trackUid: newMusicPlayerStore?.currentPlayTrackInfo?.track?.trackUid,
        editType: newMusicPlayerStore?.currentPlayTrackInfo?.track?.editType,
        versionType: newMusicPlayerStore?.currentPlayTrackInfo?.track?.versionType,
      };
    });

    // 初始處理：重新取得音檔
    // const stop = watchEffect(async () => {
    //   if (isFetchingTrackUrl.value) return;

    //   if (audioPlayer.value && !init.value) {
    //     audioPlayer.value.volume = musicPlayerStore.controll.volume.progressPersentage / 100;
    //   }

    //   // 分享
    //   if (route?.query?.tu && !shareInit.value && audioPlayer.value && !musicPlayerStore.played) {
    //     isFetchingTrackUrl.value = true;
    //     const uniqueUid = uuid();

    //     // 取得主版本 並塞入queue
    //     const [, res] = await reqTrackDetail({ in: route?.query?.tu });
    //     const master = res.getTrackDetail?.trackList.find(({ isMasterVersion }) => isMasterVersion);
    //     musicPlayerStore.handleAddToPlayQueue([{
    //       ...formatTrackDetail({ ...master, albumUid: res?.getTrackDetail?.detail?.album?.albumUid, uuid: uniqueUid }),
    //       title: master?.trackTitleDisplay,
    //     }]);

    //     // 取得分享曲目
    //     const [, trackRes] = await reqTrack({ in: route?.query?.tu });
    //     musicPlayerStore.handleSetInfo({ ...formatTrack(trackRes?.getTrack).albumInfo, uuid: uniqueUid });
    //     const trackUrl = await getTrackFileUrl({ trackUid: musicPlayerStore.info.trackUid, accountUid: siteStore?.memberInfo?.accountUid || '' });
    //     isFetchingTrackUrl.value = false;
    //     musicPlayerStore.setMusicFile(trackUrl);
    //     shareInit.value = true;
    //     return;
    //   }
    //   // localstorage
    //   if (musicPlayerStore.info.trackUid && !init.value && audioPlayer.value && !musicPlayerStore.played) {
    //     isFetchingTrackUrl.value = true;
    //     // const trackUrl = await getTrackFileUrl({ trackUid: musicPlayerStore.info.trackUid, accountUid: siteStore?.memberInfo?.accountUid || '' });
    //     isFetchingTrackUrl.value = false;
    //     // musicPlayerStore.setMusicFile(trackUrl);
    //     init.value = true;
    //   }
    // });

    // 初始處理結束 結束監聽
    // watch([init, shareInit], ([initStatus, shareStatus]) => {
    //   if (initStatus && shareStatus) stop();
    // });

    // 當播放器從未播放過 && 有上次最後播放時間 && 初始完成 => 將上次最後播放時間塞入currentTime
    // const loadeddata = () => {
    //   if (route?.query?.tu) {
    //     audioPlayer.value.currentTime = route?.query?.t || '0';
    //     return;
    //   }

    //   const targetTime = route?.query?.tu || musicPlayerStore.lastStoppedAt;
    //   if (!musicPlayerStore.played && targetTime && !isFetchingTrackUrl.value) {
    //     audioPlayer.value.currentTime = targetTime;
    //   }
    // };
    // 觸發播放器[播放||停止]
    // watch(() => musicPlayerStore.controll.isPlaying, (isPlaying) => {
    //   if (isPlaying) {
    //     // audioPlayer.value.pause();
    //     setTimeout(async () => {
    //       const playStatus = await audioPlayer.value.play();
    //       playStatus.catch(() => musicPlayerStore.handlePlayStatus(false));
    //     }, 100);
    //   } else {
    //     audioPlayer.value.pause();
    //   }
    // });

    // watch(() => musicPlayerStore.forceToTime, (sec = 0) => {
    //   audioPlayer.value.currentTime = sec;
    // });

    // watch(() => musicPlayerStore.forceToVolume, (num) => {
    //   audioPlayer.value.volume = num;
    // });

    const musicFile = computed(() => newMusicPlayerStore.musicFile);

    // const pause = () => {
    //   musicPlayerStore.setAudioElePlayStatus(false);
    //   musicPlayerStore.handlePlayStatus(false);
    // };

    // const timeupdate = (event) => {
    //   musicPlayerStore.setCurrentTime(event.target.currentTime);
    //   // 播放器有觸發初次播放 才開始存最後播放時間
    //   if (musicPlayerStore.played) {
    //     musicPlayerStore.setLastStoppedAt(event.target.currentTime);
    //   }
    // };

    // const playing = () => {
    //   musicPlayerStore.setAudioElePlayStatus(true);
    //   musicPlayerStore.handlePlayStatus(true);
    // };

    // const getTrackUrlAndPlay = async (variables) => {
    //   const fileUrl = await getTrackFileUrl(variables);
    //   musicPlayerStore.setMusicFile(fileUrl);
    //   playMusic(musicPlayerStore.info);
    // };

    // const ended = () => {
    //   musicPlayerStore.handlePlayStatus(false);
    //   musicPlayerStore.handleNext(getTrackUrlAndPlay, musicPlayerStore?.info?.isMasterVersion, siteStore?.memberInfo?.accountUid);
    // };

    // const handleStyleType = () => {
    //   musicPlayerStore.setFooterBottomExtraPaddingType(styleType.value);
    //   musicPlayerStore.setMusicPlayerStyle(styleType.value);

    //   if (styleType.value !== 'full') {
    //     expandStatus.version = false;
    //     expandStatus.queue = false;
    //   }
    // };

    const switchStyleType = () => {
      const mappingStyleType = ['circle', 'full', 'simple'];

      const nextStyleTypeIndex = (mappingStyleType.indexOf(styleType.value) + 1) % 3;
      styleType.value = mappingStyleType[nextStyleTypeIndex];
      newMusicPlayerStore.setMusicPlayerStyle(styleType.value);

      // if (styleType.value === 'circle') {
      //   expandStatus.version = false;
      //   expandStatus.queue = false;
      // }
      expandStatus.version = false;
      expandStatus.queue = false;
      // handleStyleType();
    };

    const openMobileMusicPlayer = () => {
      siteStore.toggleShowMobileMusicPlayer(true);
    };

    const albumCover = computed(() => imageSource(newMusicPlayerStore.musicInfo.cover));
    // const allowToAlbum = computed(() => (!!musicPlayerStore.playQueue?.length));

    const toAlbumPage = () => {
      if (newMusicPlayerStore.queueList.length > 0) {
        router.push({
          name: 'explore-albums-info',
          params: {
            id: newMusicPlayerStore.musicInfo.albumUid,
          },
        });
      }
    };

    const spaceControllPlayStatus = () => {
      window.addEventListener('keydown', (e) => {
        if (e.keyCode === 32) {
          if (!newMusicPlayerStore.isShowMusicPlayer) { return; }
          e.preventDefault();
          const status = !newMusicPlayerStore.playControll.play;
          newMusicPlayerStore.setControllPlayerStatus(status);
        }
      });
    };

    const adjustVolume = () => {
      if (!musicPlayerRef.value) return;

      let volume = +(newMusicPlayerStore.volume ?? 1);
      if (Number.isNaN(volume)) volume = 1;
      else if (volume < 0) volume = 0;
      else if (volume > 1) volume = 1;

      musicPlayerRef.value.volume = volume;
    };

    onMounted(() => {
      const result = parseQuery(`${window.location.search}&`);

      if (result.tu) {
        styleType.value = 'full';
        // handleStyleType();
      }

      // console.log(route.name, route.name === 'explore-albums-info', route.query?.tu, isNumber(route.query?.t));
      spaceControllPlayStatus();

      adjustVolume();
      // newMusicPlayerStore.init();
    });

    const handleOnEnded = async () => {
      await newMusicPlayerStore.playNextTrack();
    };

    const handleTimeupdate = (e) => {
      newMusicPlayerStore.setCurrentTime(e.target.currentTime);
    };

    watch(
      () => newMusicPlayerStore.playControll.play,
      (val) => {
        if (val) {
          musicPlayerRef.value.play();
        } else {
          musicPlayerRef.value.pause();
        }
      },
    );

    watch(
      () => newMusicPlayerStore.playControll.manuallyPlayFrom,
      (val) => {
        musicPlayerRef.value.currentTime = val;
      },
    );

    watch(
      () => newMusicPlayerStore.volume,
      () => {
        adjustVolume();
      },
      { immediate: true, flush: 'post' },
    );

    return {
      musicPlayerRef,
      handleTimeupdate,
      currentPlayTrackInfo,
      isShowMusicPlayer,
      // ---
      // pause,
      // loadeddata,
      musicFile,
      // playing,
      // ended,
      // timeupdate,
      audioPlayer,
      styleType,
      switchStyleType,
      musicInfo: computed(() => newMusicPlayerStore.musicInfo),
      expandStatus,
      openMobileMusicPlayer,
      albumCover,
      imageSource,
      toAlbumPage,
      handleOnEnded,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/styles/share/animate.scss';

.c-music-player {
  // display: none;
  position: relative;
  z-index: $zi-mobile-player;
}

.mobile-music-player {
  background-color: $c-black;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 55px;

  &__content {
    @include padding(0 30px 12px);
  }
}

.mobile {
  @include flex();

  &__album-cover {
    position: relative;
    top: -7px;

    &__img {
      width: 50px;
      height: 50px;
      vertical-align: bottom;
    }
  }

  &__main {
    width: 100%;
    @include flex();

    &__music-info {
      flex: 1;
      // sflex: abs($number: 0);
    }

    &__music-controll {
      flex: none;
      width: auto;
    }
  }
}

.standard-style {
  display: none;
}

@media screen and (min-width: $tablet) {
  .mobile-music-player {
    display: none;
  }

  .c-music-player {
    display: inline-block;
    position: fixed;
    z-index: $zi-player;

    &__type {}

    &__content {
      width: 100%;
      height: 100%;
      // position: relative;
      // z-index: 10;
    }
  }

  .music-wave {
    @include position(center);
    @include flex(center);
    width: 40px;
    height: 40px;

    &__bar {
      width: 6px;
      height: 40px;
      background-color: $c-white;
      transform-origin: bottom;

      &__wave1 {
        animation: wave1 0.45s infinite linear;
      }

      &__wave2 {
        animation: wave2 0.7s infinite linear;
      }

      &__wave3 {
        animation: wave3 0.9s infinite linear;
      }

      &__wave4 {
        animation: wave4 0.58s infinite linear;
      }

      &+& {
        margin-left: 5px;
      }
    }
  }

  .circle {
    width: 100%;
    height: 100%;
    position: relative;

    &__svg {
      @include position(center);
    }

    &__svg-static {
      stroke: $c-assist3;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
      @include position(center);
    }

    &__svg-dynamic {
      stroke: $c-white;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
      @include position(center);
    }
  }

  .dashboard {
    @include flex();
    width: 100%;

    &__album-cover {
      width: 110px;

      &__img {
        width: 100%;
        vertical-align: bottom;
        margin-top: -80px;
      }
    }

    &__main {
      flex: 1;
      width: 100%;
      @include flex(flex-end, flex-end, column);

      &__upper {
        width: 100%;
        @include flex(flex-start);

        &__music-info {
          flex: none;
          width: auto;
          display: flex;
          align-self: flex-end;
        }

        &__music-controll {
          margin-left: 26px;
          flex: 1;
        }

        &__music-function {
          flex: none;
          width: auto;
          margin-left: 26px;
        }
      }

      &__bottom {
        margin-top: 5px;
        margin-left: 10px;

        &__music-sound {}
      }
    }

    &__switch-icon {
      flex: none;
      width: auto;
      margin-left: 4px;
      display: flex;
      align-self: flex-start;
      cursor: pointer;

      &__img {
        width: 30px;
      }
    }
  }

  // 圓形播放器
  .c-music-player--circle {
    @include circle(100px);
    bottom: 80px;
    right: 80px;
  }

  .standard-style {
    display: block;
  }

  // 完整版的播放器
  .c-music-player--full {
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .standard-style {
      background-color: rgba($c-black, 0.92);
      position: relative;
      @include padding(20px 20px 18px 36px);
      width: 100%;
      // height: auto;
      height: 120px;
    }
  }

  // 簡易版的播放器
  .c-music-player--simple {
    @include max-width(1700);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .standard-style {
      background-color: $c-black;
      position: relative;
      @include padding(10px);
      height: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .dashboard {
      &__main {
        @include flex(flex-start, center, row);

        &__bottom {
          margin-top: 0px;
          margin-left: 93px;
        }
      }

      &__switch-icon {
        margin-left: 40px;
        align-self: center;
      }
    }
  }
}

@keyframes wave1 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave2 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.5);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave3 {
  0% {
    transform: scaleY(1.1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1.1);
  }
}

@keyframes wave4 {
  0% {
    transform: scaleY(1.05);
  }

  50% {
    transform: scaleY(0.6);
  }

  100% {
    transform: scaleY(1.05);
  }
}
</style>
