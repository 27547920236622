<template>
  <div class="c-add-to-playlist function-icon" @click="onClick">
    <tooltip :label="$t('myMusic.addToProject')">
      <div class="function-icon" >
        <div class="function-icon__img function-icon__img--add"></div>
        <span class="function-icon__label" v-if="label"> {{$t('myMusic.addToProject')}} </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
    <!-- ${projectName ? `-${projectName}` : ''} -->
      <model-base-layout :title="$t('myMusic.addToProject')" decoLine>
        <add :type="type" :data="data" @close="closeModal"/>
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, inject } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal } from 'naive-ui';
// import { useRouter } from 'vue-router';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import Add from '@/components/Global/Popup/Add.vue';
import Tooltip from '@/components/Global/Tooltip.vue';
import useLoginFirst from '@/hooks/useLoginFirst';
// import usePopInfo from '@/hooks/usePopInfo';

export default defineComponent({
  name: 'AddToPlaylist',
  components: {
    NModal,
    ModelBaseLayout,
    Add,
    Tooltip,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const modelStatus = ref(false);
    const { loginFirst } = useLoginFirst();

    const closeModal = () => {
      modelStatus.value = false;
    };

    const addToPlaylist = () => {
      modelStatus.value = true;
    };

    const onClick = () => {
      loginFirst(addToPlaylist);
    };

    const projectName = inject('projectName', '');

    return {
      modelStatus,
      addToPlaylist,
      onClick,
      projectName,
      closeModal,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
