const exploreType = {
  themes: '主题',
  moods: '气氛',
  genres: '曲风',
  playlists: '歌单',
  albums: '专辑',
  labels: '品牌',
};

export default {
  leaveMessageSuccess: '留言已送出',
  submitSuccess: '成功送出',
  test: '测试',
  aboutPoint: '关于 音韶',
  contactUs: '有疑问吗？请联络我们。',
  exploreType,
  fullMatch: '完全比对',
  keywordPlaceholder: '请输入关键字...',
  learnMore: '查看更多',
  menulist: {
    explore: '搜索',
    licensing: '授权服务',
    contact: '联络我们',
  },
  noData: '目前没有资料',
  pagination: {
    prev: '上一页',
    next: '下一页',
    to: '前往页面',
    of: '之',
  },
  rights: '音韶文化事业有限公司版权所有',
  searchPlaceholder: '搜寻关键字、曲风、情绪、乐器…',
  siteMap: {
    explore: {
      title: '搜索',
      sub: exploreType,
    },
    'my-profile': {
      title: '会员专区',
      sub: {
        'my-profile': '我的帐号',
        playlists: '专案选曲',
        'search-history': '搜寻历史',
        'play-history': '播放纪录',
        'download-history': '下载纪录',
        'preview-tool': '影音结合体会',
      },
    },
    licensing: {
      title: '法律声明',
      sub: {
        terms: '使用条款',
        privacy: '隐私权政策',
        cookies: 'COOKIES 政策',
      },
    },
    other: {
      title: '关于',
      sub: {
        licensing: '授权服务',
        aboutUs: '关于我们',
        contact: '联络我们',
      },
    },
  },

  // form
  save: '储存',
  email: '电子邮箱',
  pleaseEntryEmail: '请输入电子邮箱',
  pleaseEnterCorrectEmailFormat: '请输入正确的电子邮箱格式',
  name: '用戶名',
  pleaseEnter: '请输入用戶名',
  appellation: '性别',
  male: '先生',
  female: '小姐',
  mobile: '手机号',
  pleaseEnterMobile: '请输入手机号',
  tel: '电话',
  pleaseEnterPhone: '請輸入電話號碼',
  phoneFormatErrorMsg: '电话号码格式错误',
  city: '所在国家',
  pleaseSelectCountry: '请选择所在国家',
  cityCounty: '居住城市/地区',
  pleaseSelectCityCounty: '请选择居住城市/地区',
  businessType: '所屬行业',
  pleaseEnterBusinessType: '请选择所屬行业',
  position: '职位',
  pleasePosition: '请输入职位',
  companyName: '企业名称',
  pleaseEnterCompanyName: '请输入企业名称',
  companyCountry: '企业国家',
  pleaseEntercompanyCountry: '请输入企业国家',
  companyCity: '企业城市/地区',
  pleaseEnterCompanyCity: '请输入企业城市/地区',
  companyAddress: '企业地址',
  pleaseEnterCompanyAddress: '请输入企业地址',
  country: '国家',
  pwPlacehodler: '请输入英数字、符号组合的 8-20 个字元',
  emailBox: '电子邮箱',
  emailBoxPlaceholder: '请输入电子邮箱',
  nationality: '国籍',
  pleaseSelectNationality: '请选择国籍',
  password: '密码',
  passwordPlaceholder: '请输入密码',
  oldPw: '旧密码',
  newPw: '新密码',
  confirmNewPw: '确认新密码',
  confirmPw: '确认密码',
  submit: '提交',
  pwdRuleMsg: '密码不符合规则：英文、数字、符号{specialCharactor}',
  pleaseEnterName: '请输入用戶名',
  notAllowSameAsOldPwd: '请勿与旧密码相同',
  pwdNotConsist: '输入密码不一致',

  // 验证错误讯息
  errPhoneFormat: '电话号码格式错误',

  // other
  addSuccess: '加入成功',
  cancel: '取消',
  all: '全部',
  confirm: '确认',
  menu: {
    myProfile: 'MY ACCOUNT 我的帐号',
    explore: 'EXPLORE 搜索',
    licensing: 'LICENSING 授权服务',
    contact: 'CONTACT 联络我们',
    aboutUs: 'ABOUT US 关于我们',
    myMusic: 'MEMBERS ONLY 会员专区',
  },
  myMusic: '会员专区',
  unexpcetedError: '非预期错误',
  defaultErrorMsg: '当前无法使用',
  sortBy: {
    desc: '上架时间(由新至旧)',
    asc: '上架时间(由旧至新)',
    name: '名称(A-Z)',
    albumcode: '专辑编号(由多至少)',

  },
  albums: '专辑',
  pointCulture: '音韶文化',
  breadcrumb: {
    home: '首页',
    explore: '搜索',
  },
  edit: '编辑',
  back: '返回',
  startPlaying: '开始播放',
  switchDisplayMode: '播放器模式切换',
  downloadCompressing: '档案压缩下载中，请等待至完成再进行其他操作',
  dontShowAgain: '不要再显示',
  version: '版本',
  draggbleSort: '拖曳排序',
  copyTrackInfo: '复制曲目资讯',
  copyLyrics: '复制歌词',
  removeFilter: '清除条件',
};
