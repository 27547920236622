import request from '@/api';
import {
  login,
  whoami,
  register,
  getClientCategoryList,
  updateAccountPassword,
  updateAccount,
  forgetPassword,
  verifyEmail,
  updateAccountForgetPassword,
  resendVerifyEmail,
} from '@/api/gql/members.gql';

export const loginAPI = async (payload) => {
  const res = await request(login, payload);

  return res;
};

export const registerAPI = async (payload) => {
  const res = await request(register, payload);

  return res;
};

export const forgetPasswordAPI = async (payload) => {
  const res = await request(forgetPassword, payload);

  return res;
};

export const verifyEmailAPI = async (payload) => {
  const res = await request(verifyEmail, payload);

  return res;
};

export const resendVerifyEmailAPI = async (payload) => {
  const res = await request(resendVerifyEmail, payload);

  return res;
};

export const updateAccountForgetPasswordAPI = async (payload) => {
  const res = await request(updateAccountForgetPassword, payload);

  return res;
};

export const updateAccountAPI = async (payload) => {
  const res = await request(updateAccount, payload);

  return res;
};

export const updateAccountPasswordAPI = async (payload) => {
  const res = await request(updateAccountPassword, payload);

  return res;
};

export const whoamiAPI = async () => {
  const res = await request(whoami);

  return res;
};

export const getClientCategoryListAPI = async () => {
  const res = await request(getClientCategoryList);

  return res;
};
