<template>
  <div class="c-leave-self-checking-confirm">
    <p class="text">{{ msg }}</p>
  </div>
</template>
<script>
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'LoadingError',
  props: {
    msg: {
      type: String,
      default: '',
    },
  },
  emits: ['closeDialog', 'loadingError'],
  setup(props, { emit }) {
    const closeDialog = () => {
      emit('closeDialog', 'loadingError');
    };

    const handleLeave = (action) => {
      if (action) {
        emit('loadingError');
        return;
      }

      closeDialog();
    };

    return {
      handleLeave,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-leave-self-checking-confirm {
  @include flex(center, center, column);
}

.text {
  @include font-style($c-main, 14, 700);
}

.btn-wrap {
  margin-top: 30px;
}
</style>
