import { h } from 'vue';
import TitleWithFunction from '@/components/Global/MusicPlayer/Fragment/TitleWithFunction.vue';
// import Avatar from '@/components/Global/MusicPlayer/Fragment/Avatar.vue';
// import Track from '@/components/Global/MusicPlayer/Fragment/Track.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
import MusicPlayerRemove from '@/components/Global/MusicPlayer/Fragment/MusicPlayerRemove.vue';
import Id from '@/components/Global/MusicPlayer/Fragment/Id.vue';
// import ModifyDate from '@/components/Global/MusicPlayer/Fragment/ModifyDate.vue';

export const functionConfig = {
  functionIcons: {
    list: [
      {
        icon: 'delete',
      },
    ],
  },
  addPlaylistsBtn: {
    visible: true,
  },
  addAllToPlaylistBtn: {
    visible: true,
  },
  downloadBtn: {
    visible: true,
  },
};

export const listConfig = {
  columns: [
    {
      type: 'label',
      label: 'TITLE',
      i18nKey: 'myMusic.title',
      align: 'left',
      key: 'playlistsName',
      render: h(TitleWithFunction, {
        playType: 'queueTrack',
      }),
    },
    {
      type: 'label',
      label: 'LENGTH',
      i18nKey: 'myMusic.length',
      width: '100px',
      align: 'left',
      render: h(TimeLength, {
        color: 'white',
      }),
    },
    {
      type: 'label',
      label: 'ID',
      i18nKey: 'myMusic.id',
      width: '200px',
      align: 'left',
      key: 'id',
      render: h(Id, {
        color: 'white',
      }),
    },
    {
      type: 'checkbox',
      label: 'C',
      width: '60px',
      align: 'center',
      key: 'checkbox',
      for: 'musicPlayer',
    },
    {
      type: 'label',
      label: 'REMOVE',
      i18nKey: 'myMusic.remove',
      width: '100px',
      align: 'center',
      key: 'remove',
      render: MusicPlayerRemove,
    },
  ],
};
