<template>
  <div class="c-circle-style">
    <Tooltip label="播放器模式切換" :style="{ width: '100%', height: '100%' }">
      <div class="c-circle-style__content">
        <div class="music-wave">
          <div class="music-wave__bar" :class="{
              [`music-wave__bar__wave${idx}`]: isPlay,
            }" v-for="idx of 4" :key="idx"></div>

          <!-- <div class="music-wave__bar music-wave__bar__wave2"></div>
      <div class="music-wave__bar music-wave__bar__wave3"></div>
      <div class="music-wave__bar music-wave__bar__wave4"></div> -->
        </div>

        <!-- circle -->
        <div class="circle">
          <svg class="circle__svg" height="94" width="94">
            <circle class="circle__svg__static" cx="47" cy="47" r="42" />
          </svg>

          <svg class="circle__svg" height="94" width="94">
            <circle class="circle__svg__dynamic" :style="{ 'stroke-dashoffset': songCircleOffset }" cx="47" cy="47" r="42"
              transform="rotate(-90, 47, 47)" />
          </svg>
        </div>
      </div>
    </Tooltip>
  </div>
</template>
<script>
import {
  defineComponent, ref, computed,
  watch,
} from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'MuiscCircle',
  components: {
    Tooltip,
  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();
    const songCircleOffset = ref(0);

    const newMusicPlayerStore = useNewMusicPlayerStore();
    const isPlay = computed(() => {
      return newMusicPlayerStore.playControll.play;
    });

    // const isPlaying = computed(() => newMusicPlayerStore.musicProgressPersentage);
    const progressPersentage = computed(() => newMusicPlayerStore.musicProgressPersentage);

    // const progressPersentage = computed(() => {
    //   if (!musicPlayerStore.playControll.currentTime || !musicPlayerStore.info.duration) return 0;
    //   return musicPlayerStore.playControll.currentTime / musicPlayerStore.info.duration;
    // });

    const circumference = 2 * 3.14159 * 42;
    const songCircleAnimate = () => {
      const offset = (progressPersentage.value / 100) * circumference;
      songCircleOffset.value = circumference - offset;

      if (isPlay.value) {
        window.requestAnimationFrame(songCircleAnimate);
      }
    };

    watch(isPlay, (newVal) => {
      if (newVal) {
        window.requestAnimationFrame(songCircleAnimate);
      }
    });

    return {
      songCircleOffset,
      // isPlaying,
      isPlay,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-circle-style {
  display: none;
}

@media screen and (min-width: $tablet) {
  .c-circle-style {
    width: 100%;
    height: 100%;
    display: inline-block;
    @include bg-gradient();
    @include circle(100px);
    cursor: pointer;

    &__content {
      width: 100%;
      height: 100%;
      display: inline-block;

    }
  }

  .music-wave {
    @include position(center);
    @include flex(center);
    width: 40px;
    height: 40px;

    &__bar {
      width: 6px;
      height: 40px;
      background-color: $c-white;
      transform-origin: bottom;

      &__wave1 {
        animation: wave1 0.45s infinite linear;
      }

      &__wave2 {
        animation: wave2 0.7s infinite linear;
      }

      &__wave3 {
        animation: wave3 0.9s infinite linear;
      }

      &__wave4 {
        animation: wave4 0.58s infinite linear;
      }

      &+& {
        margin-left: 5px;
      }
    }
  }
}

@keyframes wave1 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave2 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.5);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave3 {
  0% {
    transform: scaleY(1.1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1.1);
  }
}

@keyframes wave4 {
  0% {
    transform: scaleY(1.05);
  }

  50% {
    transform: scaleY(0.6);
  }

  100% {
    transform: scaleY(1.05);
  }
}

.circle {
  width: 100%;
  height: 100%;
  position: relative;

  &__svg {
    @include position(center);

    &__static {
      stroke: $c-assist3;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
    }

    &__dynamic {
      stroke: $c-white;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
      stroke-dasharray: 264;
    }
  }
}
</style>
