<template>
  <div class="c-edit-playlist-email">
    <div class="email">
      <input class="email__input" v-model="email" :placeholder="$t('global.pleaseEntryEmail')" @input="checkEmailValidate"
        @keydown.stop />
    </div>
    <p class="error-message" v-show="!isValid">{{ $t('global.pleaseEnterCorrectEmailFormat') }}</p>

    <div class="btn-wrap">
      <!-- <a :href="`mailto:${email}?subject=分享歌單 標題&body=分享歌單 內容`" @click.prevent="submit">
      </a> -->
      <mi-button @click="submit">{{ $t('myMusic.share') }}</mi-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'SharePlaylistsContent',
  components: {
    MiButton,
  },
  props: {
    // playlistName: {
    //   type: String,
    //   default: '{歌單名字}',
    // },
    data: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const email = ref('');
    const isValid = ref(true);

    const checkEmailValidate = () => {
      if (email.value === '') return;
      const emailRegexp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
      isValid.value = emailRegexp.test(email.value);
    };

    const convertType = {
      PLAYLIST: 'playlists',
      ALBUM: 'albums',
    };

    const submit = () => {
      if (isValid.value) {
        const sharelinks = `${window.location.origin}/explore/${convertType[props.type]}-info/${props.data?.playlistUid || props.data?.albumUid || props.data?.album.albumUid}`;

        const bodyEle = document.querySelector('body');
        const aLinkEle = document.createElement('a');
        aLinkEle.setAttribute(
          'href',
          `mailto:${email.value}?subject=${t('myMusic.shareTrackEmailWording.t1')} ${encodeURIComponent(
            props.playlistName ||
            props.data.albumTitleDisplay ||
            props.data?.album?.albumTitleDisplay ||
            props.data.name,
          )}&body=${t('myMusic.shareTrackEmailWording.t2')} ${sharelinks}%0A%0A%0A${t(
            'myMusic.shareTrackEmailWording.t3',
          )}`,
        );
        aLinkEle.style.display = 'none';
        bodyEle.appendChild(aLinkEle);
        aLinkEle.click();
        bodyEle.removeChild(aLinkEle);

        emit('close');
      }
    };

    return {
      email,
      submit,
      isValid,
      checkEmailValidate,
    };
  },
});
</script>
<style lang="scss" scoped>
.email {
  margin: 36px 45px 0;
  @include padding(0 25px);
  @include flex();
  border: 1px solid #a0a0a0;
  border-radius: 25px;
  height: 50px;

  &__input {
    @include font-style($c-assist3, 14, normal, 0.8px, 22px);
    border: 0 transparent;
    outline: 0 transparent;
    background-color: transparent;
    width: 100%;

    &::placeholder {
      @include font-style($c-assist3, 14, normal, 0.8px, 22px);
    }
  }
}

.btn-wrap {
  margin-top: 50px;
  margin-bottom: 20px;
  @include flex(center);
}

.error-message {
  margin: 5px 45px 0;
  @include font-style($c-main, 14, normal, 0.8px, 22px);
}
</style>
