export default {
  title: 'Explore Music',
  exploreTypeDesc: {
    themes: 'Explore music by theme - Discover our curated themes for infinite inspirations.',
    moods: 'Explore music by moods - From super heroes to sensational, moods playlists will satisfy your emotional note.',
    genres: 'Explore music by music styles - Whether you\'re looking to lift up your spirits or relax your mind, you\'ll find music to suit every genre.',
    playlists: 'Explore music by playlists - Each playlist has been carefully crafted by us to fit for your mood.',
    albums: 'Explore music by albums - Contains tens of thousands of albums of different genres, from classic to popular albums.',
    labels: 'Explore music by brands - Find albums and tracks from the most recognized labels around the world.',
  },
  type: {
    themes: 'Themes',
    moods: 'Moods',
    genres: 'Music Styles',
    albums: 'Albums',
    playlists: 'Playlists',
    labels: 'Labels',
  },
  popularAlbum: 'Popular Albums',
  allAlbum: 'All Albums',
  seeMore: 'See All in Search',
  noData: 'No Data.',
  category: 'Category',
  order: 'Order',
  learnMore: 'Learn more',
  exploreMore: 'Exploring...',
  popularLabels: 'Popular Labels',
  popularPlaylists: 'Popular Playlists',
  allPlaylists: 'All Playlists',
  tags: 'Tags',
  publicDate: 'Public Date',
  track: 'Tracks',
  shareTrack: 'Sharing Track',
  relative: 'Related',
  hotTracks: 'Hot Tracks',
  relatedLabels: 'Related Labels',
  explore: 'Explore',
};
