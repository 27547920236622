<template>
  <div class="c-music-function">

    <div class="show-empty-expand" v-if="showExpand"></div>
    <div class="expand" v-if="showDetailExpand" @click="handleExpand('detail')">
      <img class="expand__img" :class="{ 'expand__img--active': expandDetailStatus }"
        src="@/assets/icon/icon_info_open.svg" />
    </div>
    <div class="expand" v-if="showMusicExpand" @click="handleExpand('music')">
      <img class="expand__img" :class="{ 'expand__img--active': expandMusicStatus }"
        src="@/assets/icon/icon_info_open.svg" />
    </div>
    <div class="expand" v-if="showInnerMusicExpand" @click="handleInnerMusicExpand">
      <img class="expand__img" :class="{ 'expand__img--active': expandInnerMusicStatus }"
        src="@/assets/icon/icon_info_open.svg" />
    </div>
    <div class="pull-from-shelf pull-from-shelf--ml45" v-if="offShelfConfig.used && !isOnShelfStatus">
      <img class="pull-from-shelf__icon" src="@/assets/mobile/icon_menu_info_red.svg" />
      <p class="pull-from-shelf__text">{{ type === 'ALBUM' ? $t('myMusic.trackIsOffShelf') :
        $t('myMusic.albumIsOffShelf') }}</p>
    </div>

    <ul class="function-list" :class="{ 'function-list--margin-left': ml }" v-else>
      <!-- (!showDetailExpand && !showMusicExpand && !showInnerMusicExpand) && showPlay -->
      <template v-for="icon of iconLists" :key="icon">
        <li class="function-list__item function-list__item--play" v-if="icon === 'play' && showPlayPause">
          <play-pause :type="playType" :data="data" />
        </li>

        <template v-if="memberLevel > 2">
          <!-- 加入我的曲目 -->
          <li class="function-list__item" v-if="icon === 'add'">
            <add-to-my-tracks :type="type" :data="data" />
          </li>

          <!-- 加入我的專輯 -->
          <!-- <li class="function-list__item" v-if="icon === 'add-to-album'">
            <add-to-my-albums :type="type" :data="data" />
          </li> -->

          <li class="function-list__item" v-if="icon === 'star' && !isFavourite">
            <add-to-favorite :type="type" :data="data" />
          </li>

          <!-- <li class="function-list__item" v-if="icon === 'star-to-album'">
            <add-to-my-albums-favorite :type="type" />
          </li> -->

          <li class="function-list__item" v-if="icon === 'download' && memberLevel > 3 && type === 'TRACK'">
            <download :type="type" :data="data" />
          </li>

          <li class="function-list__item" v-if="icon === 'download-all' && memberLevel > 3 && allowDownloadAll">
            <!-- -->
            <download-all :type="type" :downloadAllType="downloadAllType" :data="data" />
          </li>

          <li class="function-list__item" v-if="icon === 'note'">
            <note :type="type" :data="data" />
          </li>

          <li class="function-list__item" v-if="icon === 'share'">
            <share :type="type" :data="data" isPlayMusic />
          </li>

          <li class="function-list__item" v-if="icon === 'share-playlists'">
            <share-playlists :type="type" :data="data" />
          </li>
        </template>
      </template>
    </ul>
    <!-- </div> -->
    <!-- </template> -->
  </div>
</template>
<script>
import { defineComponent, computed, inject } from 'vue';
import PlayPause from '@/components/Global/MusicPlayer/Functions/PlayPause.vue';
import AddToFavorite from '@/components/Global/MusicPlayer/Functions/AddToFavorite.vue';
// import AddToMyTracksFavorite from '@/components/Global/MusicPlayer/Functions/AddToMyTracksFavorite.vue';
// import AddToMyAlbumsFavorite from '@/components/Global/MusicPlayer/Functions/AddToMyAlbumsFavorite.vue';
import AddToMyTracks from '@/components/Global/MusicPlayer/Functions/AddToMyTracks.vue';
// import AddToMyAlbums from '@/components/Global/MusicPlayer/Functions/AddToMyAlbums.vue';
import Note from '@/components/Global/MusicPlayer/Functions/Note.vue';
import Share from '@/components/Global/MusicPlayer/Functions/Share.vue';
import SharePlaylists from '@/components/Global/MusicPlayer/Functions/SharePlaylists.vue';
import useMemberLevel from '@/hooks/useMemberLevel';
import Download from '@/components/Global/MusicPlayer/Functions/Download.vue';
import DownloadAll from '@/components/Global/MusicPlayer/Functions/DownloadAll.vue';
import useIsOffShelf from '@/hooks/useIsOffShelf';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MusicFunction',
  components: {
    PlayPause,
    AddToMyTracks,
    // AddToMyAlbums,
    AddToFavorite,
    // AddToMyTracksFavorite,
    // AddToMyAlbumsFavorite,
    Note,
    Share,
    Download,
    DownloadAll,
    SharePlaylists,
  },
  props: {
    // 展開 Detail (第二層)
    showDetailExpand: {
      type: Boolean,
      default: false,
    },
    // 展開 Music (版本與剪輯、曲目資訊、歌詞、分軌 ...)
    showMusicExpand: {
      type: Boolean,
      default: false,
    },
    // 嵌套在 detail 內的 Music
    showInnerMusicExpand: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    showPlay: {
      type: Boolean,
      default: true,
    },
    functionList: {
      type: Array,
      default: () => ['add', 'star', 'download', 'note', 'share'],
    },
    ml: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'TRACK',
      validator(value) {
        return ['TRACK', 'PLAYLIST', 'ALBUM'].includes(value);
      },
    },
    downloadAllType: {
      type: String,
      default: '',
    },
    playType: {
      type: String,
      default: 'playlist',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    offShelfConfig: {
      type: Object,
      default: () => ({
        used: false, // 是否要顯示下架資訊
        type: 'TRACK',
      }),
    },
  },
  setup(props) {
    const { memberLevel } = useMemberLevel(props);
    const { isOnShelfStatus } = useIsOffShelf(props);
    const siteStore = useSiteStore();

    const allowDownloadAll = computed(() => {
      return siteStore.memberInfo.downloadMulti;
    });

    const showPlayPause = computed(() => {
      if (props.type === 'TRACK') {
        return true;
      }

      if (memberLevel.value > 1) {
        return true;
      }

      return false;
    });

    const iconLists = computed(() => {
      if (props.showPlay) {
        return ['play', ...props.functionList];
      }

      return props.functionList;
    });

    const expandDetailStatus = computed(() => {
      try {
        return inject('expandStatus')().detail;
      } catch {
        return false;
      }
    });
    const expandMusicStatus = computed(() => {
      try {
        return inject('expandStatus')().music;
      } catch {
        return false;
      }
    });
    const handleExpand = inject('handleExpand', () => { });

    const expandInnerMusicStatus = computed(() => {
      try {
        return inject('expandInnerMusicStatus', () => { })();
      } catch (e) {
        return false;
      }
    });
    const handleInnerMusicExpand = inject('handleInnerMusicExpand', () => { });
    const isFavourite = inject('isFavourite', false);

    return {
      // modelStatus,
      // handleClick,
      iconLists,
      handleExpand,
      expandDetailStatus,
      expandMusicStatus,
      expandInnerMusicStatus,
      handleInnerMusicExpand,
      memberLevel,
      showPlayPause,
      isFavourite,
      isOnShelfStatus,
      allowDownloadAll,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/pullFromShelf.scss';

.c-music-function {
  color: $c-white;
  position: relative;
  @include flex();

  // &__content {
  //   position: relative;
  //   @include flex();
  // }
}

.function-list {
  width: 100%;
  max-width: 300px;
  @include flex();

  &--margin-left {
    margin-left: 45px;
  }

  &__item {
    &+& {
      margin-left: 12px;
    }

    &--play {
      margin-right: 24px;
    }
  }
}

.expand {
  vertical-align: bottom;
  cursor: pointer;
  transition: 0.3s;
  width: 45px;
  height: 45px;
  @include flex();

  &__img {
    transform: rotate(0deg);
    transition: 0.3s;

    &--active {
      transform: rotate(-180deg);
    }
  }
}

.show-empty-expand {
  width: 45px;
}
</style>
