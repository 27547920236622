export default {
  loginSuccess: '登入成功',
  validateSuccess: '電子信箱驗證成功！',
  resetPwdSuccess: '重設密碼成功！',
  registerSuccess: '註冊成功',
  removeConfirm: '確定刪除 ?',
  logoutConfirm: '確定登出 ?',
  removeSuccess: '刪除成功',
  addSuccess: '加入成功',
  createSuccess: '新增成功',
  addNoteSuccess: '新增筆記成功',
  updateNoteSuccess: '更新筆記成功',
  saveSuccess: '儲存成功',
  removeNoteSuccess: '刪除筆記成功',
  copySuccess: '複製成功',
  copyFail: '複製失敗',
};
