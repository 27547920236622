import request from '@/api';
import { getTrack, playedTrack, getTrackDetail } from '@/api/gql/getTrack.gql';

export const getTrackAPI = async (payload) => {
  const res = await request(getTrack, payload);

  return res;
};

export const playedTrackAPI = async (payload) => {
  const res = await request(playedTrack, payload);

  return res;
};

export const getTrackDetailAPI = async (payload) => {
  const res = await request(getTrackDetail, payload);

  return res;
};

export const a = 0;
