import i18n from '@/i18n';
import allCode from '@/i18n/zh-tw/errCode';

const codeKeys = Object.keys(allCode);

const genMessage = (err, errInfo) => {
  let msg = '';
  if (codeKeys.includes(errInfo.messageCode)) {
    msg = i18n.global.t(`errCode.${[errInfo.messageCode]}`);
  } else {
    msg = errInfo?.message || err?.message || '';
  }

  console.log('msg', msg);

  if (msg) {
    window.$message.error(msg);
  }
};

export default genMessage;
