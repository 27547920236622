import request from '@/api';
import {
  getAccountNote,
  createAccountNote,
  updateAccountNote,
  getAccountNoteList,
  removeAccountNote,
  getAccountNoteAll,
  getAccountNoteContentAll,

} from '@/api/gql/myMusicNotes.gql';

export const createAccountNoteAPI = async (payload) => {
  const res = await request(createAccountNote, payload);

  return res;
};

export const getAccountNoteListAPI = async (payload) => {
  const res = await request(getAccountNoteList, payload);

  return res;
};

export const getAccountNoteAPI = async (payload) => {
  const res = await request(getAccountNote, payload);

  return res;
};

export const getAccountNoteContentAllAPI = async (payload) => {
  const res = await request(getAccountNoteContentAll, payload);

  return res;
};

export const updateAccountNoteAPI = async (payload) => {
  const res = await request(updateAccountNote, payload);

  return res;
};

export const removeAccountNoteAPI = async (payload) => {
  const res = await request(removeAccountNote, payload);

  return res;
};

export const getAccountNoteAllAPI = async (payload) => {
  const res = await request(getAccountNoteAll, payload);

  return res;
};
