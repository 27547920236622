import request from '@/api';
import { getAnnouncement } from '@/api/gql/getAnnouncement.gql';

export const getAnnouncementAPI = async () => {
  const res = await request(getAnnouncement);

  return res;
};

export const a = 0;
