export default {
  albums: '专辑',
  tracks: '曲目',
  playlists: '歌单',
  empty: '',
  id: 'ID',
  remove: '删除',
  playlist: '专案选曲',
  searchHistory: '搜寻历史',
  playedHistory: '播放纪录',
  downloadHistory: '下载纪录',
  myNote: '我的笔记',
  previewTool: '影音结合体会',
  searchAllProject: '搜寻所有专案',
  myTracks: '我的曲目',
  myAlbums: '我的专辑',
  favouriteTracks: '曲目收藏',
  favouriteAlbums: '专辑收藏',
  version: '版本类型',
  count: '数量',
  divide: '分轨',
  modifyDate: '修改日期',
  trackName: '曲目名称',
  title: '名称',
  length: '长度',
  viewActivityRecord: '查看活动纪录',
  keyword: '关键字',
  filterTag: '过滤标签',
  searchDate: '搜寻日期',
  playedDate: '播放日期',
  preserve30Days: '仅保留30日之纪录',
  versionAndEdit: '版本长度 / 类型',
  trackInfo: '曲目资讯',
  toAlbumPage: '前往专辑页面',
  toPlaylistPage: '前往歌单页面',
  addToQueue: '新增至播放顺序',
  downloadDate: '免费下载期限',
  noteCount: '笔记数量',
  updateDate: '更新日期',
  selectedPreviewTracks: '请选择专案曲目搭配上传画面',
  clickToPlayPosition: '点击进度条跳至该播放位置',
  searchTrackName: '搜寻专案曲目名称',
  syncPreview: '同步预览',
  mediaFile: '影片档案',
  wave: '波形',
  desc: '描述',
  addToPlaylist: '加入专案',
  allPlaylist: '专案选曲',
  previeToolDesc1: '1. 将曲目新增至专案选曲-我的曲目',
  previeToolDesc2: '2. 上传您的影片',
  previeToolDesc3: '3. 即时预览影片搭配曲目后的效果',
  startUse: '开始使用',
  seeAll: '看全部',
  playlistName: '歌单名称',
  projectName: '专案名称',
  albumName: '专辑名称',
  lyric: '歌词',
  move: '搬移歌单',
  copy: '复制歌单',
  delete: '删除',
  invite: '分享歌单',
  downloadPlaylist: '下载歌单',
  searchTrackNamePlaceholder: '搜寻曲目名称',
  notice: '仅保留近30天之纪录',
  uploadVideo: '上传影片',
  pleaseUploadVideo: '请上传影片',
  pleaseSelectTrack: '请选择曲目',
  addToProject: '加入专案',
  moveToPlaylists: '搬移至歌单',
  seeTrackNote: '查看曲目笔记',
  activityRecords: '活动纪录',
  editName: '编辑名称',
  addToFavourite: '加入收藏',
  albumInfo: '专辑资讯',
  download: '下载',
  addNote: '新增笔记',
  addEditNote: '新增/查看笔记',
  removeFromFavourite: '从收藏删除',
  removeCurrTrack: '删除此曲目',
  removeCurrProject: '删除此专案',
  removePlaylist: '删除歌单',
  shareTrack: '分享曲目',
  share: '分享',
  sharePlaylist: '分享歌单',
  shareAlbum: '分享专辑',
  albumIsOffShelf: '该专辑已下架',
  trackIsOffShelf: '该曲目已下架',
  playQueue: '播放顺序', // PLAY QUEUE
  versionEdits: '版本类型', // VERSION & EDITS
  add: '新增',
  addNewPlaylist: '新专案名称',
  noteContent: '笔记内容',
  editNote: '编辑笔记',
  searchNote: '搜寻笔记',
  pleaseEnterCurrectTimeFormat: '请输入正确的时间格式',
  copyLink: '复制连结',
  shareTrackEmailWording: {
    t1: '邀请您收听',
    t2: '嘿! 我发现了超棒的音乐',
    t3: '快来听听看吧',
  },
  createProject: '新增专案',
  waves: '波形',
  tags: '标签',
  description: '描述',
  detailDescription: '描述',
  copyDataLabel: {
    trackTitle: '曲目名称/Track Title',
    composer: '作曲家/Composer',
    publisher: '发行公司/Publishers',
    albumTitle: '专辑/Album Title',
    albumCode: '专辑编号/Album Code',
    trackNumber: '曲号/Track ID',
    label: '品牌/Labels',
    labelCode: '系列编号/Label Code',
  },
  with: '包含',
  without: '排除',
  submitSave: '储存',
  fromWhere: '指定开始处',
  play: '播放',
  rename: '重命名',
  ignoreAlbum: '已下架专辑将被忽略',
  ignoreTrack: '已下架曲目将被忽略',
  removeNoteSucceed: '删除笔记成功',
};
