<template>
  <div
    class="c-mobile-function"
    :class="{
      'c-mobile-function--active': showMobileFunction,
    }"
    @click.stop="close"
  >
    <div
      class="c-mobile-album-function"
      :class="{
        'c-mobile-album-function--active': showMobileFunction,
      }"
    >
      <div class="c-mobile-album-function__content">
        <div class="header">
          <div class="header__album-info">

            <cover-name-code-normal :data="data" />
          </div>
          <div class="header__close">
            <img class="header__close__img" src="@/assets/mobile/icon_menu_close.svg" @click="close" />
          </div>
        </div>

        <ul class="function-list">
          <!-- 加入專案 -->
          <li class="function-list__item" v-if="functionList.includes('add') && memberLevel > 2">
            <add-to-my-tracks label :data="data" :type="type"/>
          </li>
          <!-- 加入收藏 -->
          <li class="function-list__item" v-if="functionList.includes('star') && !isFavourite">
            <add-to-favorite label :type="type" :data="data" />
          </li>

          <!-- 分享曲目 -->
          <li class="function-list__item" v-if="functionList.includes('share') && memberLevel > 2">
            <share label :data="data" :type="type"/>
          </li>
          <!-- 分享專輯 -->
          <li class="function-list__item" v-if="functionList.includes('share-playlists') && memberLevel > 2">
            <share-playlists label :data="data" :type="type"/>
          </li>
          <!-- 前往專輯頁面 -->
          <li class="function-list__item" v-if="functionList.includes('to-album-page') && memberLevel > 2">
            <to-album-page label :data="data" :type="type"/>
          </li>
          <!-- 前往歌單頁面 -->
          <li class="function-list__item" v-if="functionList.includes('to-playlist-page') && memberLevel > 2">
            <to-playlist-page label :data="data" :type="type"/>
          </li>
          <!-- 新增至播放佇列 -->
          <!-- <li class="function-list__item" v-if="functionList.includes('add-in-queue') && memberLevel > 2">
            <add-in-queue label :data="data" :type="type"/>
          </li> -->
          <!-- 新增至播放佇列 -->
          <li class="function-list__item" v-if="functionList.includes('remove') && memberLevel > 2">
            <remove-playlist-track label :data="data" :type="type"/>
          </li>
          <!-- 新增筆記 -->
          <li class="function-list__item" v-if="functionList.includes('note') && memberLevel > 2">
            <note label :data="currPlaylistTrackData" :type="type" extraType="mobile-note-add-note"/>
          </li>
          <!-- 下載 -->
          <li class="function-list__item" v-if="functionList.includes('download') && memberLevel > 3 && type === 'TRACK'">
            <download label :data="currPlaylistTrackData" :type="type"/>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from 'vue';
import CoverNameCodeNormal from '@/components/Global/MusicPlayer/Fragment/CoverNameCodeNormal.vue';
import AddToMyTracks from '@/components/Global/MusicPlayer/Functions/AddToMyTracks.vue';
import Note from '@/components/Global/MusicPlayer/Functions/Note.vue';
import Share from '@/components/Global/MusicPlayer/Functions/Share.vue';
import ToAlbumPage from '@/components/Global/MusicPlayer/Functions/ToAlbumPage.vue';
import ToPlaylistPage from '@/components/Global/MusicPlayer/Functions/ToPlaylistPage.vue';
import { useSiteStore } from '@/store/site';
import useMemberLevel from '@/hooks/useMemberLevel';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import AddToFavorite from '@/components/Global/MusicPlayer/Functions/AddToFavorite.vue';
// import AddInQueue from '@/components/Global/MusicPlayer/Functions/AddInQueue.vue';
import Download from '@/components/Global/MusicPlayer/Functions/Download.vue';
import SharePlaylists from '@/components/Global/MusicPlayer/Functions/SharePlaylists.vue';
import RemovePlaylistTrack from '@/components/Global/MusicPlayer/Functions/RemovePlaylistTrack.vue';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'MobileFunction',
  components: {
    CoverNameCodeNormal,
    AddToMyTracks,
    AddToFavorite,
    RemovePlaylistTrack,
    Note,
    Share,
    Download,
    SharePlaylists,
    ToAlbumPage,
    // AddInQueue,
    ToPlaylistPage,
  },
  props: {
    showFunction: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'TRACK',
    },
    functionList: {
      type: Array,
      default: () => ['add', 'star', 'note', 'share', 'to-album-page'],
    },
  },
  emits: ['update:showFunction'],
  // props, { emit }
  setup() {
    const isFavourite = ref(false);
    const siteStore = useSiteStore();
    // const musicPlayerStore = useMusicPlayerStore();
    const { memberLevel } = useMemberLevel();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    const data = computed(() => ({
      // 為何 targetTitle 和 trackTitleDisplay 值一樣呢？我也不知道
      cover: newMusicPlayerStore.musicInfo.cover,
      targetTitle: newMusicPlayerStore.musicInfo.trackName,
      note: newMusicPlayerStore.musicInfo.creator,
      order: newMusicPlayerStore.musicInfo.trackNumber,
      albumUid: newMusicPlayerStore.musicInfo.albumUid,
      trackUid: newMusicPlayerStore.musicInfo.trackUid,
      trackTitleDisplay: newMusicPlayerStore.musicInfo.trackName,
    }));

    const close = () => {
      // emit('update:showFunction', false);
      siteStore.toggleShowMobileFunction(false);
    };
    const currPlaylistTrackData = computed(() => siteStore.mobilePlaylistTrackFunctionData);

    return {
      data,
      close,
      showMobileFunction: computed(() => siteStore.showMobileFunction),
      memberLevel,
      isFavourite,
      currPlaylistTrackData,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-mobile-function {
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 0.2);
  position: fixed;
  top: 100%;
  left: 0;
  z-index: $zi-mobile-function;
  transition: 0.3s;
  animation: fadeOut 0.4s forwards;

  &--active {
    display: inline-block;
    animation: fadeIn 0.3s forwards;
  }
}

.c-mobile-album-function {
  width: 100%;
  height: auto;
  background-color: rgba($c-white, 0.8);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  transition: 0.3s;
  @include position(tl, 100%, 0);
  animation: contentSlideOut 0.3s  forwards;

  &--active {
    animation: contentSlideIn 0.3s forwards;
    background-color: rgba($c-white, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: 0%;
  }
  to {
    opacity: 1;
    top: 0%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    top: 0%;
  }

  99% {
    top: 0%;
  }

  100% {
    opacity: 0;
    top: 100%;
  }
}

@keyframes contentSlideIn {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes contentSlideOut {
  from {
    transform: translateY(-100%);
    }
  to {
    transform: translateY(0%);
  }
}

.header {
  @include flex(space-between);
  border-bottom: 1px solid $c-assist7;
  @include padding(13px 20px);

  &__album-info {
  }

  &__close {
    width: 18px;

    &__img {
      width: 100%;
      cursor: pointer;
    }
  }
}

.function-list {
  @include padding(20px);

  &__item {
    & + & {
      margin-top: 20px;
    }
  }
}
</style>
