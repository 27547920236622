export default {
  title: '搜索音乐',
  exploreTypeDesc: {
    themes: '依主题搜索音乐－让我们精心策划的主题，为您的想像带来无限灵感。',
    moods: '依气氛搜索音乐－无论是史诗级的英雄电影配乐、诱人的广告活动或是俏皮的儿童趣味，这里的气氛分类都能满足您所有的情感音符。',
    genres: '依曲风搜索音乐－无论是蓝调爵士、放克、电音、嘻哈等流行元素，您可以在多种曲风中找到符合的音乐。',
    playlists: '依歌单搜索音乐－每一个精心设计的歌单，为您打造最符合心境的旋律清单。',
    albums: '依专辑搜索音乐－收录了数万张不同类型的音乐专辑，从经典到流行，应有尽有。',
    labels: '依品牌搜索音乐－从全世界品牌中找到适合您的专辑与曲目。',
  },
  type: {
    themes: '主题',
    moods: '气氛',
    genres: '曲风',
    albums: '专辑',
    playlists: '歌单',
    labels: '品牌',
  },
  popularAlbum: '推薦专辑',
  allAlbum: '所有专辑',
  seeMore: '查看更多',
  noData: '目前没有资料',
  category: '分类',
  order: '排序',
  learnMore: '查看更多',
  exploreMore: '搜索...',
  popularLabels: '推荐品牌',
  popularPlaylists: '推荐歌单',
  allPlaylists: '所有歌单',
  tags: '标签',
  publicDate: '发行日期',
  track: '曲目',
  shareTrack: '分享曲目',
  relative: '相关',
  hotTracks: '热门曲目',
  relatedLabels: '相关品牌',
  explore: '搜索',
};
