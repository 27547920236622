<template>
  <div class="c-share-playlists function-icon">
    <tooltip :label="type === 'TRACK' ? $t('myMusic.removeCurrTrack'):$t('myMusic.removeCurrProject')">
      <div class="function-icon" @click="handleApi">
        <div class="function-icon__img function-icon__img--remove"></div>
        <span class="function-icon__label" v-if="label"> {{type === 'TRACK' ? $t('myMusic.removeCurrTrack'):$t('myMusic.removeCurrProject')}} </span>
      </div>
    </tooltip>

    <!-- <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <model-base-layout title="刪除此專案" decoLine>
        <activityShareRecord :data="data" :type="type"/>
      </model-base-layout>
    </n-modal> -->
  </div>
</template>
<script>
import { defineComponent, watch, inject } from 'vue';
// import { useRoute } from 'vue-router';
import { useMessage } from 'naive-ui';
import { useI18n } from 'vue-i18n';
import useRequest from '@/hooks/useRequest';
import { removeAccountPlaylistAPI } from '@/api/myMusicPlaylists';
import usePopInfo from '@/hooks/usePopInfo';
// import { firstLetterUpperCase } from '@/utils/firstLetterUpperCase';

export default defineComponent({
  name: 'RemovePlaylist',
  components: {

  },
  props: {
    type: {
      type: String,
      default: '',
    },
    label: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },

  },
  setup(props) {
    const { t } = useI18n();
    const { successPopInfo } = usePopInfo();
    const message = useMessage();
    const reloadAccountPlaylist = inject('reloadAccountPlaylist');

    const {
      request: removeAccountPlaylistRequest,
      response: removeAccountPlaylistResponse,
    } = useRequest(removeAccountPlaylistAPI);

    const removeAccountPlaylist = async () => {
      await removeAccountPlaylistRequest({
        in: props.data.accountPlaylistUid,
      });

      await props.data.reloadAccountPlaylist();
    };

    const handleApi = () => {
      successPopInfo({
        desc: '確定刪除？',
        callback: removeAccountPlaylist,
        showClose: true,
      });
    };

    watch(removeAccountPlaylistResponse, () => {
      const [err, res] = removeAccountPlaylistResponse.value;

      if (err) {
        // message.error(err.message);
        return;
      }

      if (res) {
        message.success(t('msg.removeSuccess'));
      }
    });

    return {
      handleApi,
      reloadAccountPlaylist,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
