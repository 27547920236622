<template>
  <div class="c-add-note">
    <p class="c-add-note__remaining">{{ formValue.noteValue.length }}/200</p>
    <div class="c-add-note__note">
      <n-form :label-width="80" :model="formValue" :rules="rules" ref="formRef" label-placement="top">
        <n-grid :cols="12" :x-gap="20">
          <n-form-item-gi :span="12" :label="$t('myMusic.noteContent')" path="noteValue">
            <n-input type="textarea" v-model:value="formValue.noteValue" :rows="10"
              :placeholder="`${$t('myMusic.addNote')} ...`" @keydown.stop />
          </n-form-item-gi>
        </n-grid>
      </n-form>

      <div class="note-list-wrap">

        <ul class="note-list">
          <li class="note-list__item" v-for="d of noteList" :key="d.accountNoteUid">
            <div class="item-row">
              <div class="item-row__basic">
                <p class="item-row__basic__title">{{ d.content }}</p>
                <p class="item-row__basic__time">{{ d.time }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>

    <div class="submit">
      <mi-button @click="submit" :isLoading="isLoading"> {{ $t('myMusic.submitSave') }} </mi-button>
    </div>

    <!-- <div class="cancel">
      <button class="cancel__btn">Cancel</button>
    </div> -->
  </div>
</template>
<script>
import { defineComponent, ref, watch, inject } from 'vue';
import { NInput, useMessage, NForm, NFormItemGi, NGrid } from 'naive-ui';
// usePopInfo as naiveMessage
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import MiButton from '@/components/Global/MiButton.vue';
import {
  createAccountNoteAPI,
  getAccountNoteListAPI,
} from '@/api/myMusicNotes';
import useRequest from '@/hooks/useRequest';
import errMsg from '@/utils/errorMessage';
import { useCacheStore } from '@/store/cache';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'AddNote',
  components: {
    NInput,
    MiButton,
    NForm,
    NFormItemGi,
    NGrid,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    extraType: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { t } = useI18n();
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();
    // const { errorPopInfo } = usePopInfo();
    const route = useRoute();
    const message = useMessage();
    const formRef = ref(null);
    const formValue = ref({
      noteValue: '',
    });
    const noteList = ref([]);
    const { request: getAccountNoteListReq, response: getAccountNoteListRes } = useRequest(getAccountNoteListAPI);

    const { request, response, isLoading } = useRequest(createAccountNoteAPI);
    const cacheStore = useCacheStore();

    const extractTrackUid = () => {
      let trackUid = props.data?.trackUid ||
        props.data?.originUid ||
        props.data?.track?.trackUid ||
        newMusicPlayerStore.musicInfo.trackUid || '';

      if (props.data?.tracks && Array.isArray(props.data?.tracks) && trackUid === '') {
        if (props.data.tracks[0].trackUid) {
          trackUid = props.data.tracks[0].trackUid || '';
        }
      }

      return trackUid;
    };

    getAccountNoteListReq({
      in: {
        trackUid: extractTrackUid(),
        keyword: '',
        paginationInfo: {
          currentPage: 1,
          pageSize: 1,
        },
      },
    });

    const submit = async () => {
      const errorExist = await formRef.value.validate();
      if (!errorExist) {
        request({
          in: {
            content: formValue.value.noteValue,
            originUid: extractTrackUid(),
          },
        });
      }
    };

    const reloadNoteContent = inject('reloadNoteContent');

    watch(response, () => {
      const [err, res] = response.value;

      if (err) {
        message.error(err.toString());
        return;
      }

      if (res) {
        if (route.name === 'my-music-my-note') {
          // mobile
          if (props.extraType === 'mobile-note-add-note') {
            // 電腦使用
            props.data.reloadGetAccountNoteList();
          } else if (typeof reloadNoteContent === 'function') {
            // 電腦使用
            reloadNoteContent();
          }
        }

        message.success(t('msg.addNoteSuccess'));
        // 更新有 Note 的 track
        cacheStore.trackWithNotesReq();
        emit('close', false);
      }
    });

    watch(getAccountNoteListRes, () => {
      const [err, res] = getAccountNoteListRes.value;

      if (err) {
        // message.error(err.message);
        return;
      }

      if (res) {
        const { data } = res.getAccountNoteList;
        if (data.length) {
          noteList.value = data[0].accountNoteContents.map((item) => {
            item.time = dayjs(item.updatedAt).format('D MMM YYYY HH:mm');

            return item;
          });
        } else {
          noteList.value = [];
        }
      }
    });

    return {
      formValue,
      formRef,
      submit,
      rules: {
        noteValue: [
          {
            required: true,
            message: errMsg.required('內容'),
            trigger: ['input'],
          },
          {
            required: true,
            message: errMsg.contactLimit200,
            trigger: ['input'],
            validator: () => formValue.value.noteValue.length <= 200,
          },
        ],
      },
      isLoading,
      noteList,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-add-note {
  @include padding(0 24px);

  &__remaining {
    @include flex(flex-end);
    @include font-style($c-assist3, 12, 600, 0.6px, 18px);
  }

  &__note {
    margin-top: 6px;
  }
}

.submit {
  margin-top: 26px;
  @include flex(center);
}

.cancel {
  margin-top: 16px;
  @include flex(center);

  &__btn {
    cursor: pointer;
    @include font-style($c-assist3, 14px, 600, 0.7px, 18px);
    border: 0px transparent;
    outline: 0px transparent;
    background-color: transparent;
  }
}

.note-list {
  background-color: $c-white3;
  height: 25vh;
  overflow: auto;

  &__item {
    @include padding(23px 20px);
    border-bottom: 1px solid $c-assist7;
  }
}

.item-row {
  @include flex(space-between);

  &__basic {
    &__title {
      @include font-style($c-black, 14, normal, 0.8px, 26px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 18px);
    }
  }

  &__action {
    @include flex();

    &__edit {
      @include padding(0 10px);
      @include flex(center);
      display: inline-block;
      background-color: $c-assist15;
      height: 26px;
      border-radius: 13px;
      cursor: pointer;

      &__text {
        @include font-style($c-white, 14, normal, 0.7px, 26px);
      }
    }

    &__delete {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: $sm-pc) {

  .note-list {
    &__item {
      @include padding(23px 0 23px 20px);
    }
  }

  .item-row {
    @include padding(0 80px 0 0);
  }
}
</style>

<style lang="scss"></style>
