<template>
  <div class="ly-app-content">
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useMessage } from 'naive-ui';

export default defineComponent({
  name: 'AppContent',
  setup() {
    window.$message = useMessage();
  },
});
</script>
<style lang="scss" scoped>
.ly-app-content {
  width: 100%;
  height: 100%;
}
</style>
