<template>
  <div class="c-queue">
    <ul class="row" v-for="d of queue" :key="d.id">
      <li class="row__item" @click="handleQueue">
        <ul class="queue-info-list">
          <li class="queue-info-list__item queue-info-list__item--version-info">
            <div class="queue-info">
              <cover-name-code-normal :data="d" />
            </div>
          </li>
          <li class="queue-info-list__item queue-info-list__item--length">
            <div class="queue-info">
              <time-length :data="d" type="queueTrack" />
            </div>
          </li>
          <li class="queue-info-list__item queue-info-list__item--more">
            <div class="queue-info">
              <play-pause :data="d" type="mobileQueueTrack" />
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import CoverNameCodeNormal from '@/components/Global/MusicPlayer/Fragment/CoverNameCodeNormal.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
// import More from '@/components/Global/MusicPlayer/Fragment/More.vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import PlayPause from '@/components/Global/MusicPlayer/Functions/PlayPause.vue';

export default defineComponent({
  name: 'Queue',
  components: {
    CoverNameCodeNormal,
    TimeLength,
    PlayPause,
    // More,
  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    const queue = computed(() => newMusicPlayerStore.queueList.map((item) => ({
      duration: item?.track?.duration,
      albumCode: item?.album?.creator,
      trackTitleDisplay: item?.track?.trackName,
      artworkInfo: item?.track?.cover,
      albumTitleDisplay: item?.track?.albumTitleDisplay,
      trackUid: item?.track?.trackUid,
      trackWave: item?.track?.trackWave,
      note: item?.album.albumCode,
      cover: item?.track?.cover,
      targetTitle: item?.track?.trackName,
      albumUid: item?.album?.albumUid,
      order: item?.track?.trackNumber,
    })));

    return {
      queue,
    };
  },
});
</script>
<style lang="scss" scoped>
.row {
  &__item {}

  &+& {
    margin-top: 20px;
  }
}

.queue-info-list {
  @include flex();

  &__item {
    width: 100%;

    &--version-info {
      flex: 1;
    }

    &--length {
      @include flex(center);
      flex: none;
      width: 45px;
    }

    &--more {
      @include flex(center);
      flex: none;
      width: 45px;
    }
  }
}
</style>
