<template>
  <div class="c-my-music-list">
    <div class="c-my-music-list__header">
      <list-header :columns="config.columns" :handleCheckStatus="handleCheckStatus" />
    </div>
    <div class="c-my-music-list__content">
      <list-content :columns="config.columns" :contentData="contentData" :remainRowHeight="remainRowHeight"/>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, provide } from 'vue';
import ListHeader from '@/components/Global/MusicPlayer/VersionQueueList/ListHeader.vue';
import ListContent from '@/components/Global/MusicPlayer/VersionQueueList/ListContent.vue';

export default defineComponent({
  name: 'VersionQueueList',
  components: {
    ListHeader,
    ListContent,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    contentData: {
      type: Array,
      default: () => [],
    },
    remainRowHeight: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isCheckAll = ref(false);
    const resetKey = ref(1);

    const handleCheckStatus = (status) => {
      isCheckAll.value = status;
    };

    const handleResetIsChecked = () => {
      resetKey.value += 1;
    };

    provide('isCheckAll', isCheckAll);
    provide('resetKey', resetKey);

    return {
      handleCheckStatus,
      handleResetIsChecked,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list {
  width: 100%;
  height: 100%;
  @include flex(flex-start, flex-start, column);

  &__header {
    width: 100%;
    flex: none;
    height: auto;
  }

  &__content {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>
