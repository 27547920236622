export const toMinSec = (seconds = 0) => {
  const min = Math.floor(seconds / 60);
  const sec = Math.floor(seconds % 60);
  return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
};

export const toSec = (time) => {
  const timeArr = time.split(':');
  return +timeArr[0] * 60 + +timeArr[1];
};
