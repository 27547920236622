<template>
  <div class="c-tooltip" :style="cusStyle">
    <slot></slot>

    <p class="c-tooltip__desc" :class="[
        {
          'c-tooltip__desc--white-space-wrap': whiteSpaceWrap,
        },
        `c-tooltip__desc--${placement}`,
        `c-tooltip__desc--cus-type--${cusPlacementType}`,
        `c-tooltip__desc--font-size--${fontSize}`
      ]" :style="{
      maxWidth
    }">{{ label }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tooltip',
  props: {
    label: {
      type: String,
      default: 'tooltip',
    },
    placement: {
      type: String,
      default: 'center',
    },
    cusPlacementType: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: 'auto',
    },
    whiteSpaceWrap: {
      type: Boolean,
      default: false,
    },
    whiteSpaceNoWrap: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '14',
    },
    cusStyle: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
<style lang="scss" scoped>
.c-tooltip {
  position: relative;

  &__desc {
    background-color: rgba($c-black, 0.8);
    border-radius: 4px;
    @include padding(4px);
    display: none;
    white-space: nowrap;
    color: $c-white;
    @include font-style($c-white, 14, 400, normal, 20px);
    z-index: 10;
    width: auto;

    &--white-space-wrap {
      white-space: normal;
    }

    &--left {
      @include position(tl, 0%, 0);
      transform: translateY(-100%);
    }

    &--center {
      @include position(tl, 0%, 50%);
      transform: translate(-50%, -100%);
    }

    &--right {
      @include position(tr, -100%, 0);
      transform: translateY(-100%);
    }

    &--font-size {
      &--16 {
        @include font-size(16)
      }

      &--14 {
        @include font-size(14)
      }

      &--12 {
        @include font-size(12)
      }
    }

    &--cus-type {
      @include position(tl, -100%, 50%);
      transform: translate(-50%, -100%);

      &--order-cover-name {
        @include position(tl, -25px, 50%);
      }

      &--order-name {
        @include position(tl, -10px, 50%);
      }

      &--queue-version {
        @include position(tl, 0%, 0);
        transform: translateY(-50%);
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .c-tooltip {
    position: relative;

    &:hover {
      .c-tooltip__desc {
        display: inline-block;
      }
    }
  }
}

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//     display: inline-block;
//   }

//   100% {
//     opacity: 1;
//   }
// }
</style>
