import menuExplore1 from '@/assets/menuExplore/menu_1.png';
import menuExplore2 from '@/assets/menuExplore/menu_2.png';
import menuExplore3 from '@/assets/menuExplore/menu_3.png';
import menuExplore4 from '@/assets/menuExplore/menu_4.png';
import menuExplore5 from '@/assets/menuExplore/menu_5.png';
import menuExplore6 from '@/assets/menuExplore/menu_6.png';

export const menuExplore = [
  {
    label: '主題',
    desc: '依照氣氛來探索音樂，是一種在家庭、辦公、購物、休閒、娛樂等場所中播放的輔助性背景音樂',
    imgIndex: 1,
    routeType: 'themes',
    gtmClass: 'GTM_POINT_header_explore_themes',
  },
  {
    label: '氣氛',
    desc: '依照氣氛來探索音樂，是一種在家庭、辦公、購物、休閒、娛樂等場所中播放的輔助性背景音樂',
    imgIndex: 2,
    routeType: 'moods',
    gtmClass: 'GTM_POINT_header_explore_moods',
  },
  {
    label: '曲風',
    desc: '依照氣氛來探索音樂，是一種在家庭、辦公、購物、休閒、娛樂等場所中播放的輔助性背景音樂',
    imgIndex: 3,
    routeType: 'genres',
    gtmClass: 'GTM_POINT_header_explore_genres',
  },
  {
    label: '歌單',
    desc: '依照氣氛來探索音樂，是一種在家庭、辦公、購物、休閒、娛樂等場所中播放的輔助性背景音樂',
    imgIndex: 4,
    routeType: 'playlists',
    gtmClass: 'GTM_POINT_header_explore_playlists',
  },
  {
    label: '專輯',
    desc: '依照氣氛來探索音樂，是一種在家庭、辦公、購物、休閒、娛樂等場所中播放的輔助性背景音樂',
    imgIndex: 5,
    routeType: 'albums',
    gtmClass: 'GTM_POINT_header_explore_albums',
  },
  {
    label: '品牌',
    desc: '依照氣氛來探索音樂，是一種在家庭、辦公、購物、休閒、娛樂等場所中播放的輔助性背景音樂',
    imgIndex: 6,
    routeType: 'labels',
    gtmClass: 'GTM_POINT_header_explore_labels',
  },

];

export const menuExploreImg = {
  1: menuExplore1,
  2: menuExplore2,
  3: menuExplore3,
  4: menuExplore4,
  5: menuExplore5,
  6: menuExplore6,
};
