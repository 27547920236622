export default {
  about: '音韶是全台灣最大的版權音樂代理商，超過百萬首的音樂曲庫，為您提供最專業及優質的服務。',
  explore: '探索屬於 您的靈感',
  hot: {
    title: '熱門精選',
    Albums: '精選專輯',
    Playlists: '精選歌單',
    Genres: '精選曲風',
    Moods: '精選氣氛',
  },
  inherit: {
    title: '讓音樂延續下去',
    text: '免費註冊立即試聽音樂，建立您專屬的音樂收藏，創造感動體驗。',
  },
  sloganA: '音韶為台灣最大版權音樂代理商，超過百萬首音樂，為您提供多樣化選擇，',
  sloganB: '齊全、豐富的音樂，可滿足各類需求。',
  learnMore: '查看更多',
};
