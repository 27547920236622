export default {
  albums: '專輯',
  tracks: '曲目',
  playlists: '歌單',
  empty: '',
  id: 'ID',
  remove: '刪除',
  playlist: '專案選曲',
  searchHistory: '搜尋歷史',
  playedHistory: '播放紀錄',
  downloadHistory: '下載紀錄',
  myNote: '我的筆記',
  previewTool: '影音結合體會',
  searchAllProject: '搜尋所有專案',
  myTracks: '我的曲目',
  myAlbums: '我的專輯',
  favouriteTracks: '曲目收藏',
  favouriteAlbums: '專輯收藏',
  version: '版本類型',
  count: '數量',
  divide: '分軌',
  modifyDate: '修改日期',
  trackName: '曲目名稱',
  title: '名稱',
  length: '長度',
  viewActivityRecord: '查看活動紀錄',
  keyword: '關鍵字',
  filterTag: '過濾標籤',
  searchDate: '搜尋日期',
  playedDate: '播放日期',
  preserve30Days: '僅保留30日之紀錄',
  versionAndEdit: '版本長度 / 類型',
  trackInfo: '曲目資訊',
  toAlbumPage: '前往專輯頁面',
  toPlaylistPage: '前往歌單頁面',
  addToQueue: '新增至播放順序',
  downloadDate: '免費下載期限',
  noteCount: '筆記數量',
  updateDate: '更新日期',
  selectedPreviewTracks: '請選擇專案曲目搭配上傳畫面',
  clickToPlayPosition: '點擊進度條跳至該播放位置',
  searchTrackName: '搜尋專案曲目名稱',
  syncPreview: '同步預覽',
  mediaFile: '影片檔案',
  wave: '波形',
  desc: '描述',
  addToPlaylist: '加入專案',
  allPlaylist: '專案選曲',
  previeToolDesc1: '1. 將曲目新增至專案選曲-我的曲目',
  previeToolDesc2: '2. 上傳您的影片',
  previeToolDesc3: '3. 即時預覽影片搭配曲目後的效果',
  startUse: '開始使用',
  seeAll: '看全部',
  playlistName: '歌單名稱',
  projectName: '專案名稱',
  albumName: '專輯名稱',
  lyric: '歌詞',
  move: '搬移歌單',
  copy: '複製歌單',
  delete: '刪除',
  invite: '分享歌單',
  downloadPlaylist: '下載歌單',
  searchTrackNamePlaceholder: '搜尋曲目名稱',
  notice: '僅保留近30天之紀錄',
  uploadVideo: '上傳影片',
  pleaseUploadVideo: '請上傳影片',
  pleaseSelectTrack: '請選擇曲目',
  addToProject: '加入專案',
  moveToPlaylists: '搬移至歌單',
  seeTrackNote: '查看曲目筆記',
  activityRecords: '活動紀錄',
  editName: '編輯名稱',
  addToFavourite: '加入收藏',
  albumInfo: '專輯資訊',
  download: '下載',
  addNote: '新增筆記',
  addEditNote: '新增/查看筆記',
  removeFromFavourite: '從收藏刪除',
  removeCurrTrack: '刪除此曲目',
  removeCurrProject: '刪除此專案',
  removePlaylist: '刪除歌單',
  shareTrack: '分享曲目',
  share: '分享',
  sharePlaylist: '分享歌單',
  shareAlbum: '分享專輯',
  albumIsOffShelf: '該專輯已下架',
  trackIsOffShelf: '該曲目已下架',
  playQueue: '播放順序', // PLAY QUEUE
  versionEdits: '版本類型', // VERSION & EDITS
  add: '新增',
  addNewPlaylist: '新專案名稱',
  noteContent: '筆記內容',
  editNote: '編輯筆記',
  searchNote: '搜尋筆記',
  pleaseEnterCurrectTimeFormat: '請輸入正確的時間格式',
  copyLink: '複製連結',
  shareTrackEmailWording: {
    t1: '邀請您收聽',
    t2: '嘿! 我發現了超棒的音樂',
    t3: '快來聽聽看吧',
  },
  createProject: '新增專案',
  waves: '波形',
  tags: '標籤',
  description: '描述',
  detailDescription: '描述',
  copyDataLabel: {
    trackTitle: '曲目名稱/Track Title',
    composer: '作曲家/Composer',
    publisher: '發行公司/Publishers',
    albumTitle: '專輯/Album Title',
    albumCode: '專輯編號/Album Code',
    trackNumber: '曲號/Track ID',
    label: '品牌/Labels',
    labelCode: '系列編號/Label Code',
  },
  with: '包含',
  without: '排除',
  submitSave: '儲存',
  fromWhere: '指定開始處',
  play: '播放',
  rename: '重命名',
  ignoreAlbum: '已下架專輯將被忽略',
  ignoreTrack: '已下架曲目將被忽略',
  removeNoteSucceed: '刪除筆記成功',
};
