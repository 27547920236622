import axios from 'axios';
import request from '@/api';
import {
  searchTrack,
  searchAlbum,
  searchAll,
  searchPlaylist,
  searchTrackWithComposer,
  searchTrackWithLyrics,
  searchTrackWithAggs,
  searchTrackWithLyricsWithAggs,
  searchTrackWithComposerWithAggs,
} from '@/api/gql/search.gql';

window.cancelSearchTags = null;

export const searchTrackAPI = async (payload) => {
  const res = await request(searchTrack, payload);

  return res;
};

export const searchAlbumAPI = async (payload) => {
  const res = await request(searchAlbum, payload);

  return res;
};

export const searchPlaylistAPI = async (payload) => {
  const res = await request(searchPlaylist, payload);

  return res;
};

export const searchAllAPI = async (payload) => {
  const res = await request(searchAll, payload);
  return [...res, payload];
};

export const searchTrackWithLyricsAPI = async (payload) => {
  const res = await request(searchTrackWithLyrics, payload);

  return res;
};

export const searchTrackWithComposerAPI = async (payload) => {
  const res = await request(searchTrackWithComposer, payload);

  return res;
};

export const searchTrackWithAggsAPI = async (payload) => {
  const axiosCancelToken = new axios.CancelToken(((c) => {
    window.cancelSearchTags = c;
  }));

  const res = await request(searchTrackWithAggs, payload, '', axiosCancelToken);

  return res;
};

export const searchTrackWithLyricsWithAggsAPI = async (payload) => {
  const axiosCancelToken = new axios.CancelToken(((c) => {
    window.cancelSearchTags = c;
  }));

  const res = await request(searchTrackWithLyricsWithAggs, payload, '', axiosCancelToken);

  return res;
};

export const searchTrackWithComposerWithAggsAPI = async (payload) => {
  const axiosCancelToken = new axios.CancelToken(((c) => {
    window.cancelSearchTags = c;
  }));

  const res = await request(searchTrackWithComposerWithAggs, payload, '', axiosCancelToken);

  return res;
};
