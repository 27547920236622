<template>
  <div class="c-share function-icon" :class="{
    'loading': isLoading
  }" @click="handleDownload">
    <tooltip :label="$t('myMusic.download')">
      <div class="function-icon">
        <div class="function-icon__img function-icon__img--download"></div>
        <span class="function-icon__label" v-if="label"> {{ $t('myMusic.download') }} </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false" @after-leave="reset">
      <model-base-layout :title="$t('myMusic.download')" decoLine contentPadding>
        <p>{{ $t(downloadCollectionLabel) }}: {{ collectionName }} ({{ collectionCode }})</p>

        <div class="select-wrap">

          <n-form :model="downloadForm" :rules="rules" ref="downloadFormRef">
            <n-form-item path="downloadFormat" :label="$t('member.downloadFormat')">

              <n-radio-group v-model:value="downloadForm.downloadFormat" name="radiogroup1">
                <n-space>
                  <n-radio value="MP3">MP3</n-radio>
                  <n-radio value="WAV">WAV</n-radio>
                </n-space>
              </n-radio-group>
            </n-form-item>
          </n-form>
        </div>

        <div class="btn-wrap">
          <div class="btn-wrap__btn">
            <mi-button type="border" @click="closeModal">
              <span>{{ $t('global.cancel') }}</span>
            </mi-button>
          </div>
          <div class="btn-wrap__btn" @click="submitDownloadFormat">
            <mi-button>
              <span>{{ $t('myMusic.download') }}</span>
            </mi-button>
          </div>
        </div>
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import { debounce } from 'lodash-es';
import { useI18n } from 'vue-i18n';
import {
  NModal,
  NSpace,
  NRadioGroup,
  NRadio,
  NForm,
  NFormItem,
} from 'naive-ui';
import Tooltip from '@/components/Global/Tooltip.vue';
import { downloadAllAPI } from '@/api/download';
import useDownloadFile from '@/hooks/useDownloadFile';
import MiButton from '@/components/Global/MiButton.vue';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
// import useGtm from '@/hooks/useGtm';
import genMessage from '@/utils/handleMessage';
import { useSiteStore } from '@/store/site';
import { getAlbumOnlyTracksAPI } from '@/api/getAlbums';
import { getPlaylistOnlyTracksAPI } from '@/api/getPlaylists';
import useRequest from '@/hooks/useRequest';
import usePopInfo from '@/hooks/usePopInfo';

export default defineComponent({
  name: 'DownloadAll',
  components: {
    Tooltip,
    NModal,
    ModelBaseLayout,
    NSpace,
    NRadioGroup,
    NRadio,
    MiButton,
    NForm,
    NFormItem,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    downloadAllType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { errorPopInfo } = usePopInfo();

    const { t } = useI18n();
    const { fileDownload } = useDownloadFile();
    const siteStore = useSiteStore();
    const isLoading = ref(false);
    const modelStatus = ref(false);
    const downloadFormRef = ref();
    const downloadFormat = ref('');
    const downloadForm = ref({
      downloadFormat: '',
    });

    const rules = {
      downloadFormat: {
        required: true,
        message: t('member.selectDownloadFormat'),
      },
    };

    const closeModal = () => {
      modelStatus.value = false;
    };

    // const message = useMessage();
    // const { handleGtm } = useGtm();
    const { request: albumTrackUidReq } = useRequest(getAlbumOnlyTracksAPI);
    const { request: playlistTrackUidReq } = useRequest(getPlaylistOnlyTracksAPI);

    const collectionName = computed(() => props.data?.albumTitleDisplay || props.data?.name || props.data?.album?.albumTitleDisplay);
    // album 會有值，playlist 會是空
    const collectionCode = computed(() => {
      const code = props.data?.albumCode || props.data?.album?.albumCode || undefined;
      return code || '';
    });

    const downloadCollectionLabel = computed(() => {
      if (['searchAlbum', 'album'].includes(props.downloadAllType)) {
        return 'member.downloadAlbum';
      }

      if (['searchPlaylist', 'playlist'].includes(props.downloadAllType)) {
        return 'member.downloadPlaylist';
      }

      if (props.downloadAllType === 'memberPlaylistAlbum') {
        return 'myMusic.download';
      }

      return 'myMusic.download';
    });

    const download = async (downloadType) => {
      if (isLoading.value) return;
      isLoading.value = true;
      let tracksUid = [];

      if (['searchAlbum', 'album'].includes(props.downloadAllType)) {
        const [, res] = await albumTrackUidReq({
          in: props.data.albumUid,
        });

        tracksUid = res.getAlbum.tracks.map((item) => {
          return ({
            trackUid: item.trackUid,
            title: `POINT_${item.albumCode}_${Number(item.trackNumber)}_${item.trackTitleDisplay}`,
          });
        });
      } else if (['searchPlaylist', 'playlist'].includes(props.downloadAllType)) {
        const [, res] = await playlistTrackUidReq({
          in: props.data.playlistUid,
        });

        tracksUid = res.getPlaylist.tracks.map((item) => ({
          trackUid: item.track?.trackUid,
          title: `POINT_${item.track?.albumCode}_${Number(item.track?.trackNumber)}_${item.track?.trackTitleDisplay}`,
        }));
      } else if (props.downloadAllType === 'memberPlaylistAlbum') {
        const [, res] = await albumTrackUidReq({
          in: props.data.album.albumUid,
        });

        tracksUid = res.getAlbum.tracks.map((item) => ({
          trackUid: item.trackUid,
          title: `POINT_${item.albumCode}_${Number(item.trackNumber)}_${item.trackTitleDisplay}`,
        }));
      }

      // const fileName = `POINT_${props.data?.albumTitleDisplay || props.data?.name || props.data?.album?.albumTitleDisplay}`;

      const downloadCollectionName = computed(() => (collectionCode.value ? `_${collectionCode.value}` : ''));
      const fileName = `POINT${downloadCollectionName.value}_${collectionName.value}`;

      // 不是錯誤，只是個提醒（最初命名用於錯誤）
      errorPopInfo({
        desc: t('global.downloadCompressing'),
      });

      const [, res] = await downloadAllAPI({
        // tracksUid,
        tracks: tracksUid,
        folderName: fileName,
        downloadType,
      });

      // 錯誤時可以blob轉成json
      const isJsonBlob = (data) => data instanceof Blob && data.type === 'application/json';

      if (isJsonBlob(res.data)) {
        const responseObj = await res.data.text();
        const { errors } = JSON.parse(responseObj);

        genMessage(errors[0].message, errors[0].extensions);
        isLoading.value = false;
        return;
      }

      // handleGtm('DOWNLOADED', { trackUid });
      // fileDownload(res?.data, fileName, 'zip');
      const contentDisposition = res.headers['content-disposition'];
      fileDownload(res?.data, contentDisposition);
      isLoading.value = false;
    };

    // const downloadDebounce = debounce(download, 500);

    const reset = () => {
      downloadForm.value.downloadFormat = '';
    };

    const handleDownload = () => {
      if (siteStore.memberInfo.downloadTypeRequest) {
        modelStatus.value = true;
        return;
      }

      download(siteStore.memberInfo.downloadType);
    };

    const handleValidateClick = async () => {
      const errors = await downloadFormRef.value.validate();

      return errors;
    };

    const submitDownloadFormat = async () => {
      const error = await handleValidateClick();

      if (error) {
        return;
      }

      download(downloadForm.value.downloadFormat);
      closeModal();
    };

    return {
      // downloadDebounce,
      collectionName,
      collectionCode,
      modelStatus,
      isLoading,
      closeModal,
      handleDownload,
      downloadFormat,
      downloadForm,
      downloadFormRef,
      handleValidateClick,
      rules,
      submitDownloadFormat,
      reset,
      downloadCollectionLabel,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';

.btn-wrap {
  margin-top: 50px;
  @include flex(flex-end);

  &__btn {

    &+& {
      margin-left: 10px;
    }
  }
}

.select-wrap {
  margin-top: 20px;
}
</style>
