import { defineStore } from 'pinia';

export const useSearchStore = defineStore({
  id: 'search',
  state: () => ({
    searchValue: {
      keyword: '',
      type: '',
      includeTags: [],
      excludeTags: [],
      includeTagGroups: [],
      excludeTagGroups: [],
      includeLabels: [],
      excludeLabels: [],
      duration: 0,
      bpm: 0,
      // orderBy: 'DESC',
    },

    // 用來儲存，所有被加入過標籤的 tag
    historyActivedTagMapping: {},

    // 欲搜尋、排除的 tags
    activeTags: [],
    includeTags: [],
    excludeTags: [],
    rootTypeCount: {
      instruments: 0,
      tempos: 0,
      countryList: 0,
      editType: 0,
      versionType: 0,
      musicStyles: 0,
      moods: 0,
      musicFor: 0,
      eras: 0,
      albumCompany: 0,
    },

    includeLabelGroupStatus: [],
    excludeLabelGroupStatus: [],
    searchConditionChangeMessage: false,

    // 與 filter 相關
    // 存 root 下要顯示的 TagGroup
    activeTagGroup: {},
    activeTagGroupUidList: [],
    recordTagCount: {},
    // TagGroup 下要顯示得 Tag
    // activeTag: {},
    allTagGroupList: {},
    allowedInsertTags: true,
    expandUid: '',
    // 紀錄版本類型和版本長度的排序
    orderWeight: {
      editType: {},
      versionType: {},
    },
  }),
  getters: {
    rootCount: (state) => {
      const keys = Object.keys(state.activeTagGroup);

      const countLength = keys.reduce((list, key) => {
        list[key] = state.activeTagGroup[key].reduce((total, item) => {
          total += item.count;

          return total;
        }, 0);

        return list;
      }, {});

      return countLength;
    },
    activeIncludeTag: (state) => {
      const activeIncludeTag = state.activeTags.filter((tag) => tag.type === 'include').map((tag) => tag.tagUid);

      return activeIncludeTag;
    },
  },
  actions: {
    setExpandUid(uid) {
      this.expandUid = uid;
    },

    resetActivedTags() {
      this.activeTags = [];
      this.includeTags = [];
      this.excludeTags = [];
    },
    saveHistoryActivedTagMapping(tag) {
      if (!this.historyActivedTagMapping[tag.tagUid]) {
        this.historyActivedTagMapping[tag.tagUid] = tag;
      }
    },

    handleSearchConditionChangeMessage(status) {
      this.searchConditionChangeMessage = status;
    },

    handleTagsForSearch() {
      this.searchValue.includeTags = [];
      this.searchValue.includeTagGroups = [];
      this.searchValue.includeLabels = [];
      this.searchValue.excludeTags = [];
      this.searchValue.excludeTagGroups = [];
      this.searchValue.excludeLabels = [];

      this.activeTags.forEach((item) => {
        if (item.type === 'include') {
          if (item.isLabel) {
            console.log(1);
            // 排除的 品牌
            if (!this.searchValue.includeLabels.includes(item.tagUid)) {
              this.searchValue.includeLabels.push(item.tagUid);
            }
          } else if (item.tags) {
            console.log(2);
            // 排除的 標籤群組

            if (!this.searchValue.includeTagGroups.includes(item.tagUid)) {
              this.searchValue.includeTagGroups.push(item.tagUid);
            }
          } else {
            console.log(3);
            // 排除的 標籤
            if (!this.searchValue.includeTags.includes(item.tagUid)) {
              this.searchValue.includeTags.push(item.tagUid);
            }
          }
        }

        if (item.type === 'exclude') {
          if (item.isLabel) {
            console.log(4);
            // 排除的 品牌
            if (!this.searchValue.excludeLabels.includes(item.tagUid)) {
              this.searchValue.excludeLabels.push(item.tagUid);
            }
          } else if (item.tags) {
            console.log(5);
            // 排除的 標籤群組
            if (!this.searchValue.excludeTagGroups.includes(item.tagUid)) {
              this.searchValue.excludeTagGroups.push(item.tagUid);
            }
          } else {
            console.log(6);
            // 排除的 標籤
            if (!this.searchValue.excludeTags.includes(item.tagUid)) {
              this.searchValue.excludeTags.push(item.tagUid);
            }
          }
        }
      });
    },

    setSearchValue(key, value) {
      this.searchValue[key] = value;

      this.handleSearchConditionChangeMessage(true);
    },

    countRootTypeCount() {
      const rootTypekeys = Object.keys(this.rootTypeCount);

      rootTypekeys.forEach((key) => {
        this.rootTypeCount[key] = this.activeTags.reduce((total, item) => {
          if (key === item.rootType) {
            total += 1;
          }

          return total;
        }, 0);
      });
    },

    check10TagsLimit() {
      if (this.activeTags.length >= 10) {
        window.$message.error('已達標籤選擇上限10個');
        return false;
      }
      return true;
    },

    // tag
    setIncludeTags(tagData) {
      if (this.includeTags.find((item) => item.tagUid === tagData.tagUid)) {
        window.$message.error('此標籤已在選取列表中');
        return;
      }

      // if (!this.check10TagsLimit()) return;

      const includeTag = {
        ...tagData,
        type: 'include',
      };

      this.includeTags = [...this.includeTags, includeTag];
      this.saveHistoryActivedTagMapping(tagData);
      this.updateActiveTags();
    },
    removeIncludeTags(tagData) {
      this.includeTags = this.includeTags.filter((item) => tagData.tagUid !== item.tagUid);
      this.updateActiveTags();
    },
    setExcludeTags(tagData) {
      if (this.includeTags.find((item) => item.tagUid === tagData.tagUid)) {
        window.$message.error('此標籤已在選取列表中');
        return;
      }
      // if (!this.check10TagsLimit()) return;

      const excludeTag = {
        ...tagData,
        type: 'exclude',
      };

      this.excludeTags = [...this.excludeTags, excludeTag];
      this.saveHistoryActivedTagMapping(tagData);

      this.updateActiveTags();
    },
    removeExcludeTags(tagData) {
      this.excludeTags = this.excludeTags.filter((item) => tagData.tagUid !== item.tagUid);

      this.updateActiveTags();
    },

    // tagGroup
    setIncludeLabelGroupStatus(tagGroupData) {
      this.includeLabelGroupStatus.push(tagGroupData);
    },
    setExcludeLabelGroupStatus(tagGroupData) {
      this.excludeLabelGroupStatus.push(tagGroupData);
    },
    removeIncludeLabelGroupStatus(tagGroupData) {
      this.includeLabelGroupStatus = this.includeLabelGroupStatus.filter(
        (item) => item.tagGroupUid !== tagGroupData.tagGroupUid,
      );
    },
    removeExcludeTLabelGroupStatus(tagGroupData) {
      this.excludeLabelGroupStatus = this.excludeLabelGroupStatus.filter(
        (item) => item.tagGroupUid !== tagGroupData.tagGroupUid,
      );
    },
    removeAllSameTag(tagGroupData) {
      const tagList = tagGroupData.tags;
      this.includeTags = this.includeTags.filter(
        (item) => !tagList.find((findItem) => item.tagUid === findItem.tagUid),
      );
      this.excludeTags = this.excludeTags.filter(
        (item) => !tagList.find((findItem) => item.tagUid === findItem.tagUid),
      );
    },
    syncAllTagsRecords(tagGroupData, type) {
      this.updateActiveTags();
      this.removeIncludeLabelGroupStatus(tagGroupData);
      this.removeExcludeTLabelGroupStatus(tagGroupData);

      if (type === 'include') {
        this.setIncludeLabelGroupStatus(tagGroupData);
      } else {
        this.setExcludeLabelGroupStatus(tagGroupData);
      }
    },

    updateActiveTags() {
      this.activeTags = [...this.includeTags, ...this.excludeTags];
      this.countRootTypeCount();
      // this.handleSearchConditionChangeMessage(true);
    },
    insertToAllTagGroupList(tagGroup, type) {
      if (!this.allTagGroupList[type]) {
        this.allTagGroupList[type] = tagGroup;
      }
    },

    recordOrderWeight(tagGroup, type) {
      this.orderWeight[type] = tagGroup.reduce((obj, item) => {
        obj[item.tagUid] = item.weight;

        return obj;
      }, {});
    },
    cleanCurrTagGroupTag(type) {
      this.activeTags.forEach((item) => {
        if (item.rootType === type) {
          if (item.type === 'include') {
            this.removeIncludeTags(item);
          }

          if (item.type === 'exclude') {
            this.removeExcludeTags(item);
          }
        }
      });
    },
    cleanAllActiveTag() {
      this.activeTags = [];
      this.includeTags = [];
      this.excludeTags = [];
      this.searchValue.includeTags = [];
      this.searchValue.includeTagGroups = [];
      this.searchValue.includeLabels = [];
      this.searchValue.excludeTags = [];
      this.searchValue.excludeTagGroups = [];
      this.searchValue.excludeLabels = [];
    },
    insertTagUnderTagGroup(tagGroup) {
      const type = tagGroup.tagRootId;
      const tags = tagGroup.tags.map((item) => ({
        label: item.name,
        tagGroupUid: tagGroup.tagGroupUid,
        tagUid: item.tagUid,
        rootType: item.tagSource,
      }));

      this.allTagGroupList[type] = this.allTagGroupList[type].map((item) => {
        if (item.tagGroupUid === tagGroup.tagGroupUid) {
          item.tags = tags;
        }

        return item;
      });
    },
    // 與 filter 相關
    addTagGroup(tagGroups, tags, labels) {
      this.cleanBeforeSearch();

      // const allowedGroupUid = ['instruments', 'countryList', 'musicStyles', 'moods', 'musicFor', 'editType'];
      tagGroups.forEach((item) => {
        // if (allowedGroupUid.includes(item.tagRootId)) {

        if (!this.activeTagGroup[item.tagRootId]) {
          this.activeTagGroup[item.tagRootId] = [];
        }

        this.activeTagGroup[item.tagRootId].push({
          label: item.name,
          rootType: item.tagRootId,
          tagGroupUid: item.tagGroupUid,
          tagUid: item.tagGroupUid,
          tags: [],
          count: item.count,
        });
        // }
      });

      // 品牌
      labels.forEach((item) => {
        if (!this.activeTagGroup?.albumCompany) {
          this.activeTagGroup.albumCompany = [];
        }

        this.activeTagGroup.albumCompany.push({
          label: item.name,
          rootType: 'albumCompany',
          // 為統一格式，key 仍然用 tag
          tagGroupUid: item.labelUid,
          tagUid: item.labelUid,
          tags: [],
          count: item.count,
          isLabel: true,
        });
      });

      this.activeTagGroupUidList = tags.map((tag) => tag.tagUid);
      this.recordTagCount = tags.reduce((obj, tag) => {
        if (!obj[tag.tagUid]) {
          obj[tag.tagUid] = tag.count;
        }

        return obj;
      }, {});

      this.setAllowedInsertTags(true);
    },
    insertTagUnderActiveTagGroup(tagGroup) {
      const type = tagGroup.tagRootId;

      const tags = tagGroup.tags.map((item) => ({
        label: item.name,
        tagGroupUid: tagGroup.tagGroupUid,
        tagUid: item.tagUid,
        rootType: item.tagSource,
      }));

      this.activeTagGroup[type] = this.activeTagGroup[type].map((item) => {
        if (item.tagGroupUid === tagGroup.tagGroupUid) {
          item.tags = tags;
        }

        return item;
      });

      this.activeTagGroup[type] = this.activeTagGroup[type].map((item) => {
        item.tags = item.tags.filter((tag) => this.activeTagGroupUidList.includes(tag.tagUid));

        return item;
      });
    },

    // 清除側欄篩選渲染
    cleanBeforeSearch() {
      this.activeTagGroup = {};
      this.activeTagGroupUidList = [];
      this.recordTagCount = {};
    },

    setAllowedInsertTags(status) {
      this.allowedInsertTags = status;
    },
  },

});

export const a = '';
