export default {
  required(label, type = 'input') {
    const fieldType = {
      input: '請輸入',
      select: '請選擇',
    };

    return `${fieldType[type]}${label}`;
  },
  minMaxRange(min, max, label) {
    return `${label}長度需介於 ${min}~${max} 位元`;
  },
  max(max) {
    return `最大長度為 ${max} 位元`;
  },
  min(min) {
    return `最小長度為 ${min} 位元`;
  },
  email: '請輸入正確的 Email 格式',
  password: '請輸入正確的密碼格式',
  taiwanMobile: '請輸入正確的手機格式',
  passwordConfirm: '請輸入正確的密碼與確認密碼',
  predictBirthDate: '預產期日期格式錯誤',
  birthday: '請輸入正確的出生日期',
  hospital: '請選擇醫院',
  customHospital: '請擇一選擇醫院或自行填寫',
  bornWeek: '出生週數需大於 0',
  bornWeight: '出生體重需大於 0',
  agreeTerms: '請勾選「我同意『服務條款內容』」',
  taiwanId: '身分證字號格式錯誤',
  enrollMembers: '至少需要1人參加',
  contactLimit: '內容不得超過 1000 字',
  contactLimit200: '內容不得超過 200 字',
};
