<template>
  <div class="sign-out">
    <div class="sign-out__content" @click="handleLogoutConfirm">
      <p class="sign-out__content__text">SIGN OUT</p>
      <img class="sign-out__content__icon" src="@/assets/profile/icon_signout.svg" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useSiteStore } from '@/store/site';
import usePopInfo from '@/hooks/usePopInfo';

export default defineComponent({
  name: 'SignOut',
  setup() {
    const { t } = useI18n();
    const siteStore = useSiteStore();
    const router = useRouter();
    const route = useRoute();
    const { successPopInfo } = usePopInfo();

    const handleLogout = () => {
      siteStore.handleLogout();

      if (route.meta.auth) {
        router.push({ name: 'home' });
      }
    };

    const handleLogoutConfirm = async () => {
      successPopInfo({
        desc: t('msg.logoutConfirm'),
        callback: handleLogout,
        showClose: true,

      });
    };

    return {
      handleLogoutConfirm,
      handleLogout,
    };
  },
});
</script>
<style lang="scss" scoped>
.sign-out {
  flex: none;
  width: 50%;
  @include flex(flex-end);

  &__content {
    @include padding(0 10px);
    @include inline-flex(space-between);
    background-color: $c-assist2;
    width: 100px;
    height: 32px;
    border-radius: 16px;
    cursor: pointer;

    &__text {
      @include font-style($c-assist4, 12, normal, 0px, 16px);
    }

    &__icon {
      width: 13px;
      margin-left: 5px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .sign-out {
    @include flex(center, center);

    &__content {
      @include padding(36px 0 30px 0);
      @include flex();
      background-color: transparent;
      width: auto;

      &__text {
        @include font-style($c-assist4, 14, normal, 0.8px, 18px);
      }

      &__icon {
        width: 20px;
        margin-left: 12px;
      }
    }
  }
}
</style>
