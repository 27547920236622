export default {
  title: '探索音樂',
  exploreTypeDesc: {
    themes: '依主題探索音樂－讓我們精心策劃的主題，為您的想像帶來無限靈感。',
    moods: '依氣氛探索音樂－無論是史詩級的英雄電影配樂、誘人的廣告活動或是俏皮的兒童趣味，這裡的氣氛分類都能滿足您所有的情感音符。',
    genres: '依曲風探索音樂－無論是藍調爵士、放克、電音、嘻哈等流行元素，您可以在多種曲風中找到符合的音樂。',
    playlists: '依歌單探索音樂－每一個精心設計的歌單，為您打造最符合心境的旋律清單。',
    albums: '依專輯探索音樂－收錄了數萬張不同類型的音樂專輯，從經典到流行，應有盡有。',
    labels: '依品牌探索音樂－從全世界品牌中找到適合您的專輯與曲目。',
  },
  type: {
    themes: '主題',
    moods: '氣氛',
    genres: '曲風',
    albums: '專輯',
    playlists: '歌單',
    labels: '品牌',
  },
  popularAlbum: '精選專輯',
  allAlbum: '所有專輯',
  seeMore: '查看更多',
  noData: '目前沒有資料',
  category: '分類',
  order: '排序',
  learnMore: '查看更多',
  exploreMore: '探索...',
  popularLabels: '精選品牌',
  popularPlaylists: '精選歌單',
  allPlaylists: '所有歌單',
  tags: '標籤',
  publicDate: '發行日期',
  track: '曲目',
  shareTrack: '分享曲目',
  relative: '相關',
  hotTracks: '熱門曲目',
  relatedLabels: '相關品牌',
  explore: '探索',
};
