// import { useMessage } from 'naive-ui';
import { playedTrackAPI } from '@/api/getTrack';
// import useGtm from '@/hooks/useGtm';
import { useSiteStore } from '@/store/site';

export default () => {
  // const message = useMessage();
  // const { handleGtm } = useGtm();
  const siteStore = useSiteStore();

  const getTrackFileUrl = async ({ trackUid }) => {
    const accountUid = siteStore?.memberInfo?.accountUid || '';
    const [err, res] = await playedTrackAPI({ in: { trackUid, accountUid } });
    // if (err) return message.error(err.message);
    if (err) {
      window.$message.error(err.message);
      return '';
    }
    // handleGtm('PLAYED', { trackUid });
    if (res.playedTrack?.audioInfoMp3.includes('http')) return res.playedTrack?.audioInfoMp3;
    return `${process.env.VUE_APP_IMAGE_DOMAIN}${res.playedTrack?.audioInfoMp3}`;
  };

  return {
    getTrackFileUrl,
  };
};
