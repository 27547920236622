export default {
  title: '授權服務',
  cookiesTitle: 'COOKIES 政策',
  termsTitle: '使用條款',
  privacyTitle: '隱私權政策',
  customization: '客製化服務',
  contents: [
    {
      title: '授權服務',
      desc: [
        '超過百萬首曲目高品質配樂，強大的搜尋篩選工具，各式授權組合，配合您的製作預算',
      ],
    },
    {
      title: '單曲授權 NeedleDrop',
      desc: [
        '需要特定音樂來襯托您的作品嗎？我們擁有全台灣最大的音樂曲庫，各類型音樂曲風，成為您創作上最強後盾，歡迎隨時線上試聽挑選，讓作品更加完美。',
      ],
    },
    {
      title: '年租式授權 Blanket License',
      desc: [
        '提供多元化的年租式方案(專輯或曲數)，滿足利用人不同的使用需求。(如電視台、廣播電台、錄音室、線上遊戲、網紅及Podcast…等數位媒體)',
      ],
    },
    {
      title: '專案授權 Project License',
      desc: ['量身打造適合您的超值專案價。無論是紀錄片、戲劇節目、形象影片、有聲書、電影、Podcast…等配樂授權需求，請立即聯絡我們 !'],
    },
    {
      title: '音效授權 Sound Effect',
      desc: ['曾為您的特殊音效不足感到煩惱嗎？我們提供各類型的音效片，讓您一次付費便能⾧期使用，馬上聯絡我們。'],
    },
    {

      title: '百貨公司、商家、門市音樂授權 In Store Music',
      desc: [
        '舒適的購物環境能吸引消費者進入，好的音樂能讓消費者停留更久的時間，不論是運動風、奢華風、兒童館 或是周年慶、特殊節日活動...等，我們都能提供客製化的需求，規劃配合環境的音樂，讓您的商場與擁有獨一無二的魅力。',
      ],
    },
    {
      title: '作曲服務 Customized Composing Service',
      desc: [
        '由業界著名配樂作曲家團隊，為您作曲。節目片頭，電視台頻道包裝、企業Jingle、遊戲、廣告配樂、電視劇配樂、主題曲、插曲、片尾曲，好溝通，創意新穎。',
      ],
    },
    {
      title: 'Point Music X 音樂串流平台 共同合作 供個人線下耹聽!',
      desc: [
        '您是否很想收藏某一首喜愛的廣告音樂或是影視配樂，卻無從入手? 我們聽見廣大小耳朵的心聲了! 特別整理出您絕對有印象的熱門配樂歌單! 請透過各大線上音樂串流平台的訂閱服務，馬上將喜愛的音樂下載變成您的專屬手機鈴聲或來電答鈴。',
      ],
    },
  ],
};
