export default {
  400001: 'Invalid account or password',
  400002: 'Member status error',
  400003: 'The email address is already registered',
  400004: 'Your passwords must match',
  400005: 'Old password is incorrect',
  400006: 'Account does not exist or suspended',
  400007: 'File format error, upload format should be jpg, jpeg, png',
  400008: 'Cannot access the file',
  400009: 'Already in your collections',
  400010: 'All tracks had been added in your collections',
  400011: 'Oops! Something went wrong.',
  400012: 'Insufficient download quota',
  400013: 'Not in validity period',
  400014: 'Not allowed to download this track',
  400015: 'No download content',
  400016: 'File not exist.',
  400017: 'Not allowed to download this type of tracks/album',
  401001: 'Unauthorized',
  401002: 'Account does not exist or suspended',
  403001: 'No permission',
  404001: 'Oops! Something went wrong.',
  404002: 'Oops! Something went wrong.',
  413001: 'File size error, upload file size limit 1MB',
  500001: 'Oops! Something went wrong.',
  500002: 'Oops! Something went wrong.',
  500004: 'Oops! Something went wrong.',
};
