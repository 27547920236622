export default {
  400001: '帳號或密碼不正確',
  400002: '用戶當前狀態不符',
  400003: '此Email已註冊過',
  400004: '新密碼不一致',
  400005: '舊密碼錯誤',
  400006: '用戶不存在或被停用',
  400007: '圖片格式不支援，需為 jpg, jpeg, png',
  400008: '無法取得音樂檔案',
  400009: '已在收藏中',
  400010: '全部曲目已在收藏中',
  400011: 'Oops!出錯了!',
  400012: '下載數量已達限額，無法下載',
  400013: '有效期間不符合，無法下載',
  400014: '無此歌曲的下載權限',
  400015: '沒有下載內容',
  400016: '下載檔案不存在，無法下載',
  400017: '下載不合法，不允許下載該類型',
  401001: '權限驗證信息缺失或不合法',
  401002: '帳號不存在或被凍結',
  403001: '權限不匹配',
  404001: 'Oops!出錯了!',
  404002: 'Oops!出錯了!',
  413001: '上傳檔案容量過大，限制1MB',
  500001: 'Oops!出錯了!',
  500002: 'Oops!出錯了!',
  500004: 'Oops!出錯了!',
};
