<template>
  <div class="c-divide-line"></div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DivideLine',
});
</script>
<style lang="scss" scoped>
.c-divide-line {
  width: 100%;
  height: 1px;
  background-color: rgba($c-assist3, 0.5);
  margin: 20px 0 30px;
}
</style>
