export default {
  keepLogin: '保持登录',
  forgotPassword: '忘记密码?',
  noAccount: '还没有加入会员吗？',
  forgetPwd: '忘记密码',
  alreadyAMember: '已经是会员?',
  resetEmailSentTo: '已将密码重设信发送至',
  linkExpired: '连结已失效',
  home: '首页',
  isValidating: '验证中',
  loginAndSendAgainMsg: '请登录您的帐号后选择重新发送验证连结通知信！',
  validateLinkExpired: '验证连结已失效',
  resetPwd: '密码重设',
  siteIntro: '成为音韶会员，您可以：',
  siteIntroDesc1: '线上完整试听超过百万首曲目',
  siteIntroDesc2: '无上限建立喜爱歌单并与他人分享',
  siteIntroDesc3: '使用影音结合体会功能搭配您的影片',
  byRegistering: '我同意',
  termsOfUse: '使用条款',
  and: '和',
  privacyPolicy: '隐私权政策',
  valiateEmailSentTo: '已将验证连结发送至',
  emailIsAccount: '电子邮箱即为您的登录帐号',
  sendValidateLinkToEmail: '已发送验证连结至您的邮箱，',
  clickForCompleteSignup: '请点击信内连结以完成注册。',
  signin: '登录',
  signup: '注册',
  registerForFullFeatures: '注册成为会员后，方可享有完整功能',

};
