import { createApp } from 'vue';
import VueClickAway from 'vue3-click-away';
import VueClipboard from 'vue-clipboard2';
import Maska from 'maska';
import { createHead } from '@vueuse/head';
import { createGtm } from '@gtm-support/vue-gtm';
import i18n from '@/i18n';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@/styles/init/index.scss';
import '@/styles/utils/_rwd.scss';
import 'animate.css';

import Loading from '@/components/Global/Loading.vue';

const head = createHead();
const app = createApp(App);

app.component('Loading', Loading);

app.use(store)
  .use(router)
  .use(i18n)
  .use(head)
  .use(VueClickAway)
  .use(VueClipboard)
  .use(Maska);

// 要上測試才會有用，localhost 域名不會觸發gtm
if (process.env.VUE_APP_GTM_CODE) {
  app.use(createGtm({
    id: process.env.VUE_APP_GTM_CODE,
    defer: true,
    compatibility: true,
    loadScript: true,
    vueRouter: router,
  }));
}

app.mount('#app');
