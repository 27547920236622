<template>
  <div class="c-track-info" :class="{ 'c-track-info--padding': padding }">
    <div class="c-track-info__content">
      <p class="info-desc">
        {{ description }}
      </p>

      <div class="info-detail">
        <ul class="data-list">
          <li class="data-list__item" v-for="item of trackInfo" :key="item.title">
            <p class="data-list__item__title">{{ item.title }}</p>
            <p class="data-list__item__text" v-for="(d, idx) of item.data" :key="d">{{ d }}
              <span v-if="idx !== item.data.length - 1">/</span>
            </p>
          </li>
        </ul>

        <div class="copy">
          <tooltip :label="$t('global.copyTrackInfo')" placement="center" maxWidth="220px" >
            <img class="copy__img" src="@/assets/searchResults/icon_copy.svg" @click="doCopy" />
          </tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useMessage } from 'naive-ui';
import { useI18n } from 'vue-i18n';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'TrackInfo',
  components: {
    Tooltip,
  },
  props: {
    albumData: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    trackInfo: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
    },
    padding: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const message = useMessage();
    const { t } = useI18n();
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    return {
      t,
      message,
      newMusicPlayerStore,
    };
  },

  methods: {
    copyInfo() {
      const content = `
      ${this.t('myMusic.copyDataLabel.trackTitle')}: ${this.data.trackTitleDisplay || this.newMusicPlayerStore?.musicInfo?.trackName || ''}
      ${this.t('myMusic.copyDataLabel.composer')}: ${(this.trackInfo[1] || this.data[1])?.data.map((name) => name).join(', ') || ''}
      ${this.t('myMusic.copyDataLabel.publisher')}: ${(this.trackInfo[2] || this.data[2]).data.map((name) => name).join(', ') || ''}
      ${this.t('myMusic.copyDataLabel.albumTitle')}: ${this.albumData.album?.albumTitleDisplay || this.newMusicPlayerStore?.currentPlayTrackInfo.track?.albumTitleDisplay || ''}
      ${this.t('myMusic.copyDataLabel.albumCode')}: ${this.data.albumCode || this.newMusicPlayerStore?.musicInfo?.creator || ''}
      ${this.t('myMusic.copyDataLabel.trackNumber')}: ${this.data.albumCode || this.newMusicPlayerStore?.musicInfo?.creator}-${this.data.trackNumber || this.newMusicPlayerStore?.musicInfo?.trackNumber}
      ${this.t('myMusic.copyDataLabel.label')}: ${this.albumData.label?.labelNameDisplay || this.trackInfo[3]?.data.map((name) => name).join(', ') || ''}
      ${this.t('myMusic.copyDataLabel.labelCode')}: ${this.albumData.label?.labelCode || this.newMusicPlayerStore?.currentPlayTrackInfo?.track?.labelCode || ''}
      ISRC: ${this.albumData?.isrc || this.newMusicPlayerStore?.currentPlayTrackInfo?.track?.isrc || ''}
      ISWC: ${this.albumData?.iswc || this.newMusicPlayerStore?.currentPlayTrackInfo?.track?.iswc || ''}
      `;

      return content;
    },
    doCopy() {
      this.$copyText(this.copyInfo()).then(
        () => {
          this.message.success(this.t('msg.copySuccess'));
        },
        () => {
          this.message.error(this.t('msg.copyFail'));
        },
      );
    },
  },
});
</script>
<style lang="scss" scoped>
.c-track-info {
  &--padding {
    padding: 20px;
  }

  &__content {
    width: 100%;
    @include flex(flex-start, flex-start, column);
  }
}

.info-desc {
  @include font-style($c-black, 14, 400, 0.7px, 20px);
  @include padding(0 0 24px 0);
  margin-bottom: 24px;
  border-bottom: 1px solid $c-assist7;
  width: 100%;
}

.info-detail {
  width: 100%;
  @include flex(flex-start, flex-start);
}

.data-list {
  flex: 1;

  &__item {
    flex: 1;
    @include padding(0 5px);

    &+& {
      margin-top: 24px;
    }

    &__title {
      @include font-style($c-assist3, 14, bold, 0.7px, 26px);
      margin-bottom: 8px;
    }

    &__text {
      @include font-style($c-black, 14, 600, 0.7px, 26px);

    }
  }
}

.copy {
  flex: none;
  width: 18px;

  &__img {
    width: 100%;
    cursor: pointer;
  }
}

@media screen and (min-width: $tablet) {
  .c-track-info {
    @include padding(0 20px);

    &__content {
      flex-direction: row;
    }
  }

  .info-desc {
    display: none;
  }

  .data-list {
    width: 100%;
    @include flex(flex-start, flex-start);

    &__item {
      &+& {
        margin-top: 0px;
      }
    }
  }
}
</style>
