<template>
  <div class="c-versions">
    <ul class="row" v-for="d of versionList" :key="d.id">
      <li class="row__item">
        <ul class="versions-info-list">
          <li class="versions-info-list__item versions-info-list__item--version-info">
            <div class="versions-info">
              <version-info :data="d" />
            </div>
          </li>
          <li class="versions-info-list__item versions-info-list__item--length">
            <div class="versions-info">
              <time-length :data="d" />
            </div>
          </li>
          <li class="versions-info-list__item versions-info-list__item--more">
            <div class="versions-info">
              <play-pause :data="d" type="mobileVersion" />
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import VersionInfo from '@/components/Global/MusicPlayer/Fragment/VersionInfo.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
// import More from '@/components/Global/MusicPlayer/Fragment/More.vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import PlayPause from '@/components/Global/MusicPlayer/Functions/PlayPause.vue';

export default defineComponent({
  name: 'versions',
  components: {
    VersionInfo,
    TimeLength,
    PlayPause,

  },

  setup() {
    const newMusicPlayerStore = useNewMusicPlayerStore();
    const versionList = computed(() => {
      return newMusicPlayerStore.versionList;
    });
    const mockData = [
      // {
      //   main: 'Full length',
      //   sub: 'Vocal Lead',
      //   length: '03:08',
      //   id: 'EN026-1',
      // },
      // {
      //   main: 'Full length',
      //   sub: 'Vocal Lead',
      //   length: '03:08',
      //   id: 'EN026-2',
      // },
      // {
      //   main: 'Full length',
      //   sub: 'Vocal Lead',
      //   length: '03:08',
      //   id: 'EN026-3',
      // },
    ];
    // const versions = computed(() => newMusicPlayerStore.versionList.map((item) => ({
    //   duration: item?.track?.duration,
    //   albumCode: item?.album?.creator,
    //   trackTitleDisplay: item?.track?.trackName,
    //   artworkInfo: item?.track?.cover,
    //   albumTitleDisplay: item?.track?.albumTitleDisplay,
    //   trackUid: item?.track?.trackUid,
    //   trackWave: item?.track?.trackWave,
    //   note: item?.album.albumCode,
    //   cover: item?.track?.cover,
    //   targetTitle: item?.track?.trackName,
    //   albumUid: item?.album?.albumUid,
    //   order: item?.track?.trackNumber,
    // })));

    return {
      mockData,
      versionList,
    };
  },
});
</script>
<style lang="scss" scoped>
.row {
  &__item {}

  // &+& {
  //   margin-top: 20px;
  // }
}

.versions-info-list {
  @include flex();

  &__item {
    width: 100%;

    &--version-info {
      flex: 1;
    }

    &--length {
      @include flex(center);
      flex: none;
      width: 45px;
    }

    &--more {
      @include flex(center);
      flex: none;
      width: 45px;
    }
  }
}
</style>
