export default {
  contactUs: '聯絡我們',
  leaveMessage: '留言給我們',
  contactInfo: '聯絡資訊',
  name: '姓名',
  appellation: '性別',
  male: '先生',
  female: '小姐',
  email: '電子信箱',
  questionType: '問題類型',
  questionContent: '問題內容',
  pleaseEnterEmail: '請輸入電子信箱',
  pleaseEnterQuestion: '請輸入問題',
  pleaseSelectQuestionType: '請選擇問題類型',
  submit: '送出',
  contact: '聯絡資訊',
  pointAddress: '105台北市松山區八德路四段165號4樓',
  quesOptAuthz: '音樂授權相關事宜',
  quesOptPick: '協助挑選音樂',
  quesOptCompose: '客製化作曲',
  quesOptInquiry: '詢價',
  quesOptOperate: '網站操作相關問題',
  quesOptOpinion: '意見回饋',
  quesOptScore: '客製化配樂',
  quesOptOther: '其他',
};
