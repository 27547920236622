<template>
  <div class="c-to-album-page function-icon" @click="handleNext">
    <img class="function-icon__img" src="@/assets/musicPlayer/playbar_album.svg" />
    <span class="function-icon__label" v-if="label">
      {{$t('myMusic.toAlbumPage')}}
    </span>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'ToAlbumPage',
  props: {
    data: {
      type: Object,
    },
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const siteStore = useSiteStore();
    const router = useRouter();

    const handleNext = () => {
      router.push({ name: 'explore-albums-info', params: { id: props.data.albumUid } });
      siteStore.toggleShowMobileMusicPlayer(false);
    };

    return {
      handleNext,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
