import encodeurl from 'encodeurl';

import defaultTrackWave from '@/assets/global/track_wave_default.png';

const imageDomainUrl = process.env.VUE_APP_IMAGE_DOMAIN;

const trackWaveSource = (src, defaultImg) => {
  if (!src) {
    return defaultImg || defaultTrackWave;
  }

  if (src.includes('http')) {
    return src;
  }

  return `${imageDomainUrl}${encodeurl(src)}`;

  // 若又有出現波形圖出不來的問題，下面兩個網址請也一併測試
  // https://howdesign.atlassian.net/browse/POINTMUSIC-1456
  // return `${imageDomainUrl}${encodeurl('point-group-public-resource/wave/CEL7024_1_Ya_no_es_Tu_Canción(Main).png')}`;
  // https://howdesign.atlassian.net/browse/POINTMUSIC-1100
  // return `${imageDomainUrl}${encodeurl('point-group-public-resource/wave/AUTH001_001_Let_Em_Loose(Full_Mix).png')}`;
};

export default trackWaveSource;
