<template>
  <div class="c-music-volume" :class="`c-music-volume--${styleType}`">
    <div class="track-volume">
      <div class="track-volume__track" v-show="styleType === 'full'">
        <p class="version-queue">

          <span class="version-queue__version" @click="handleExpand('version')"> Versions({{ versionsLength }})</span>
          <span class="version-queue__queue" @click="handleExpand('queue')">Queue({{ queueLength }})</span>
        </p>
      </div>
      <div class="track-volume__volume">
        <div class="volume">
          <img
            class="volume__icon"
            :src="`${require(`@/assets/icon/playbar_voice_${volumeLevel + 1}.svg`)}`"
            @click="handleVolumeLevel(volumeLevel)"
          />
        </div>
        <div
          class="progress-wrap"
          ref="volumeProgressBarEle"
          @click="calcProgressBarOffset"
          @mousedown="handleMouseDown"
          @mouseover="handleMouseOver"
          @mouseup="handelMouseUp"
        >
          <div class="progress-static"></div>

          <div class="progress-line" :style="{ width: `${musicVolume * 100}%` }" >
            <div class="progress-line__dot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'MusicVolume',
  props: {
    styleType: {
      type: String,
      default: 'circle',
    },
    version: {
      type: Boolean,
      default: false,
    },
    queue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:queue', 'update:version'],

  setup(props, { emit }) {
    const volumeProgressBarEle = ref(null);
    // const currentSongProgress = ref('0%');
    const isMouseDown = ref(false);
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    const volumeLevel = computed(() => {
      if (newMusicPlayerStore.volume > 0.5) return 2;
      if (newMusicPlayerStore.volume > 0) return 1;
      return 0;
    });

    const calcProgressBarOffset = (e) => {
      // 點擊位置與左邊界的 x 距離
      const { clientX } = e;
      // progressBar 資訊
      const eleInfoBox = volumeProgressBarEle.value.getBoundingClientRect();

      // 進度條與左邊界的距離
      const leftOffset = volumeProgressBarEle.value.getBoundingClientRect().left;
      // 進度條的長度
      const progressBarLength = eleInfoBox.width;
      // 點擊位置在進度條的百分比
      const volumeValue = (clientX - leftOffset) / progressBarLength;
      console.log('volumeValue', volumeValue);
      newMusicPlayerStore.setVolume(volumeValue);
      // currentSongProgress.value = `${clickPersentage * 100}%`;
    };

    const handleMouseDown = (e) => {
      isMouseDown.value = true;
      calcProgressBarOffset(e);
    };

    const handleMouseOver = (e) => {
      // 滑鼠壓著的情況下移動
      if (isMouseDown.value) {
        calcProgressBarOffset(e);
      }
    };

    const handelMouseUp = () => {
      isMouseDown.value = false;
    };

    const handleVolumeLevel = (currentLevel) => {
      console.log('currentLevel', currentLevel);
      const nextLevel = {
        1: 2,
        2: 0,
        0: 1 }[currentLevel];
      const percentage = nextLevel * 0.5;
      console.log('percentage', percentage);

      newMusicPlayerStore.setVolume(percentage);
    };

    const handleExpand = (type) => {
      if (type === 'queue') {
        emit('update:version', false);
      }

      if (type === 'version') {
        emit('update:queue', false);
      }

      emit(`update:${type}`, !props[type]);
    };

    return {
      calcProgressBarOffset,
      volumeProgressBarEle,
      // currentSongProgress,
      handleMouseDown,
      handelMouseUp,
      handleMouseOver,
      musicVolume: computed(() => newMusicPlayerStore.volume),
      // musicTrack: computed(() => newMusicPlayerStore.track),
      queueLength: computed(() => newMusicPlayerStore.queueList?.length || 0),
      versionsLength: computed(() => newMusicPlayerStore.versionList?.length || 0),
      handleVolumeLevel,
      handleExpand,
      volumeLevel,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-music-volume {
  @include flex();
  color: $c-white;
}

.track-volume {
  @include flex();

  &__track {
  }

  &__volume {
    margin-left: 28px;
    @include flex();
  }
}

.version-queue {
  &__version {
    @include font-style($c-assist3, 14);
    cursor: pointer;
  }

  &__queue {
    @include font-style($c-assist3, 14);
    cursor: pointer;
    margin-left: 10px;
  }
}

.volume {
  width: 18px;

  &__icon {
    width: 100%;
    vertical-align: bottom;
    cursor: pointer;
  }
}

.progress-wrap {
  margin-left: 16px;
  @include flex(center);
  height: 18px;
  cursor: pointer;
  width: 120px;
  position: relative;
}

.progress-static {
  background-color: $c-text1;
  height: 1px;
  width: 120px;
  @include position(center);
}

.progress-line {
  background-color: $c-main;
  height: 2px;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &__dot {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include circle(1px);
    background-color: $c-main;
    transition: 0.3s;
  }
}

.progress-wrap {
  &:hover {
    .progress-line__dot {
      right: -3px;
      @include circle(6px);
    }
  }
}

.c-music-volume--simple {
  .track-volume {
    @include flex();

    &__track {
      margin-left: 20px;
      order: 2;
    }

    &__volume {
      margin-left: 0px;
      order: 1;
    }
  }

  .version-queue {
    &__version {
      @include font-style($c-assist4, 14);
    }

    &__queue {
      @include font-style($c-assist4, 14);
      margin-left: 10px;
    }
  }
}
</style>
