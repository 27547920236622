import graphqlRequest from '@/api/graphql';
import restfulRequest from '@/api/axios';
import cusStorage from '@/utils/cusStorage';

const baseURL = process.env.VUE_APP_BASE_API;

// 先嘗試從 local 取，若沒有再從 session 取
const tokenValue = () => cusStorage.getItem('token') || cusStorage.getItem('token', true);

const gqlRequest = graphqlRequest.create({
  baseURL: `${baseURL}/graphql`,
  descriptor: false,
  auth: false,
  getToken: tokenValue,
});

const restfulReq = restfulRequest.create({
  baseURL,
  descriptor: false,
  auth: false,
  getToken: tokenValue,
});

export default gqlRequest;
export { restfulReq };
