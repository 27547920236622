import { defineStore } from 'pinia';

export const useMyMusicStore = defineStore({
  id: 'myMusic',
  state: () => ({
    listFilter: [],
    accountPlaylistList: {
      trackList: [],
      albumList: [],
    },
    playlists: {
      listFilter: [],
    },
    'search-history': {
      listFilter: [],
    },
    'play-history': {
      listFilter: [],
    },
    'download-history': {
      listFilter: [],
    },
    'my-note': {
      listFilter: [],
    },
    'search-tracks': {
      listFilter: [],
    },
    'search-albums': {
      listFilter: [],
    },
    'search-playlists': {
      listFilter: [],
    },
  }),

  actions: {
    setListFilter(listFilter) {
      this.listFilter = listFilter;
    },
    async setAccountPlaylistList(type, data) {
      this.accountPlaylistList[type] = data;
    },

    // setListFilter(pageName, listFilter) {
    //   const formatPageName = pageName.replace('my-music-', '');
    //   if (this[formatPageName]) {
    //     this[formatPageName].listFilter = listFilter;
    //   }
    // },
  },
});

export const a = '';
