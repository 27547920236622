export default () => {
  const getFileName = (disposition) => {
    let filename = '';
    if (disposition && disposition.indexOf('inline') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    return filename;
  };

  const fileDownload = (res, disposition) => {
    const fileName = getFileName(disposition);
    const decodedFileName = decodeURIComponent(fileName);

    const targetUrl = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = targetUrl;
    link.setAttribute('download', decodedFileName);
    document.body.appendChild(link);
    link.click();
  };

  return {
    fileDownload,
  };
};
