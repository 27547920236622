<template>
  <div class="c-cover-name-code" :class="{
    'c-cover-name-code--off-shelf': offShelfConfig.used && !isOnShelfStatus,
  }">
    <router-link class="c-cover-name-code__img"
    :to="{path: `/explore/albums-info/${data.albumUid || data.url || data.track?.album?.albumUid || data.album?.albumUid}`}"
    >
      <div class="c-cover-name-code__img__mask"></div>
      <img class="c-cover-name-code__img__icon" src="@/assets/mobile/icon_menu_info_red.svg" />
      <img
        class="c-cover-name-code__img__cover"
        :src="
          imageSource(
            data.cover ||
              trackData.artworkInfo ||
              data.track?.artworkInfo ||
              data.album?.artworkInfo ||
              data.artworkInfo,
          )
        "
      />

    </router-link>

     <p class="c-cover-name-code__info">
        <span class="c-cover-name-code__info__name">{{
          data.targetTitle ||
          trackData.trackTitleDisplay ||
          data.track?.trackTitleDisplay ||
          data.album?.albumTitleDisplay ||
          data.title ||
          data.albumTitleDisplay ||
          data.name ||
          ''
        }}</span>
        <span class="c-cover-name-code__info__code" v-if="showCode"
          >{{ data.note || trackData.albumCode || data.track?.albumCode || data.album?.albumCode || data.albumCode
          }}{{ trackNumber }}</span
        >
      </p>
  </div>
</template>
<script>
// 與 CoverNameCode 差別在於沒有圓角，和 margin 負值
import { defineComponent, computed } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import imageSource from '@/utils/imageSource';
import useIsOffShelf from '@/hooks/useIsOffShelf';
// import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'CoverNameCodeNormal',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    showCode: {
      type: Boolean,
      default: true,
    },
    offShelfConfig: {
      type: Object,
      default: () => ({
        used: false, // 是否要顯示下架資訊
        type: 'TRACK',
      }),
    },
  },
  setup(props) {
    // const musicPlayerStore = useMusicPlayerStore();
    // const newMusicPlayerStore = useNewMusicPlayerStore();
    const { isOnShelfStatus } = useIsOffShelf(props);

    const trackData = computed(() => {
      if (props.data?.tracks) {
        return props.data.tracks[0];
      }

      return {};
    });

    const trackNumber = computed(() => {
      const trackN =
        props.data.order || trackData.value.trackNumber || props.data.track?.trackNumber || props.data.trackNumber;

      return trackN ? `-${trackN}` : '';
    });

    return {
      imageSource,
      trackData,
      // info: computed(() => musicPlayerStore.info),
      trackNumber,
      isOnShelfStatus,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/pullFromShelf.scss';
.c-cover-name-code {
  @include flex();

  &--off-shelf {
    .c-cover-name-code__img {
      position: relative;

      &__mask {
        display: block;

        &::after {
          content: '';
          @include position(center);
          width: 100%;
          height: 100%;
          display: inline-block;
          background-color: rgba($c-black, 0.5);

        }
      }

      &__icon {
        display: inline-block;
        width: 20px;
        @include position(center);
      }
    }
  }

  &__img {

    &__mask {
      display: none;
    }

    &__icon {
      display: none;
    }

    &__cover {
      width: 36px;
      height: 36px;

    }
  }

  &__info {
    margin-left: 16px;
    @include flex(flex-start, flex-start, column);

    &__name {
      width: 100%;
      text-align: left;
      @include font-style($c-assist6, 14, bold, 0.8px, 18px);
      @include word-ellipsis(1);
    }

    &__code {
      text-align: left;
      @include font-style($c-assist4, 14, 400, 0.8px, 18px);
      @include word-ellipsis(1);
    }
  }
}
</style>
