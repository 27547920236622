<template>
  <div class="c-music-controll" :class="`c-music-controll--${styleType}`">
    <div class="player-btns">
      <div class="player-btns__item player-btns__item__prev" v-if="styleType !== 'mobile'">
        <prev />
      </div>
      <div class="player-btns__item player-btns__item--play-pause">
        <play-pause color="white" type="musicPlayer"/>
      </div>
      <div class="player-btns__item player-btns__item--next">
        <next />
      </div>
    </div>

    <music-progress :styleType="styleType" v-show="styleType !== 'mobile'" />
  </div>
</template>
<script>
import { defineComponent,
  // computed
} from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
// import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import MusicProgress from '@/components/Global/MusicPlayer/MusicProgress.vue';
import PlayPause from '@/components/Global/MusicPlayer/Functions/PlayPause.vue';
import Prev from '@/components/Global/MusicPlayer/Functions/Prev.vue';
import Next from '@/components/Global/MusicPlayer/Functions/Next.vue';

export default defineComponent({
  name: 'MusicControll',
  components: {
    MusicProgress,
    PlayPause,
    Prev,
    Next,
  },
  props: {
    styleType: {
      type: String,
      default: 'circle',
    },
  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();
    // const newMusicPlayerStore = useNewMusicPlayerStore();

    // const handlePlayStatus = (status) => {
    //   musicPlayerStore.handlePlayStatus(status);
    // };
    return {
      // musicControll: computed(() => musicPlayerStore.controll),
      // handlePlayStatus,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-music-controll {
  @include flex(center, center);
  width: 100%;
  color: $c-white;
}

.player-btns {
  @include flex();
  flex: none;

  &__item {
    width: 18px;
    vertical-align: bottom;
    cursor: pointer;

    & + & {
      margin-left: 35px;
    }
  }

  &--prev {
  }

  &--play {
  }

  &--next {
  }
}

.music-progress {
  @include flex(center, center);
  flex: 1;
  margin-left: 30px;

  &__current-time {
    @include font-style($c-white, 14);
    flex: none;
  }

  &__wrap {
    flex: 1;
    margin: 0 8px;
  }

  &__total-time {
    @include font-style($c-white, 14);
    flex: none;
    width: auto;
    text-align: right;
  }
}

.progress-wave {
  &__box {
    width: 100%;
    position: relative;
    left: -1;
    background-color: $c-assist3;

    &__moving {
      height: 56px;
      background-color: $c-main;
    }

    &__img {
      @include position(tl, 0, 0);
      width: 100%;
      height: 56px;
      overflow: hidden;
      vertical-align: middle;
    }
  }
}

.progress-wrap {
  @include flex(center);
  height: 24px;
  cursor: pointer;
  position: relative;
}

.progress-static {
  background-color: $c-text1;
  height: 1px;
  width: 100%;
  background-color: #fff;
  @include position(center);
}

.progress-line {
  background-color: $c-main;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &__dot {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include circle(1px);
    background-color: $c-main;
    transition: 0.3s;
  }
}

.progress-wrap {
  &:hover {
    .progress-line__dot {
      right: -3px;
      @include circle(6px);
    }
  }
}

.c-music-controll--simple {
}
</style>
