<template>
  <div class="c-remove" @click="removeTrack" v-if="showRemoveBtn">
    <img class="c-remove__img" src="@/assets/myMusic/icon_remove.svg">
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'Remove',
  props: {
    data: {
      type: Object,
    },
  },
  setup(props) {
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    const showRemoveBtn = computed(() => {
      if (newMusicPlayerStore.currentPlayTrackInfo?.track?.trackUid === props.data?.track?.trackUid) return false;
      return true;
    });

    const removeTrack = () => {
      newMusicPlayerStore.removeFromQueueList(props.data.track.trackUid);
    };
    return {
      showRemoveBtn,
      removeTrack,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-remove {
  width: 100%;
  cursor: pointer;
  @include flex(center);
}
</style>
