export default {
  loginSuccess: 'Login Succeed',
  validateSuccess: 'Email verify succeed!',
  resetPwdSuccess: 'Reset password Succeed!',
  registerSuccess: 'Register Succeed',
  removeConfirm: 'Are you sure to remove?',
  logoutConfirm: 'Are you sure to logout?',
  removeSuccess: 'Remove Succeed',
  addSuccess: 'Add Succeed',
  createSuccess: 'Create Succeed',
  addNoteSuccess: 'Add Note Succeed',
  updateNoteSuccess: 'Update Note Succeed',
  saveSuccess: 'Save Succeed',
  removeNoteSuccess: 'Remove Note Succeed',
  copySuccess: 'Copy Succeed!',
  copyFail: 'Copy Fail!',
};
