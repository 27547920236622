import request from '@/api';
import {
  getAccountPlaylistList,
  getAccountNoteList,
  createAccountPlaylist,
  addToAccountPlaylist,
  getAccountPlaylistAll,
  getAccountPlaylist,
  removeAccountPlaylist,
  removeAccountPlaylistContent,
  getAccountFavourite,
  addAccountFavouriteContent,
  removeAccountFavouriteContent,
  updateAccountPlaylist,
  getAccountPlaylistHistoryList,
  getAccountPlaylistContentTotal,
  addMultiToAccountPlaylist,
  changeContentSorter,
} from '@/api/gql/myMusicPlaylists.gql';

export const addMultiToAccountPlaylistAPI = async (payload) => {
  const res = await request(addMultiToAccountPlaylist, payload);

  return res;
};

export const getAccountPlaylistContentTotalAPI = async (payload) => {
  const res = await request(getAccountPlaylistContentTotal, payload);

  return res;
};

export const updateAccountPlaylistAPI = async (payload) => {
  const res = await request(updateAccountPlaylist, payload);

  return res;
};

export const getAccountPlaylistHistoryListAPI = async (payload) => {
  const res = await request(getAccountPlaylistHistoryList, payload);

  return res;
};

export const getAccountFavouriteAPI = async (payload) => {
  const res = await request(getAccountFavourite, payload);

  return res;
};

export const getAccountPlaylistListAPI = async (payload) => {
  const res = await request(getAccountPlaylistList, payload);

  return res;
};

export const getAccountPlaylistAllAPI = async (payload) => {
  const res = await request(getAccountPlaylistAll, payload);

  return res;
};

// 建立我的歌單清單的項
export const createAccountPlaylistAPI = async (payload) => {
  const res = await request(createAccountPlaylist, payload);

  return res;
};

export const addToAccountPlaylistAPI = async (payload) => {
  const res = await request(addToAccountPlaylist, payload);

  return res;
};

export const getAccountNoteListAPI = async (payload) => {
  const res = await request(getAccountNoteList, payload);

  return res;
};

export const getAccountPlaylistAPI = async (payload) => {
  const res = await request(getAccountPlaylist, payload);

  return res;
};

export const removeAccountPlaylistAPI = async (payload) => {
  const res = await request(removeAccountPlaylist, payload);

  return res;
};

export const removeAccountPlaylistContentAPI = async (payload) => {
  const res = await request(removeAccountPlaylistContent, payload);

  return res;
};

export const addAccountFavouriteContentAPI = async (payload) => {
  const res = await request(addAccountFavouriteContent, payload);

  return res;
};

export const removeAccountFavouriteContentAPI = async (payload) => {
  const res = await request(removeAccountFavouriteContent, payload);

  return res;
};

export const changeContentSorterAPI = async (payload) => {
  const res = await request(changeContentSorter, payload);

  return res;
};
