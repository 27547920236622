<template>
<div class="c-prev function-icon">
  <img src="@/assets/icon/playbar_prev_white.svg" @click="handlePrev" v-if="isWhite"/>
  <img src="@/assets/icon/playbar_prev.svg" @click="handlePrev" v-else/>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { debounce } from 'lodash-es';
import { useMessage } from 'naive-ui';
import { useI18n } from 'vue-i18n';
// import useGetTrackFile from '@/hooks/useGetTrackFile';
import { useSiteStore } from '@/store/site';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
// import useMusicPlayer from '@/hooks/useMusicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'Prev',
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const siteStore = useSiteStore();
    // const musicPlayerStore = useMusicPlayerStore();
    const message = useMessage();
    // const { getTrackFileUrl } = useGetTrackFile();
    // const { playMusic } = useMusicPlayer();
    const { t } = useI18n();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    // const getTrackUrlAndPlay = async (variables) => {
    //   const fileUrl = await getTrackFileUrl(variables);
    //   musicPlayerStore.setMusicFile(fileUrl);
    //   playMusic(musicPlayerStore.info);
    // };

    const handlePrev = debounce(() => {
      if (!siteStore.isLogin) {
        message.error(t('auth.registerForFullFeatures'));
        return;
      }
      newMusicPlayerStore.playPrevTrack();
    }, 300);

    return {
      handlePrev,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
