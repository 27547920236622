<template>
  <p class="c-id" :class="{ 'c-id--pr': pr, 'c-id--white': color === 'white' }">
    {{ data.albumCode || data.album?.albumCode || data.track?.albumCode }}{{ trackNumber }}
  </p>
</template>
<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'Id',
  props: {
    data: {
      type: Object,
      default: () => { },
    },
    // right-padding
    pr: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const trackNumber = computed(() => {
      if (props.data?.trackNumber) {
        return `-${props.data.trackNumber}`;
      }

      if (props.data?.track?.trackNumber) {
        return `-${props.data.track.trackNumber}`;
      }

      return '';
    });

    return {

      trackNumber,

    };
  },
});
</script>
<style lang="scss" scoped>
.c-id {
  @include font-style($c-assist4, 14, 400, 0.7px, 26px);
  @include word-ellipsis(2, 'true');

  &--pr {
    @include padding(0 30px 0 0);

  }

  &--white {
    color: $c-white;
  }

}

@media screen and (min-width: $tablet) {
  .c-id {
    color: $c-black;
    // @include padding(0 10px 0);

    &--white {
      color: $c-white;
    }
  }
}
</style>
