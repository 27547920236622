<template>
  <div class="c-add">
    <div class="create-field">
      <div class="create-field__box">
        <input class="create-field__box__input" :placeholder="$t('myMusic.addNewPlaylist')" v-model="playlistName"
          @keydown.stop />
        <button class="add-btn show-tablet" @click="add">
          <img class="add-btn__icon" src="@/assets/searchResults/icon_popup_playlist_add_2.svg" />
          <span class="add-btn__text">{{ $t('myMusic.add') }}</span>
        </button>
      </div>

      <div class="mobile-btn-wrap">
        <mi-button icon="add">
          <span class="mobile-add-btn-text" @click="add">{{ $t('myMusic.add') }}</span>
        </mi-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, watch, ref, inject } from 'vue';
// import { useMessage } from 'naive-ui';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
  createAccountPlaylistAPI,
  // getAccountPlaylistAllAPI,
  // addToAccountPlaylistAPI,
} from '@/api/myMusicPlaylists';
import useRequest from '@/hooks/useRequest';
import { firstLetterUpperCase } from '@/utils/firstLetterUpperCase';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'AddNewPlaylist',
  components: {
    MiButton,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    type: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(_, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const type = firstLetterUpperCase(`${route.query.type.toLowerCase()}`);
    // const message = useMessage();
    const playlistName = ref('');
    const playlistList = ref([]);

    const { request, response } = useRequest(createAccountPlaylistAPI);
    // const { request: getAccountPlaylistAllRequest, response: getAccountPlaylistAllResponse } = useRequest(getAccountPlaylistAllAPI);
    // const { request: addAccountPlaylistContentRequest, response: addAccountPlaylistContentResponse } = useRequest(addToAccountPlaylistAPI);

    const add = () => {
      if (!playlistName.value) {
        console.log(3);
        window.$message.error(t('global.pleaseEnterName'));
        console.log(4);
        return;
      }

      request({
        in: {
          name: playlistName.value,
          type,
        },
      });
    };

    // getAccountPlaylistAllRequest({
    //   in: {
    //     keyword: '',
    //     type,
    //   },
    // });

    // const addIntoCurrPlaylist = (playlistId) => {
    //   const typeMapping = {
    //     TRACK: 'trackUid',
    //     PLAYLIST: 'playlistUid',
    //     ALBUM: 'albumUid',
    //   };

    //   addAccountPlaylistContentRequest({
    //     in: {
    //       accountPlaylistUid: playlistId,
    //       originUid: props.data[typeMapping[props.type]],
    //       type,
    //     },
    //   });
    // };

    const reloadAccountAfterCreateNewPlaylist = inject('reloadAccountAfterCreateNewPlaylist');

    watch(response, () => {
      const [err, res] = response.value;

      if (err) {
        // message.error(err.message);
        return;
      }

      if (res.createAccountPlaylist) {
        playlistName.value = '';
        window.$message.success(t('msg.createSuccess'));
        reloadAccountAfterCreateNewPlaylist();

        emit('close');
        // getAccountPlaylistAllRequest({ in: { keyword: '', type } });
      }
    });

    // watch(getAccountPlaylistAllResponse, () => {
    //   const [, res] = getAccountPlaylistAllResponse.value;

    //   const tempList = res.getAccountPlaylistAll || [];
    //   playlistList.value = tempList.map((item) => ({
    //     id: item.accountPlaylistUid,
    //     title: item.name,
    //   }));
    // });

    // watch(addAccountPlaylistContentResponse, () => {
    //   const [, res] = addAccountPlaylistContentResponse.value;
    //   console.log('res...', res);

    //   if (res) {
    //     message.success('新增成功');
    //   } else {
    //     message.error('新增失敗');
    //   }
    // });

    return {
      playlistName,
      playlistList,
      add,
      // addIntoCurrPlaylist,
    };
  },
});
</script>
<style lang="scss" scoped>
.playlist {
  height: 300px;
  overflow: auto;
}

.playlist-list {
  &__item {
    @include padding(0 24px);
    @include flex();
    height: 55px;
    cursor: pointer;

    &:hover {
      background-color: $c-assist1;

      .item-box__icon {
        display: inline-block;
      }
    }
  }
}

.item-box {
  width: 100%;
  @include flex();

  &__title {
    flex: 1;
    @include font-style($c-assist6, 14, normal, 0.8px, 22px);
  }

  &__icon {
    display: none;
    flex: none;
    width: auto;
    margin-left: 10px;
  }
}

.create-field {
  @include padding(0 24px);
  margin: 0 24px;
  // border-top: 1px solid $c-assist7;

  &__box {
    margin-top: 20px;
    @include flex();
    @include padding(0px 24px);
    height: 40px;
    border-radius: 20px;
    background-color: $c-assist1;

    &__input {
      flex: 1;
      border: 0 transparent;
      outline: 0 transparent;
      background-color: transparent;
    }
  }
}

.add-btn {
  flex: none;
  width: auto;
  @include flex();
  border: 0 transparent;
  outline: 0 transparent;
  cursor: pointer;
  background-color: transparent;

  &__icon {
    width: 14px;
  }

  &__text {
    margin-left: 10px;
    @include font-style($c-main, 14, normal);
  }
}

.mobile-btn-wrap {
  margin-top: 15px;
  @include flex(center);
}

.show-tablet {
  display: none
}

.mobile-add-btn-text {
  margin-left: 10px;
}

@media screen and (min-width: $portrait) {
  .mobile-btn-wrap {
    display: none;

  }

  .show-tablet {
    display: block;
  }
}
</style>
