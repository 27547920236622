import {
  ref, createApp, h,
} from 'vue';

import { useI18n } from 'vue-i18n';
import AsyncDialog from '@/components/Other/AsyncDialog.vue';
import ErrorInfo from '@/components/Other/ErrorInfo.vue';
import SuccessInfo from '@/components/Other/SuccessInfo.vue';
import AnnouncementInfo from '@/components/Other/AnnouncementInfo.vue';

export default () => {
  const { t } = useI18n();
  const genPopInfo = (contentComponent, { desc, callback, isOverlayUseCallback, showClose, data = {} }) => new Promise((resolve) => {
    const app = createApp({
      setup() {
        const visible = ref(true);
        const closeSubDialog = (status) => {
          visible.value = false;
          resolve(status);
        };
        return () => h(
          AsyncDialog,
          {
            visible: visible.value,
            'onUpdate:visible': closeSubDialog,
            callback,
            isOverlayUseCallback,
            showClose,
            cancelBtnText: t('global.cancel'),
            confirmBtnText: t('global.confirm'),
          },
          h(contentComponent, {
            msg: desc,
            data,
          }),
        );
      },
    });

    const root = document.createElement('div');
    document.body.appendChild(root);

    return {
      instance: app.mount(root),
      unmount() {
        app.unmount(root);
        document.body.removeChild(root);
      },
    };
  });

  const popInfoWrap = (type) => {
    const msgTypeComponent = {
      error: ErrorInfo,
      success: SuccessInfo,
      announcement: AnnouncementInfo,
    };

    return (args) => genPopInfo(msgTypeComponent[type], args);
  };

  return {
    successPopInfo: popInfoWrap('success'),
    errorPopInfo: popInfoWrap('error'),
    announcementPopInfo: popInfoWrap('announcement'),
  };
};
