const exploreType = {
  themes: 'Themes',
  moods: 'Moods',
  genres: 'Music Styles',
  playlists: 'Playlists',
  albums: 'Albums',
  labels: 'Labels',
};

export default {
  leaveMessageSuccess: 'Submit Message Succeed',
  submitSuccess: 'Submit Succeed',
  test: 'Test',
  aboutPoint: 'About Point',
  contactUs: 'Any questions? Contact us ...',
  exploreType,
  fullMatch: 'Full Match',
  keywordPlaceholder: 'Please enter keyword...',
  learnMore: 'See All in Search',
  menulist: {
    explore: 'Explore',
    licensing: 'License',
    contact: 'Contact us',
  },
  noData: 'No Data',
  pagination: {
    prev: 'Prev',
    next: 'Next',
    to: 'To',
    of: 'of',
  },
  rights: 'Copyright by Point Culture Co., Ltd.',
  searchPlaceholder: 'Keyword, Style, Mood…',
  siteMap: {
    explore: {
      title: 'Explore',
      sub: exploreType,
    },
    'my-profile': {
      title: 'Member',
      sub: {
        'my-profile': 'Members Only',
        playlists: 'Project Music Selection',
        'search-history': 'Search History',
        'play-history': 'Played History',
        'download-history': 'Download History',
        'preview-tool': 'Preview Tool',
      },
    },
    licensing: {
      title: 'Legal Notices',
      sub: {
        terms: 'Terms of Use',
        privacy: 'Privacy Policy',
        cookies: 'COOKIES Policy',
      },
    },
    other: {
      title: 'About',
      sub: {
        licensing: 'License',
        aboutUs: 'About Us',
        contact: 'Contact Us',
      },
    },
  },

  // form
  save: 'Save',
  email: 'Email',
  pleaseEntryEmail: 'Please enter Email',
  pleaseEnterCorrectEmailFormat: 'Please enter correct email format',
  name: 'Name',
  pleaseEnter: 'Please enter name',
  appellation: 'Gender',
  male: 'Male',
  female: 'Female',
  mobile: 'Mobile',
  pleaseEnterMobile: 'Please enter mobile',
  tel: 'Phone',
  pleaseEnterPhone: 'Please enter phone',
  phoneFormatErrorMsg: 'Please enter correct phone format',
  city: 'City',
  pleaseSelectCountry: 'Please select country',
  cityCounty: 'County/Area',
  pleaseSelectCityCounty: 'Please select county/area',
  businessType: 'Type of business',
  pleaseEnterBusinessType: 'Please select type of business',
  position: 'Position',
  pleasePosition: 'Please enter Position',
  companyName: 'Company name',
  pleaseEnterCompanyName: 'Please enter company name',
  companyCountry: 'Company country',
  pleaseEntercompanyCountry: 'Please enter company country',
  companyCity: 'Company city/area',
  pleaseEnterCompanyCity: 'Please enter city/area',
  companyAddress: 'Company address',
  pleaseEnterCompanyAddress: 'Please enter company address',
  country: 'Country',
  pwPlacehodler: 'Please enter alphabet, numbers, sign with 8 to 20 letters',
  emailBox: 'Email',
  emailBoxPlaceholder: 'Please enter email',
  nationality: 'Nationality',
  pleaseSelectNationality: 'Please enter nationality',
  password: 'Password',
  passwordPlaceholder: 'Please enter password',
  oldPw: 'Old password',
  newPw: 'New Password',
  confirmNewPw: 'Confirm new password ',
  confirmPw: 'Confirm new password',
  submit: 'Submit',
  pwdRuleMsg: 'Please use alphabet, numbers, sign {specialCharactor}',
  pleaseEnterName: 'Please Name',
  notAllowSameAsOldPwd: 'Please enter a new password which not same as old one',
  pwdNotConsist: 'Passwords is not matched',

  // 驗證錯誤訊息
  errPhoneFormat: 'Phone formation error.',

  // other
  addSuccess: 'Add Succeed',
  cancel: 'Cancel',
  all: 'All',
  confirm: 'Confirm',
  menu: {
    myProfile: 'MY ACCOUNT',
    explore: 'EXPLORE',
    licensing: 'LICENSING',
    contact: 'CONTACT',
    aboutUs: 'ABOUT US',
    myMusic: 'MEMBERS ONLY',
  },
  myMusic: 'MEMBERS ONLY',
  unexpcetedError: 'Unexpected Error',
  defaultErrorMsg: "It's not in serve",
  sortBy: {
    desc: 'Time(From new to old)',
    asc: 'Time(From old to new)',
    name: 'Name(A-Z)',
    albumcode: 'Album Code(Descendant)',
  },
  albums: 'Album',
  pointCulture: 'Point',
  breadcrumb: {
    home: 'Home',
    explore: 'Explore',
  },
  edit: 'Edit',
  back: 'Back',
  startPlaying: 'Start Playing',
  switchDisplayMode: 'Switching Display Modes',
  downloadCompressing: 'The file is being compressed, please wait for the download to complete.',
  dontShowAgain: 'Don\'t show again.',
  version: 'Version',
  draggbleSort: 'Sort by dragging',
  copyTrackInfo: 'Copy Track Info',
  copyLyrics: 'Copy Lyrics',
  removeFilter: 'Remove Filter',
};
