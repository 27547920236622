export default {
  accountData: 'Account Details',
  changePw: 'Change Password ',
  preferSetting: 'Preference ',
  pointClient: 'Customer Service',
  memberType: 'Type of Member',
  downloadAuth: 'Limits of Authority',
  downloadCount: 'Downloaded/Available',
  contractPeriod: 'Contract Period',
  emailNotice: 'Email would be used for your login account. If you have any further requirements, please contact us.',
  verified: 'Verified',
  unverify: 'Unverified',
  sendVerifyEmail: 'Send verification email.',
  verifyEmailSent: 'Verification Email was sent.',
  accountModifySuccess: 'Account Updated Succeed.',
  changePwSuccess: 'Change Password Succeed.',
  defaultDownloadExt: 'Download Format.',
  searchResultPrefer: 'Search Result Preference.',
  wave: 'Waves',
  desc: 'Description',
  tag: 'Tags',
  csNotice1: 'If you have any questions. Please contact the customer service below. Or link to ',
  csNoticeContact: 'Contact Us ',
  csNotice2: 'Leave your question, we will contact you as soon as possible!',
  cs: 'Customer Service',
  saveSuccess: 'Save succeed.',
  notSupportMobile: 'We are not support mobile devices.',
  modify: 'Edit',
  formerMember: 'Former Member',
  normalMember: 'Member',
  inReview: 'In Review',
  unverified: 'Unverified',
  waitForAudit: 'Waiting for verification',
  fileLimit1mb: 'File limitation: 1MB',
  updateAvatarSuccess: 'Update avatar succeed.',
  myAccount: 'My Account',
  downloadPeriod: 'Download Period',
  unlimited: 'Unlimited',
  askingDownloadFormat: 'Asking Download Format',
  downloadAlbum: 'Download Album',
  downloadPlaylist: 'Download Playlist',
  downloadTrack: 'Download Track',
  selectDownloadFormat: 'Please select download format.',
  downloadFormat: 'Download format',
  musicPlayerStyle: 'Player default style',
  musicPlayerStyleFull: 'Full',
  musicPlayerStyleSimple: 'Compact',
  musicPlayerStyleCircle: 'Symbol',
};
