export default {
  keepLogin: 'Keep login',
  forgotPassword: 'Forgot password?',
  noAccount: 'Don’t have an account?',
  forgetPwd: 'Forgot password',
  alreadyAMember: 'Already a member?',
  resetEmailSentTo: 'We had sent the reset email to',
  linkExpired: 'The link was expired',
  home: 'home',
  isValidating: 'Validating',
  loginAndSendAgainMsg: 'Please login your account and send the verification email again!',
  validateLinkExpired: 'Validating link was expired',
  resetPwd: 'Reset password',
  siteIntro: "As a Point member, you're allowed to:,",
  siteIntroDesc1: 'Previewing over one million music online.',
  siteIntroDesc2: 'Creating your own playlists and sharing others.',
  siteIntroDesc3: 'Integrating your video with music by preview tool.',
  byRegistering: 'I agree.',
  termsOfUse: 'Terms of Use',
  and: 'and',
  privacyPolicy: 'Privacy Policy',
  valiateEmailSentTo: 'The verification email was sent to ',
  emailIsAccount: 'Email would be used for your login account.',
  sendValidateLinkToEmail: 'The verification link was sent to  your email.',
  clickForCompleteSignup: 'Completing registration by clicking the link in your email.',
  signin: 'Sign in',
  signup: 'Sign up',
  registerForFullFeatures: 'Register For Full Features',
};
