<template>
  <div class="c-add-playlists-btn" >
  <tooltip :label="$t('myMusic.addToProject')">
    <mi-button icon="add" @click="addToPlaylist">
      <!-- <span class="add-text">Add playlist</span> -->
    </mi-button>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <model-base-layout :title="$t('myMusic.createProject')" decoLine>
        <add-all @close="modelStatus = false"/>
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NModal } from 'naive-ui';
import MiButton from '@/components/Global/MiButton.vue';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import AddAll from '@/components/Global/Popup/AddAll.vue';
// import AddNewPlaylist from '@/components/Global/Popup/AddNewPlaylist.vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'AddAllToPlaylistBtn',
  components: {
    MiButton,
    NModal,
    ModelBaseLayout,
    AddAll,
    // AddNewPlaylist,
    Tooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const modelStatus = ref(false);

    const addToPlaylist = () => {
      console.log('123');
      modelStatus.value = true;
    };

    return {
      modelStatus,
      addToPlaylist,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-add-playlists {
  & + & {
    margin-left: 12px;
  }
}
.add-icon {
  width: 15px;
}

.add-text {
  margin-left: 9px;

}
</style>
