export default [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "LayoutWithHeader" */ '@/layout/LayoutWithHeader.vue'),
    children: [

      {
        path: 'sign-in',
        name: 'sign-in',
        component: () => import(/* webpackChunkName: "SignIn" */ '@/views/Auth/SignIn.vue'),
        meta: {
          title: 'Sign In',
          layout: {
            siteMap: false,
          },
        },
      },
      {
        path: 'sign-up',
        name: 'sign-up',
        component: () => import(/* webpackChunkName: "SignUp" */ '@/views/Auth/SignUp.vue'),
        meta: {
          title: 'Sign Up',
          layout: {
            siteMap: false,
          },
        },
      },
      {
        path: 'password-recovery',
        name: 'password-recovery',
        component: () => import(/* webpackChunkName: "PasswordRecovery" */ '@/views/Auth/PasswordRecovery.vue'),
        meta: {
          title: '重設密碼',
          layout: {
            siteMap: false,
          },
        },
      },
      {
        path: 'validate',
        name: 'validate',
        component: () => import(/* webpackChunkName: "Validate" */ '@/views/Auth/Validate.vue'),
        meta: {
          title: '郵箱驗證',
          layout: {
            siteMap: false,
          },
        },
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/Auth/ResetPassword.vue'),
        meta: {
          title: '重設密碼',
          layout: {
            siteMap: false,
          },
        },
      },
    ],
  },
];
