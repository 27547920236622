import { h } from 'vue';
import TitleWithFunction from '@/components/Global/MusicPlayer/Fragment/TitleWithFunction.vue';
// import Avatar from '@/components/Global/MusicPlayer/Fragment/Avatar.vue';
// import Track from '@/components/Global/MusicPlayer/Fragment/Track.vue';
// import Remove from '@/components/Global/MusicPlayer/Fragment/Remove.vue';
// import ModifyDate from '@/components/Global/MusicPlayer/Fragment/ModifyDate.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
import Id from '@/components/Global/MusicPlayer/Fragment/Id.vue';

export const functionConfig = {
  addPlaylistsBtn: {
    visible: true,
  },
  downloadBtn: {
    visible: true,
  },
};

export const listConfig = {
  columns: [
    {
      type: 'label',
      label: 'TITLE',
      i18nKey: 'myMusic.title',
      align: 'left',
      key: 'playlistsName',
      render: h(TitleWithFunction, {
        functionList: ['download', 'share'],
        playType: 'version',
      }),
    },
    {
      type: 'label',
      label: 'LENGTH',
      i18nKey: 'myMusic.length',
      width: '100px',
      align: 'left',
      render: h(TimeLength, {
        color: 'white',
      }),
    },
    {
      type: 'label',
      label: 'ID',
      i18nKey: 'myMusic.id',
      width: '200px',
      align: 'left',
      key: 'id',
      render: h(Id, {
        color: 'white',
      }),
    },

    // {
    //   type: 'checkbox',
    //   label: 'C',
    //   width: '60px',
    //   align: 'center',
    //   key: 'checkbox',
    // },
  ],
};

export const contentData = [
  // {
  //   playlistsName: '加入收藏',
  //   owner: 'vicky',
  //   track: 10,
  //   modifyDate: '27 Aug 2021',
  //   remove: 'x',
  //   length: '03:17',
  //   id: 'MAT305-5',
  //   title: '30 Sec',
  //   titleExtra: 'Instrumental',
  // },
  // {
  //   playlistsName: '哈哈',
  //   name: 'becky',
  //   track: 3,
  //   modifyDate: '27 Aug 2021',
  //   remove: 'x',
  //   length: '03:17',
  //   id: 'MAT305-5',
  //   title: '15 Sec',
  //   titleExtra: 'Instrumental',
  // },
  // {
  //   playlistsName: '哈哈',
  //   name: 'becky',
  //   track: 3,
  //   modifyDate: '27 Aug 2021',
  //   remove: 'x',
  //   length: '03:17',
  //   id: 'MAT305-5',
  //   title: '15 Sec',
  //   titleExtra: 'Instrumental',
  // },
  // {
  //   playlistsName: '哈哈',
  //   name: 'becky',
  //   track: 3,
  //   modifyDate: '27 Aug 2021',
  //   remove: 'x',
  //   length: '03:17',
  //   id: 'MAT305-5',
  //   title: '15 Sec',
  //   titleExtra: 'Instrumental',
  // },
  // {
  //   playlistsName: '哈哈',
  //   name: 'becky',
  //   track: 3,
  //   modifyDate: '27 Aug 2021',
  //   remove: 'x',
  //   length: '03:17',
  //   id: 'MAT305-5',
  //   title: '15 Sec',
  //   titleExtra: 'Instrumental',
  // },
  // {
  //   playlistsName: '哈哈',
  //   name: 'becky',
  //   track: 3,
  //   modifyDate: '27 Aug 2021',
  //   remove: 'x',
  //   length: '03:17',
  //   id: 'MAT305-5',
  //   title: '15 Sec',
  //   titleExtra: 'Instrumental',
  // },
];
