import { useMessage } from 'naive-ui';
import { getAccountPlaylistListAPI, getAccountFavouriteAPI } from '@/api/myMusicPlaylists';
import useRequest from '@/hooks/useRequest';
import { useMyMusicStore } from '@/store/myMusic';

export default () => {
  const message = useMessage();
  const { request } = useRequest(getAccountPlaylistListAPI);
  const myMusicStore = useMyMusicStore();
  const { setAccountPlaylistList } = myMusicStore;

  const fetchAllPlaylist = async () => {
    // tracks
    const [trackFavErr, trackFavRes] = await getAccountFavouriteAPI({
      in: 'Tracks',
    });

    if (trackFavErr) {
      message.error(trackFavErr.message);
    }

    const [trackListErr, trackListRes] = await request({
      in: {
        paginationInfo: {
          currentPage: 1,
          pageSize: 20,
        },
        type: 'Tracks',
        order: {
          orderBy: 'DESC',
          orderByColumn: 'modifiedAt',
        },
      },
    });

    if (trackFavErr) {
      message.error(trackListErr.message);
    }

    const trackFavList = trackFavRes.getAccountFavourite ? [trackFavRes.getAccountFavourite] : [];
    const trackList = [...trackFavList, ...trackListRes.getAccountPlaylistList.data];

    setAccountPlaylistList('trackList', trackList);

    // albums
    const [albumFavErr, albumFavRes] = await getAccountFavouriteAPI({
      in: 'Albums',
    });

    if (albumFavErr) {
      message.error(albumFavErr.message);
    }

    const [albumListErr, albumListRes] = await request({
      in: {
        paginationInfo: {
          currentPage: 1,
          pageSize: 20,
        },
        type: 'Albums',
        order: {
          orderBy: 'DESC',
          orderByColumn: 'modifiedAt',
        },
      },
    });

    if (albumFavErr) {
      message.error(albumListErr.message);
    }

    const albumFavList = albumFavRes.getAccountFavourite ? [albumFavRes.getAccountFavourite] : [];
    const albumList = [...albumFavList, ...albumListRes.getAccountPlaylistList.data];
    setAccountPlaylistList('albumList', albumList);
  };

  return {
    fetchAllPlaylist,
  };
};
