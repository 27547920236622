const addCommas = (value) => {
  const reg = /\d(?=(\d{3})+$)/g;
  const valueStr = String(value);
  if (valueStr.indexOf('.') > -1) {
    const [integer, decimal] = valueStr.split('.');
    return `${integer.replace(reg, '$&,')}.${decimal}`;
  }

  return valueStr.replace(reg, '$&,');
};

export default addCommas;
