<template>
  <ul class="language-list" :class="{ 'language-list--mobile-menu-content': mobileMenuContent }">
    <li class="language-list__item" :class="[{
        'language-list__item--active': locale === l.key,
      }, `GTM_POINT_header_lang_${l.key}`]" v-for="l of languageList" :key="l.key" @click="handleLanguage(l.key)">
      <!-- {{ l.label }} -->
      <span class="language-list__item__label">{{ l.label }}</span>
    </li>
  </ul>
</template>
<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import cusStorage from '@/utils/cusStorage';

export default defineComponent({
  name: 'LanguageList',
  props: {
    mobileMenuContent: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { locale } = useI18n({ useScope: 'global' });

    const handleLanguage = (lang) => {
      locale.value = lang;
      cusStorage.setItem('lang', lang);
      window.location.reload();
    };

    const languageList = [
      {
        key: 'en',
        label: 'English',
      },
      {
        key: 'zh-tw',
        label: '繁體中文',
      },
      {
        key: 'zh-cn',
        label: '简体中文',
      },
    ];

    return {
      languageList,
      handleLanguage,
      locale,
    };
  },
});
</script>
<style lang="scss" scoped>
.language-list {

  @include padding(10px 0);

  &--mobile-menu-content {
    @include flex();
    @include padding(0 20px);

    .language-list__item+.language-list__item {
      margin-left: 20px;

    }

    .language-list {
      &__item {
        &__label {
          @include font-size(14);

        }
      }
    }
  }

  &__item {
    @include flex(center);
    height: 40px;
    cursor: pointer;

    &:hover {
      .language-list__item__label {
        color: $c-main;
      }
    }

    &--active {
      .language-list__item__label {
        color: $c-main;
      }
    }

    &__label {
      @include font-style($c-assist6, 14, normal, 0.8px, 22px);
    }
  }
}
</style>
