import { watch, computed } from 'vue';
import { useSiteStore } from '@/store/site';
import cusStorage from '@/utils/cusStorage';

export default () => {
  const siteStore = useSiteStore();
  const memberInfo = computed(() => siteStore.memberInfo);

  // level 1: 訪客
  // level 2: 信箱已驗證
  // level 3: 一般會員，不能下載（沒有下載額度）
  // level 4: 一般會員，可以下載

  // level 1 限制的功能
  //  - 沒有 music functions(加入歌單)
  //  - 有曲目播放鍵
  //  - 沒有專輯/歌單播放鍵
  //  - 沒有 my-music
  //  - footer 沒有會員專區
  // level 2 限制的功能
  //  - 沒有 music functions(加入歌單)
  //  - 有曲目播放鍵
  //  - 沒有 my-music
  //  - footer 會員專區只顯示「我的帳戶」
  // level 3 限制的功能
  //  - 不能下載（沒有下載額度）
  //  - footer 全顯示

  const memberLevel = computed(() => {
    if (!memberInfo.value.status) return 1;

    let level = 0;
    if (['MAILCHECK', 'FREEZE'].includes(memberInfo.value.status)) {
      level = 1;
    }

    if (memberInfo.value.status === 'APPROVAL') {
      level = 2;
    }

    if (memberInfo.value.status === 'NORMAL') {
      level = 3;
    }

    if (memberInfo.value.status === 'PRIMARY') {
      level = 4;
    }

    return level;
  });

  watch(memberLevel, () => {
    cusStorage.setItem('memberLevel', memberLevel.value);
  });

  return {
    memberLevel,
    memberInfo,
  };
};
