// 取的目錄下所有檔案
const currFolderFiles = require.context('./', false, /\.js$/);

// 取得目錄下所有檔案名稱，並過濾不需要的檔案
const getModuleFiles = currFolderFiles.keys().filter((item) => {
  // 不需要的檔案寫進來
  const excludeFiles = ['./index.js'];
  let isRemain = true;

  if (excludeFiles.indexOf(item) > -1) {
    isRemain = false;
  }

  return isRemain;
});

// 將需要的檔案export default組成物件
const modules = getModuleFiles.reduce((languageObj, path) => {
  const moduleName = path.replace(/((.\/)|(.js))/g, '');
  languageObj[moduleName] = currFolderFiles(path).default;

  return languageObj;
}, {});

export default modules;
