import {
  ref,
  //  createApp, h,
} from 'vue';

// import AsyncDialog from '@/components/Other/AsyncDialog.vue';
// import LoadingError from '@/components/Other/LoadingError.vue';
import { useRouter } from 'vue-router';
import usePopInfo from '@/hooks/usePopInfo';
import { useSiteStore } from '@/store/site';

export default (requestApi) => {
  const { errorPopInfo } = usePopInfo();
  const siteStore = useSiteStore();
  const isLoading = ref(false);
  const response = ref({});
  const router = useRouter();

  const request = async (args = {}) => {
    try {
      isLoading.value = true;
      response.value = await requestApi(args);
      isLoading.value = false;
      const [, , extraError] = response.value;

      if (extraError?.statusCode === 401) {
        siteStore.handleLogout();
        router.push({ name: 'home' });

        // errorPopInfo({
        //   desc: err.message,
        //   callback: () => {
        //     siteStore.handleLogout();
        //     router.push({ name: 'home' });
        //   },
        //   isOverlayUseCallback: true,
        // });
      }

      return response.value;
    } catch (e) {
      isLoading.value = false;
      // 錯誤彈窗
      errorPopInfo({
        desc: `${e}`,
        isOverlayUseCallback: true,
      });

      console.log('e...', e);
    } finally {
      // isLoading.value = false;
    }

    return null;
  };

  return {
    response,
    isLoading,
    request,
  };
};
