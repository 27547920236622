export default [
  // {
  //   path: '/search',
  //   component: () => import(/* webpackChunkName: "LayoutWithHeader" */ '@/layout/LayoutWithHeader.vue'),
  //   meta: {
  //     title: 'search',
  //     key: 'search',
  //   },
  //   children: [
  //     {
  //       path: '',
  //       name: 'search',
  //       component: () => import(/* webpackChunkName: "Search" */ '@/views/Search/Search.vue'),
  //       meta: {
  //         title: '搜尋',

  //       },
  //     },
  //   ],
  // },
];
