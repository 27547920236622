<template>
  <div class="avatar">
    <div class="avatar__content">
      <div class="avatar-box">
        <input type="file" ref="inputRef" class="file-input" @change="handleFileChange"
          accept="image/png, image/jpeg, image/jpg" />

        <div class="avatar-box__img" :style="{ 'background-image': `url('${avatarSrc}')` }" />

        <div class="edit-avatar" @click.prevent="triggerUpload">
          <img class="edit-avatar__icon" src="@/assets/profile/icon_edit.svg" />
          <p class="edit-avatar__text">{{ $t('member.modify') }}</p>
        </div>
      </div>

      <span class="member">
        <p class="member__name">{{ userName }}</p>
        <p class="member__level">{{ memberLabel }}</p>
      </span>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue';
// import { v4 as uuid } from 'uuid';
import { useMessage } from 'naive-ui';
import { useI18n } from 'vue-i18n';
import { useSiteStore } from '@/store/site';
import { uploadImageAPI } from '@/api/uploadImage';
import useRequest from '@/hooks/useRequest';
import { updateAccountAPI, whoamiAPI } from '@/api/members';
import imageSource from '@/utils/imageSource';
import defaultAvatar from '@/assets/icon/header_user_black.svg';
// import genMessage from '@/utils/errMsgMapping';

export default defineComponent({
  name: 'Avatar',
  // emits: ['uploadAvatar'],
  setup() {
    const { t } = useI18n();
    const message = useMessage();
    const siteStore = useSiteStore();
    const userName = computed(() => siteStore.memberInfo.realName);
    const memberLevel = computed(() => siteStore.memberInfo.status);
    const memberAuditStatus = computed(() => {
      if (!memberLevel.value) return '';

      const levelConvert = {
        APPROVAL: `(${t('member.waitForAudit')})`, // 待審核
        FREEZE: '', // 凍結
        MAILCHECK: `(${t('member.waitForAudit')})`, // 驗證郵件
        NORMAL: '', // 一般會員
      };

      return levelConvert[memberLevel.value];
    });
    const file = ref();
    const inputRef = ref(null);
    const { request, response } = useRequest(uploadImageAPI);
    const { request: updateAccountRequest, response: updateAccountResponse } = useRequest(updateAccountAPI);
    const { request: whoamiRequest, response: whoamiResponse } = useRequest(whoamiAPI);

    const handleFileChange = (event) => {
      file.value = event.target.files;

      if (file.value[0].size > 1048576) {
        message.error(t('member.fileLimit1mb'));
        return;
      }

      request({
        file: file.value,
        in: {
          type: 'Avator',
        },
      });
    };

    const triggerUpload = () => {
      inputRef.value.click();
    };

    const userData = computed(() => siteStore.memberInfo);
    const avatarSrc = computed(() => imageSource(siteStore.memberInfo.avator, defaultAvatar));

    const memberLabel = computed(() => {
      switch (memberLevel.value) {
        case 'PRIMARY':
          return t('member.formerMember');
        case 'NORMAL':
          return t('member.normalMember');
        case 'APPROVAL':
          return t('member.inReview');
        default:
          return t('member.unverified');
      }
    });

    watch(response, () => {
      const [err, res] = response.value;

      if (err) {
        return;
      }

      if (res) {
        const { path } = res.uploadImage;

        updateAccountRequest({
          in: {
            avator: path,
            city: userData.value.city,
            companyAddress: userData.value.companyAddress,
            companyCity: userData.value.companyCity,
            companyCountry: userData.value.companyCountry,
            companyName: userData.value.companyName,
            country: userData.value.country,
            jobTitle: userData.value.jobTitle,
            mobile: userData.value.mobile,
            phoneNumber: userData.value.phoneNumber,
            realName: userData.value.realName,
            salutation: userData.value.salutation,
            clientCategoryUid: userData.value?.clientCategory?.clientCategoryUid,
          },
        });
      }
    });

    watch(updateAccountResponse, () => {
      const [err, res] = updateAccountResponse.value;

      if (err) {
        message.error(err.messsage);
      }

      if (res) {
        whoamiRequest();
      }
    });

    watch(whoamiResponse, () => {
      const [err, res] = whoamiResponse.value;

      if (err) {
        message.error(err.messsage);
      }

      if (res) {
        siteStore.setMemberInfo(res.whoami);
        message.success(t('member.updateAvatarSuccess'));
      }
    });

    return {
      handleFileChange,
      triggerUpload,
      imageSource,
      userName,
      file,
      inputRef,
      avatarSrc,
      memberAuditStatus,
      memberLevel,
      memberLabel,
    };
  },
});
</script>
<style lang="scss" scoped>
.avatar {
  flex: none;
  width: 50%;
  @include flex();

  &__content {
    @include flex();
  }
}

.avatar-box {
  @include circle(52px);
  overflow: hidden;
  position: relative;

  &__img {
    width: 52px;
    height: 52px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.edit-avatar {
  display: none;
}

.member {
  margin-left: 16px;

  &__name {
    @include font-style($c-black, 14, bold, 0px, 22px);
  }

  &__level {
    @include font-style($c-assist3, 14, normal, 0px, 22px);
  }
}

@media screen and (min-width: $tablet) {
  .avatar {
    flex: 1;
    width: 100%;
    @include flex(center, center);

    &__content {
      @include padding(36px 0 30px 0);
      @include flex(center, center, column);
    }
  }

  .avatar-box {
    @include circle(150px);
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    &__img {
      width: 150px;
      height: 150px;
    }
  }

  .edit-avatar {
    @include position(bl, 0px, 0);
    @include flex(center, center);
    background-color: rgba($c-black, 0.6);
    width: 100%;
    height: 30px;
    cursor: pointer;

    &__icon {
      width: 13px;
      cursor: pointer;
    }

    &__text {
      margin-left: 2px;
      @include font-style($c-white, 13, bold, 0, 24px);
    }
  }

  .member {
    &__name {
      @include font-style($c-black, 24, bold, 1.2px, 32px);
      margin-top: 18px;
      text-align: center;
    }

    &__level {
      @include font-style($c-assist3, 14, normal, 0.28px, 24px);
      margin-top: 12px;
      text-align: center;
    }
  }
}

.file-input {
  display: none;
}
</style>
