import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { isString } from 'lodash-es';
import { useI18n } from 'vue-i18n';
import useRequest from '@/hooks/useRequest';
import { getTrackAPI, getTrackDetailAPI } from '@/api/getTrack';
import { getPlaylistAPI } from '@/api/getPlaylists';
import { getAccountPlaylistAPI } from '@/api/myMusicPlaylists';
import { getAlbumAPI } from '@/api/getAlbums';
import useGetTrackFile from '@/hooks/useGetTrackFile';
import trackWaveSource from '@/utils/trackWaveSource';
import cusStorage from '@/utils/cusStorage';
import formatTrackSubInfo from '@/utils/formatTrackSubInfo';

export const useNewMusicPlayerStore = defineStore('newMusicPlayerStore', () => {
  const { request: getTrackRequest } = useRequest(getTrackAPI);
  const { request: getTrackDetailRequest } = useRequest(getTrackDetailAPI);
  const { request: getAlbumRequest } = useRequest(getAlbumAPI);
  const { request: getPlaylistReqeust } = useRequest(getPlaylistAPI);
  const { request: getAccountPlaylistRequest } = useRequest(getAccountPlaylistAPI);
  const { getTrackFileUrl } = useGetTrackFile();
  const { t } = useI18n();
  const route = useRoute();
  const isShowMusicPlayer = ref(false);

  // state
  // 判斷現在是 queue 列表還是 verison 列表
  const currPlayList = ref('queue');
  const currentPlayTrackInfo = ref({});
  const playControll = ref({
    play: false,
    currentTime: 0,
    manuallyPlayFrom: 0,
  });
  const volume = ref(0);
  // 版本佇列
  const versionList = ref([]);
  // 播放佇列
  const queueList = ref([]);
  // 播放器樣式/相關css調整
  const musicPlayerStyle = ref('circle');
  const footerBottomExtraPaddingType = ref('circle');
  const musicFile = ref({
    url: '',
    trackUid: '',
  });
  // queue 中 checkbox 使用
  const selectTrackList = ref([]);
  const searchTrackList = ref([]);
  const shareData = ref({
    albumUid: '',
    trackUid: '',
  });

  const toggleShowMusicPlayer = (status) => {
    console.log('status', status);
    isShowMusicPlayer.value = status;
  };

  // 取得音檔來源
  const fetchTrackFile = async (trackUid) => {
    try {
      const fileUrl = await getTrackFileUrl({ trackUid });

      return fileUrl;
    } catch (e) {
      console.log('e', e);
    }

    return '';
  };

  const fetchTrack = async (trackUid) => {
    try {
      const [, res] = await getTrackRequest({ in: trackUid });

      const data = res.getTrack || {};

      return {
        ...data,
        track: {
          ...data,
          trackName: data.trackTitleDisplay,
          cover: data.artworkInfo,
          trackSubInfo: formatTrackSubInfo({ editType: data.editType, versionType: data.versionType }),
          trackWave: trackWaveSource(data.trackWave),
        },
        album: data?.album,
      };
    } catch (e) {
      console.log('e', e);
    }

    return {};
  };

  // 拿 version
  const fetchTrackDetail = async (trackUid) => {
    try {
      const [, res] = await getTrackDetailRequest({ in: trackUid });

      return res.getTrackDetail;
    } catch (e) {
      console.log('e', e);
    }

    return {};
  };

  const fetchAlbum = async (albumUid) => {
    try {
      const [, res] = await getAlbumRequest({ in: albumUid });
      // console.log('err', err);
      // console.log('res', res);

      if (!res) {
        return false;
      }

      return res.getAlbum;
    } catch (e) {
      console.log('e', e);
    }

    return {};
  };

  const fetchPlaylist = async (playlistUid) => {
    try {
      const [, res] = await getPlaylistReqeust({
        playlistUid,
        relation: true,
      });

      return res.getPlaylist;
    } catch (e) {
      console.log('e', e);
    }

    return {};
  };

  const fetchMemberMyTracks = async (playlistUid) => {
    try {
      const [, res] = await getAccountPlaylistRequest({ in: playlistUid });

      return res.getAccountPlaylist.accountPlaylistContents;
    } catch (e) {
      console.log('e', e);
    }

    return {};
  };

  const recordMusicPlayerCurrentPlayTrackInfo = () => {
    cusStorage.setItem('musicPlayer_currentPlayTrackInfo', currentPlayTrackInfo.value);
  };

  const recordMusicPlayerCurrPlayList = () => {
    cusStorage.setItem('musicPlayer_currPlayList', currPlayList.value);
  };

  const recordMusicPlayerVersionList = () => {
    cusStorage.setItem('musicPlayer_versionList', versionList.value);
  };

  const recordMusicPlayerQueueList = () => {
    cusStorage.setItem('musicPlayer_queueList', queueList.value);
  };

  const recordMusicPlayerVolume = () => {
    cusStorage.setItem('musicPlayer_volume', volume.value);
  };

  const recordMusicPlayerTime = (time) => {
    cusStorage.setItem('musicPlayer_time', time);
  };

  const setVolume = (val) => {
    volume.value = val;
    recordMusicPlayerVolume();
  };

  const manuallyPlayFrom = (val) => {
    playControll.value.manuallyPlayFrom = val;
  };

  const setCurrentTime = (time) => {
    recordMusicPlayerTime(String(time));
    playControll.value.currentTime = time;
  };

  const handleVersion = async (clear = false) => {
    if (clear) {
      versionList.value = [];
      return;
    }
    const {
      masterVersionTrackUid,
      track: { trackUid },
    } = currentPlayTrackInfo.value;
    const versionData = await fetchTrackDetail(trackUid);
    // 有 masterVersionTrackUid 表示是曲目，沿用原 trackuid
    const masterTrackUid = masterVersionTrackUid || trackUid;

    // .filter((item) => {
    //   return !item.isMasterVersion;
    // })

    console.log('versionData', versionData);
    versionList.value = versionData.trackList.map((item) => {
      return {
        masterVersionTrackUid: masterTrackUid,
        album: { ...currentPlayTrackInfo.value.album }, // avoid reference value
        track: {
          cover: item.artworkInfo,
          duration: item.duration,
          isrc: currentPlayTrackInfo.value.track.isrc,
          iswc: currentPlayTrackInfo.value.track.iswc,
          trackDescription: item.trackDescription,
          trackNumber: item.trackNumber,
          trackName: item.trackTitleDisplay,
          trackSubInfo: formatTrackSubInfo({ editType: item.editType, versionType: item.versionType }),
          trackUid: item.trackUid,
          trackWave: trackWaveSource(item.trackWave),
        },
      };
    });

    recordMusicPlayerVersionList();
  };

  const fetchTrackAndSetIntoMusicFile = async (trackUid) => {
    const musicFileUrl = await fetchTrackFile(trackUid);

    musicFile.value = {
      url: musicFileUrl,
      trackUid,
    };
  };

  // 播放/暫停
  const setControllPlayerStatus = (status) => {
    playControll.value.play = status;
  };

  // 開始播放
  const startPlayTrack = (trackData, specificTime) => {
    // console.log('trackData?', trackData);
    // console.log('musicFile.value', musicFile.value);
    // 馬上設定 true 的話，vue 響應系統可能還沒更改到 audio 中
    setTimeout(() => {
      // 歌曲就算不能播，也要放進去，這樣按下一首才找得到
      currentPlayTrackInfo.value = trackData;
      const playSuccess = !!musicFile.value.url;
      handleVersion(!playSuccess);
      if (!playSuccess) {
        return;
      }
      if (specificTime) {
        manuallyPlayFrom(specificTime);
      }
      setControllPlayerStatus(true);
      recordMusicPlayerCurrPlayList();
      recordMusicPlayerCurrentPlayTrackInfo();
      // handleVersion();
    }, 100);
  };

  // 播放第一首曲目
  const playFirstTrack = async (specificTime) => {
    // specificTime
    setControllPlayerStatus(false);
    const fisrtTrack = queueList.value[0];
    const { trackUid } = fisrtTrack.track;
    // const musicFileUrl = await fetchTrackFile(fisrtTrack.track.trackUid);
    // musicFile.value = {
    //   url: musicFileUrl,
    //   trackUid,
    // };

    await fetchTrackAndSetIntoMusicFile(trackUid);

    const trackData = await fetchTrack(trackUid);

    startPlayTrack(trackData, specificTime);
  };

  // 播放指定曲目
  const playSpecificTrack = async (trackUid) => {
    setControllPlayerStatus(false);

    let matchTrack = {};
    if (currPlayList.value === 'queue') {
      matchTrack = queueList.value.find((trackData) => {
        return trackUid === trackData.track.trackUid;
      });
    } else {
      matchTrack = versionList.value.find((trackData) => {
        return trackUid === trackData.track.trackUid;
      });
    }

    // const musicFileUrl = await fetchTrackFile(matchTrack.track.trackUid);
    // musicFile.value = {
    //   url: musicFileUrl,
    //   trackUid,
    // };
    await fetchTrackAndSetIntoMusicFile(matchTrack.track.trackUid);

    const trackData = await fetchTrack(matchTrack.track.trackUid);

    startPlayTrack(trackData);
  };

  const setCurrPlayList = (value) => {
    if (['queue', 'version'].includes(value)) {
      currPlayList.value = value;
    }
  };

  // 播放指定Version
  const playSpecificVersionTrack = async (trackUid) => {
    setControllPlayerStatus(false);
    const matchVersionTrack = versionList.value.find((trackData) => {
      return trackUid === trackData.track.trackUid;
    });

    // const musicFileUrl = await fetchTrackFile(matchVersionTrack.track.trackUid);
    // musicFile.value = {
    //   url: musicFileUrl,
    //   trackUid,
    // };
    await fetchTrackAndSetIntoMusicFile(matchVersionTrack.track.trackUid);

    const trackData = await fetchTrack(matchVersionTrack.track.trackUid);

    startPlayTrack(trackData);
  };

  const handleVersionTrack = (trackUuid) => {
    // console.log('handleVersionTrack', trackUuid);
    setControllPlayerStatus(false);
    setCurrPlayList('version');
    playSpecificVersionTrack(trackUuid);
  };

  // 取得當前播放音樂在 queue 中的 index
  const currPlayQueueTrackIndex = () => {
    const { trackUid } = currentPlayTrackInfo.value.track;
    const matchTrackIndex = queueList.value.findIndex((trackData) => {
      return trackUid === trackData.track.trackUid;
    });

    return matchTrackIndex;
  };

  // version 最後一首跳回去時，要找對應的主曲目 index
  const findQueueTrack = (trackUid) => {
    // console.log('queueList', queueList.value);
    // console.log('trackUid', trackUid);
    const matchTrack = queueList.value.find((trackData) => {
      // console.log('trackUid', trackUid);
      // console.log('trackData.track.trackUid', trackData.track.trackUid);
      // console.log('?', trackUid === trackData.track.trackUid);
      return trackUid === trackData.track.trackUid;
    });

    return matchTrack;
  };

  const currPlayVersionTrackIndex = () => {
    const { trackUid } = currentPlayTrackInfo.value.track;
    const matchTrackIndex = versionList.value.findIndex((trackData) => {
      return trackUid === trackData.track.trackUid;
    });

    return matchTrackIndex;
  };

  // 播放前一首歌 --- start
  const playPrevQueueTrack = () => {
    const matchTrackIndex = currPlayQueueTrackIndex();

    const prevTrackIndex = matchTrackIndex - 1;
    // 如果不是第一首，表示有前一首
    if (prevTrackIndex !== -1) {
      const prevTrackUid = queueList.value[matchTrackIndex - 1].track.trackUid;

      playSpecificTrack(prevTrackUid);
    }
  };

  const palyPrevVersionTrack = () => {
    const matchTrackIndex = currPlayVersionTrackIndex();

    const prevTrackIndex = matchTrackIndex - 1;
    // 如果不是第一首，表示有前一首
    if (prevTrackIndex !== -1) {
      const prevTrackUid = versionList.value[matchTrackIndex - 1].track.trackUid;

      playSpecificTrack(prevTrackUid);
    }
  };

  const playPrevTrack = () => {
    if (currPlayList.value === 'queue') {
      playPrevQueueTrack();
    }

    if (currPlayList.value === 'version') {
      palyPrevVersionTrack();
    }
  };

  // 播放前一首歌 --- end

  // 播放下一首歌 --- start
  const playQueueNextTrack = async () => {
    setControllPlayerStatus(false);
    setCurrentTime(0);
    const matchTrackIndex = currPlayQueueTrackIndex();
    // console.log('matchTrackIndex', matchTrackIndex);

    const nextTrackIndex = matchTrackIndex + 1;

    // 如果不是最後一首，表示有下一首
    if (nextTrackIndex !== queueList.value.length) {
      const nextTrackUid = queueList.value[matchTrackIndex + 1].track.trackUid;

      await playSpecificTrack(nextTrackUid);
    } else {
      // console.log('222');
      const queueListLength = queueList.value.length;
      const lastTrackInfo = queueList.value[queueListLength - 1];
      // console.log('lastTrackInfo', lastTrackInfo);
      currentPlayTrackInfo.value = lastTrackInfo;
      fetchTrackAndSetIntoMusicFile(currentPlayTrackInfo.value.track.trackUid);

      setControllPlayerStatus(false);
    }
  };

  const playVersionNextTrack = async () => {
    setControllPlayerStatus(false);
    setCurrentTime(0);
    const matchTrackIndex = currPlayVersionTrackIndex();

    const nextTrackIndex = matchTrackIndex + 1;
    // 如果不是最後一首，表示有下一首
    if (nextTrackIndex !== versionList.value.length) {
      const nextTrackUid = versionList.value[matchTrackIndex + 1].track.trackUid;

      playSpecificTrack(nextTrackUid);
    } else {
      const { masterVersionTrackUid } = currentPlayTrackInfo.value;
      setCurrPlayList('queue');
      // console.log('masterVersionTrackUid', masterVersionTrackUid);

      const matchQueueTrack = findQueueTrack(masterVersionTrackUid);
      // console.log('matchQueueTrack', matchQueueTrack);
      currentPlayTrackInfo.value = matchQueueTrack;
      await playQueueNextTrack();
    }
  };

  const playNextTrack = async () => {
    if (currPlayList.value === 'queue') {
      await playQueueNextTrack();
    }

    if (currPlayList.value === 'version') {
      await playVersionNextTrack();
    }
  };
  // 播放下一首歌 --- end

  // 清空播放列表
  const clearQueueList = () => {
    setControllPlayerStatus(false);
    queueList.value = [];
    recordMusicPlayerQueueList();
  };

  const clearVersionList = () => {
    setControllPlayerStatus(false);
    versionList.value = [];
    recordMusicPlayerVersionList();
  };

  const handleClearMusicPlayer = () => {
    setControllPlayerStatus(false);
    cusStorage.removeItem('musicPlayer_currentPlayTrackInfo');
    cusStorage.removeItem('musicPlayer_currPlayList');
    cusStorage.removeItem('musicPlayer_versionList');
    cusStorage.removeItem('musicPlayer_queueList');
    cusStorage.removeItem('musicPlayer_volume');
    clearQueueList();
    clearVersionList();
    currentPlayTrackInfo.value = {};
  };

  // 加入播放列表
  const addIntoQueueList = (trackInfo) => {
    if (trackInfo.track.status === 'Unactivate') {
      return;
    }

    queueList.value.push(trackInfo);
    recordMusicPlayerQueueList();
  };

  // 從播放列表移除
  const removeFromQueueList = (trackUid) => {
    const currPlayedTrackId = currentPlayTrackInfo.value.track.trackUid;

    // console.log('trackUid', trackUid);
    // console.log('currPlayedTrackId', currPlayedTrackId);
    if (currPlayedTrackId === trackUid) {
      return;
    }

    queueList.value = queueList.value.filter((item) => {
      return item.track.trackUid !== trackUid;
    });
    recordMusicPlayerQueueList();

    if (queueList.value.length === 0) {
      handleClearMusicPlayer();
    }
  };

  // 從播放列表移除多個曲目
  const removeMultiTrackFromQueueList = (trackUidList) => {
    queueList.value = queueList.value.filter((item) => {
      return !trackUidList.includes(item.track.trackUid);
    });
    recordMusicPlayerQueueList();
  };

  // 處理單首曲目播放
  const handleTrack = async (trackUuid, autoplay = true) => {
    clearQueueList();
    const trackData = await fetchTrack(trackUuid);
    // 下架的曲目拿不到資訊
    if (!trackData.trackUid) {
      return;
    }

    const {
      artworkInfo,
      album,
      trackDescription,
      trackNumber,
      isrc,
      iswc,
      trackTitleDisplay,
      trackUid,
      duration,
      trackWave,
      editType,
      versionType,
      status,
    } = trackData;

    currentPlayTrackInfo.value = {
      album,
      track: {
        cover: artworkInfo,
        duration,
        isrc,
        iswc,
        trackDescription,
        trackNumber,
        trackName: trackTitleDisplay,
        trackSubInfo: formatTrackSubInfo({ editType, versionType }),
        editType,
        versionType,
        trackUid,
        status,
        trackWave: trackWaveSource(trackWave),
      },
    };

    addIntoQueueList(currentPlayTrackInfo.value);
    if (autoplay) {
      playFirstTrack();
    }
  };

  const formatAlbumData = (albumData) => {
    // console.log('albumData', albumData);
    // console.log('albumData', albumData);
    if (!albumData) {
      return [];
    }

    const { albumCode, albumDescription, albumTitleDisplay, albumUid, artworkInfo } = albumData;

    const album = {
      albumCode,
      albumDescription,
      albumTitleDisplay,
      albumUid,
      artworkInfo,
    };

    const queueArr = albumData.tracks.map((track) => {
      // console.log('track', track);
      return {
        // musicFileUrl,
        album,
        track: {
          cover: track.artworkInfo,
          duration: track.duration,
          isrc: track.isrc,
          iswc: track.iswc,
          trackDescription: track.trackDescription,
          trackNumber: track.trackNumber,
          trackName: track.trackTitleDisplay,
          trackSubInfo: formatTrackSubInfo({ editType: track.editType, versionType: track.versionType }),
          trackUid: track.trackUid,
          status: track.status,
          trackWave: trackWaveSource(track.trackWave),
        },
      };
    });

    return queueArr;
  };

  // 播放專輯
  const handleAlbum = async (albumUuid, autoplay = true) => {
    clearQueueList();
    const albumData = await fetchAlbum(albumUuid);

    const queueArr = formatAlbumData(albumData);

    queueArr.forEach((trackData) => {
      addIntoQueueList(trackData);
    });

    if (autoplay) {
      playFirstTrack();
    }
  };

  const handlePlaylist = async (playlistUuid, autoplay = true) => {
    clearQueueList();
    const playlistData = await fetchPlaylist(playlistUuid);

    const queueArr = playlistData.tracks.map((item) => {
      return {
        // musicFileUrl,
        album: item.track.album,
        track: {
          cover: item.track.artworkInfo,
          duration: item.track.duration,
          isrc: item.track.isrc,
          iswc: item.track.iswc,
          trackDescription: item.track.trackDescription,
          trackNumber: item.track.trackNumber,
          trackName: item.track.trackTitleDisplay,
          trackSubInfo: formatTrackSubInfo({ editType: item.track.editType, versionType: item.track.versionType }),
          trackUid: item.track.trackUid,
          status: item.track.status,
          trackWave: trackWaveSource(item.track.trackWave),
        },
      };
    });

    queueArr.forEach((trackData) => {
      addIntoQueueList(trackData);
    });

    if (autoplay) {
      playFirstTrack();
    }
  };

  // 點擊波形圖播放，指定曲目時間播放
  const handleWavePlay = async (trackUid, specificTime) => {
    // console.log('trackUid', trackUid);
    // console.log('specificTime', specificTime);
    // 若是同一首歌，就直接跳去該位置
    // console.log(trackUid, currentPlayTrackInfo.value.track.trackUid);
    if (trackUid === currentPlayTrackInfo.value?.track?.trackUid) {
      manuallyPlayFrom(specificTime);
      return;
    }
    await handleTrack(trackUid, false);
    playFirstTrack(specificTime);
    // manuallyPlayFrom(specificTime);
  };

  const handleQueueTrack = (trackUid) => {
    setCurrPlayList('queue');
    playSpecificTrack(trackUid);
  };

  const formatSearchTracks = (searchTrack) => {
    // 格式同 currentPlayTrackInfo
    searchTrackList.value = searchTrack.map((track) => {
      return {
        album: track.album,
        track: {
          cover: track.artworkInfo,
          duration: track.duration,
          isrc: track.isrc,
          iswc: track.iswc,
          trackDescription: track.trackDescription,
          trackNumber: track.trackNumber,
          trackName: track.trackTitleDisplay,
          trackUid: track.trackUid,
          trackSubInfo: formatTrackSubInfo({ editType: track.editType, versionType: track.versionType }),
          status: 'Activate', // 寫死，搜尋中不會有未上架的
          trackWave: trackWaveSource(track.trackWave),
        },
      };
    });
  };

  const handleSearchTrack = (trackUid) => {
    console.log('searchTrackList.value', searchTrackList.value);
    clearQueueList();

    const matchTrackIndex = searchTrackList.value.findIndex((trackData) => {
      return trackUid === trackData.track.trackUid;
    });

    const tempList = searchTrackList.value.filter((item, idx) => {
      return idx >= matchTrackIndex;
    });

    tempList.forEach((trackData) => {
      addIntoQueueList(trackData);
    });

    playFirstTrack();
  };

  const handlesVersionInTrack = (trackUid) => {
    handleTrack(trackUid);
  };

  const handleMemberMyTracks = async (accountPlaylistUid, autoplay = true) => {
    clearQueueList();
    const memberMyTracks = await fetchMemberMyTracks(accountPlaylistUid);

    const queueArr = memberMyTracks.reduce((list, item) => {
      if (item.track.status === 'Activate') {
        const tempTrackInfo = {
          // musicFileUrl,
          album: item.track.album,
          track: {
            cover: item.track.artworkInfo,
            duration: item.track.duration,
            isrc: item.track.isrc,
            iswc: item.track.iswc,
            trackDescription: item.track.trackDescription,
            trackNumber: item.track.trackNumber,
            trackName: item.track.trackTitleDisplay,
            trackSubInfo: formatTrackSubInfo({ editType: item.track.editType, versionType: item.track.versionType }),
            trackUid: item.track.trackUid,
            status: item.track.status,
            trackWave: trackWaveSource(item.track.trackWave),
          },
        };
        list.push(tempTrackInfo);
      } else {
        window.$message.warning(t('myMusic.ignoreTrack'));
      }

      return list;
    }, []);

    queueArr.forEach((trackData) => {
      addIntoQueueList(trackData);
    });
    if (autoplay) {
      playFirstTrack();
    }
  };

  const handleMemberMyAlbum = async (accountPlaylistUid, autoplay = true) => {
    clearQueueList();

    // 拿到專輯 uid --> 拿所有專輯曲目 --> 放入 queue
    const memberMyAlbum = await fetchMemberMyTracks(accountPlaylistUid);
    // console.log('memberMyAlbum', memberMyAlbum);
    const albumUidList = memberMyAlbum.reduce((list, item) => {
      if (item.album.status === 'Activate') {
        list.push(item.album.albumUid);
      } else {
        window.$message.warning(t('myMusic.ignoreAlbum'));
      }

      return list;
    }, []);

    console.log('albumUidList', albumUidList);

    const recordAlbumTracks = [];
    for (let i = 0; i < albumUidList.length; i += 1) {
      const albumData = await fetchAlbum(albumUidList[i]);

      const albumTracksArr = formatAlbumData(albumData);
      recordAlbumTracks.push(albumTracksArr);
    }
    const queueArr = recordAlbumTracks.flat();

    queueArr.forEach((trackData) => {
      addIntoQueueList(trackData);
    });
    if (autoplay) {
      playFirstTrack();
    }
  };

  const resetSelectTrackList = () => {
    selectTrackList.value = [];
  };

  // 新增選到的曲目checkbox
  const addIntoSelectTrackList = (trackUid) => {
    selectTrackList.value.push(trackUid);
  };

  // 移除選到的曲目checkbox
  const removeFromSelectTrackList = (trackUid) => {
    selectTrackList.value = selectTrackList.value.filter((selectTrackUid) => {
      return trackUid !== selectTrackUid;
    });
  };

  // 批次移除選中的曲目
  const handleRemoveFromPlayQueue = () => {
    selectTrackList.value.forEach((trackUid) => {
      removeFromQueueList(trackUid);
    });

    resetSelectTrackList();
  };

  // 專輯頁面
  const playByShare = async () => {
    await handleTrack(route.query.tu, false);
    await fetchTrackAndSetIntoMusicFile(route.query.tu);

    setTimeout(() => {
      const matchQueueTrack = findQueueTrack(route.query.tu);
      currentPlayTrackInfo.value = matchQueueTrack;
      let time = 0;
      if (isString(route.query?.t)) {
        time = route.query.t >= matchQueueTrack.track.duration ? 0 : route.query.t;
      }
      // firefox 指定秒數會有問題，所以 firefox 不設定指定秒數
      manuallyPlayFrom(time);
      setControllPlayerStatus(false);
    }, 100);
  };

  // 從上次的歌開始播放
  const playByRecord = async () => {
    const musicPlayerCurrentPlayTrackInfo = cusStorage.getItem('musicPlayer_currentPlayTrackInfo');
    const musicPlayerCurrPlayList = cusStorage.getItem('musicPlayer_currPlayList');
    const musicPlayerVersionList = cusStorage.getItem('musicPlayer_versionList');
    const musicPlayerQueueList = cusStorage.getItem('musicPlayer_queueList');
    const musicPlayerVolume = cusStorage.getItem('musicPlayer_volume');
    const musicPlayerTime = cusStorage.getItem('musicPlayer_time');

    console.log('%cmusicPlayerTime -->', 'color: #059669; background-color: #D1FAE5', musicPlayerTime);

    const isAllExist =
      musicPlayerCurrentPlayTrackInfo && musicPlayerCurrPlayList && musicPlayerVersionList && musicPlayerQueueList;

    if (isAllExist) {
      currentPlayTrackInfo.value = musicPlayerCurrentPlayTrackInfo;
      currPlayList.value = musicPlayerCurrPlayList;
      versionList.value = musicPlayerVersionList;
      queueList.value = musicPlayerQueueList;
      volume.value = musicPlayerVolume;

      await fetchTrackAndSetIntoMusicFile(currentPlayTrackInfo.value.track.trackUid);

      setTimeout(() => {
        try {
          manuallyPlayFrom(musicPlayerTime);
        } catch (e) {
          console.log(e);
        } finally {
          setControllPlayerStatus(false);
        }
      }, 100);

      // 進入頁面自動播放
      // const { trackUid } = currentPlayTrackInfo.value.track;
      // playSpecificTrack(trackUid);
    }
  };

  const initVolume = () => {
    const storeVolume = cusStorage.getItem('musicPlayer_volume');

    if (storeVolume === 0) {
      setVolume(storeVolume);
      return;
    }

    const defaultVolume = storeVolume || 0.2;
    setVolume(defaultVolume);
  };

  const init = async () => {
    if (route.name === 'explore-albums-info' && route.query?.tu) {
      playByShare();
      return;
    }

    initVolume();
    playByRecord();
  };

  const setMusicPlayerStyle = (type) => {
    musicPlayerStyle.value = type;
  };

  const setShareData = (albumUid, trackUid) => {
    shareData.value = {
      albumUid,
      trackUid,
    };
  };

  // computed
  const musicInfo = computed(() => {
    return {
      creator: currentPlayTrackInfo.value?.album?.albumCode || '',
      albumUid: currentPlayTrackInfo.value?.album?.albumUid || '',
      trackNumber: currentPlayTrackInfo.value?.track?.trackNumber || '',
      trackName: currentPlayTrackInfo.value?.track?.trackName || '',
      trackUid: currentPlayTrackInfo.value?.track?.trackUid || '',
      cover: currentPlayTrackInfo.value?.track?.cover || '',
      trackSubInfo: currentPlayTrackInfo.value?.track?.trackSubInfo || '',
      editType: currentPlayTrackInfo.value?.track?.editType || '',
      versionType: currentPlayTrackInfo.value?.track?.versionType || '',
    };
  });

  const musicProgress = computed(() => {
    return {
      fullLength: currentPlayTrackInfo.value?.track?.duration || '',
      trackWave: currentPlayTrackInfo.value?.track?.trackWave || '',
    };
  });

  // 音源
  // const musicFileUrl = computed(() => {
  //   return currentPlayTrackInfo.value?.musicFileUrl || '';
  // });

  // 音樂播放進度
  const musicProgressPersentage = computed(() => {
    if (!playControll.value.currentTime || !currentPlayTrackInfo.value?.track?.duration) return 0;
    return (playControll.value.currentTime / currentPlayTrackInfo.value?.track?.duration) * 100;
  });

  return {
    // state
    // status,
    currentPlayTrackInfo,
    volume,
    playControll,
    versionList,
    queueList,
    musicPlayerStyle,
    footerBottomExtraPaddingType,
    musicFile,
    selectTrackList,
    shareData,
    currPlayList,
    isShowMusicPlayer,

    // getter
    musicInfo,
    musicProgress,
    musicProgressPersentage,

    // action
    toggleShowMusicPlayer,
    fetchTrackDetail,
    fetchTrackFile,
    handleTrack,
    setVolume,
    setControllPlayerStatus,
    setCurrentTime,
    manuallyPlayFrom,
    handleAlbum,
    handlePlaylist,
    handleQueueTrack,
    playPrevTrack,
    playNextTrack,
    handleWavePlay,
    handleVersionTrack,
    removeFromQueueList,
    removeMultiTrackFromQueueList,
    addIntoSelectTrackList,
    removeFromSelectTrackList,
    handleRemoveFromPlayQueue,
    handleMemberMyTracks,
    handleMemberMyAlbum,
    init,
    handleSearchTrack,
    handlesVersionInTrack,
    formatSearchTracks,
    setMusicPlayerStyle,
    setShareData,
    handleClearMusicPlayer,
  };
});

export const a = 1;
