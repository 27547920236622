<template>
  <div class="c-title-with-function">
    <div class="c-title-with-function__title">
      <router-link class="c-title-with-function__title__image"
        v-bind:style="{ 'background-image': 'url(' + albumCoverImg + ')' }"
        :to="{ path: `/explore/albums-info/${data.albumInfo?.albumUid || albumUid || data.album?.albumUid || ''}` }"></router-link>
      <div>
        <!-- (${data.track.trackSubInfo}) -->
        <tooltip :label="`${data.track.trackName} ${data.track.trackSubInfo && `(${data.track.trackSubInfo})`}`"
          cusPlacementType="queue-version">
          <p class="c-title-with-function__title__text">{{ data.track.trackName }}
          </p>
          <p class="c-title-with-function__title__sub-text" v-if="data.track.trackSubInfo">
            ({{ data.track.trackSubInfo }})
          </p>
        </tooltip>
      </div>
      <span class="c-title-with-function__title__extra" v-if="data.titleExtra">({{ data.titleExtra }})</span>
    </div>
    <div class="c-title-with-function__function">
      <music-function :data="data" :function-list="functionList" :playType="playType" showPlay ml />
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';
import imageSource from '@/utils/imageSource';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'TitleWithFunction',
  components: {
    MusicFunction,
    Tooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    functionList: {
      type: Array,
      default: () => ['add', 'star', 'download', 'share'],
    },
    playType: {
      type: String,
      default: 'queue',
    },
    idx: {
      type: Number,
    },
  },
  setup(props) {
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    return {
      albumCoverImg: computed(() => imageSource(props.data?.artworkInfo || props.data?.track.cover)),
      albumUid: computed(() => newMusicPlayerStore.musicInfo.albumUid),
    };
  },
});
</script>
<style lang="scss" scoped>
.c-title-with-function {
  width: 100%;
  @include flex();

  &__title {
    @include flex();
    flex: none;
    width: 500px;

    &__image {
      cursor: pointer;
      display: block;
      margin-right: 10px;
      // padding-bottom: 20%;
      width: 60px;
      height: 60px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__text {
      @include font-style($c-white, 14, bold, 0.8px, 26px);
      @include word-ellipsis(2)
    }

    &__sub-text {
      @include font-style($c-white, 14, bold, 0.8px, 26px);
      @include word-ellipsis()
    }

    &__extra {
      @include font-style($c-white, 14, normal, 0.8px, 26px);
    }
  }

  &__function {
    flex: 1;
  }
}
</style>
