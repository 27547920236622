<template>
  <div class="c-share-playlists function-icon" @click="share">

    <tooltip :label="type === 'PLAYLIST' ? $t('myMusic.sharePlaylist'): $t('myMusic.shareAlbum')">
      <div class="function-icon" >
        <div class="function-icon__img function-icon__img--share-playlists"></div>
        <span class="function-icon__label" v-if="label"> {{type === 'PLAYLIST' ?  $t('myMusic.sharePlaylist'): $t('myMusic.shareAlbum')}} </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <model-base-layout :title="type === 'PLAYLIST' ?  $t('myMusic.sharePlaylist'): $t('myMusic.shareAlbum')" decoLine>
        <share-playlists-content :data="data" :type="type" @close="close"/>
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal } from 'naive-ui';
import SharePlaylistsContent from '@/components/Global/Popup/SharePlaylistsContent.vue';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'SharePlaylists',
  components: {
    NModal,
    ModelBaseLayout,
    SharePlaylistsContent,
    Tooltip,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    label: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },

  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();
    const modelStatus = ref(false);

    const share = () => {
      modelStatus.value = true;
    };

    const close = () => {
      modelStatus.value = false;
    };

    return {
      modelStatus,
      share,
      close,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
