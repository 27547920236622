<template>
  <div>
    <ul class="content-row aaa" :class="{ 'content-row--remain-row-height': remainRowHeight }">
      <li class="content-row__item" :class="[`content-row__item--${col.type}`]"
        :style="{ flex: col.width ? 'none' : '1', width: col.width, 'text-align': col.align }"
        v-for="(col, idx2) of columns" :key="col.key">

        <template v-if="col.type === 'label'">
          <div class="content-row__item__cell" :class="[{ 'content-row__item__cell--padding-left': idx2 === 0 }]">
            <template v-if="col.render">
              <component :is="col.render" v-bind="{ data, column: col, idx: idx1, expandStatus }"
                @expand="handleExpand" />

            </template>
            <template v-else>
              <!-- {{data[col.key]}} -->
              <!-- <span class="content-row__item__text">{{ data[col.key] }}</span> -->
              <span class="content-row__item__text">{{ data[col.key] }}</span>
              <!-- <span class="content-row__item__text">{{ col.i18nKey ? $t(data[col.i18nKey] || '') : data[col.key] }}</span> -->

            </template>
          </div>
        </template>
        <template v-if="col.type === 'checkbox'">
          <div class="content-row__item__checkbox" v-if="(col.for === 'musicPlayer' && showCheckbox)">
            <n-checkbox v-model:checked="isCheck" />
          </div>
        </template>
        <template v-if="col.type === 'action'">
          <img src="@/assets/myMusic/icon_more.svg" />
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  defineComponent, ref, inject, computed,
  watch,
} from 'vue';
import { NCheckbox } from 'naive-ui';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';

export default defineComponent({
  name: 'VersionQueueListContentRow',
  components: {
    NCheckbox,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    columns: {
      type: Object,
      default: () => ({}),
    },

    idx1: {
      type: Number,
    },
    remainRowHeight: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isCheck = ref(false);
    const expandStatus = ref(true);
    // const musicPlayerStore = useMusicPlayerStore();
    const newMusicPlayerStore = useNewMusicPlayerStore();

    const handleExpand = () => {
      expandStatus.value = !expandStatus.value;
    };

    const globalCheckStatus = inject('isCheckAll');
    const checkStatus = computed(() => globalCheckStatus.value);

    const showCheckbox = computed(() => {
      if (newMusicPlayerStore.currentPlayTrackInfo?.track?.trackUid === props.data?.track?.trackUid) return false;
      return true;
    });

    watch(checkStatus, (newVal) => {
      isCheck.value = newVal;
    });

    const globalResetKey = inject('resetKey');
    const resetKey = computed(() => globalResetKey.value);

    watch(resetKey, () => {
      isCheck.value = false;
    });

    watch(isCheck, (v) => {
      if (v) {
        newMusicPlayerStore.addIntoSelectTrackList(props.data.track.trackUid);
      } else {
        newMusicPlayerStore.removeFromSelectTrackList(props.data.track.trackUid);
      }
    });

    return {
      handleExpand,
      expandStatus,
      isCheck,
      checkStatus,
      showCheckbox,
    };
  },
});
</script>
<style lang="scss" scoped>
.content-row {
  width: 100%;
  height: 52px;
  @include flex();

  &--remain-row-height {
    height: 78px;
  }

  &__item {
    @include flex();
    @include padding(20px 0);

    &__text {
      @include font-style($c-white, 14, normal, 0.8px, 26px);
    }

    &__cell {
      width: 100%;

      &--padding-left {
        width: 100%;
      }
    }

    &__checkbox {
      width: 100%;
      @include flex(center);
    }
  }
}

@media screen and (min-width: $pc) {
  .content-row {
    height: 78px;
  }
}
</style>
