<template>
  <div class="c-play-pause">

    <template v-if="type === 'musicPlayer'">
      <img class="player-btns player-btns--music-player" src="@/assets/icon/playbar_play.svg" v-show="!isPlay"
        @click="handlePlay" />
      <img class="player-btns player-btns--music-player" src="@/assets/icon/playbar_pause.svg" v-show="isPlay"
        @click="handleMusicPlayerPlayStatus" />
    </template>
    <template v-else>
      <img class="player-btns" src="@/assets/icon/playbar_play_grey.svg"
        v-if="['album', 'searchAlbum', 'searchPlaylist', 'memberMyTracks'].includes(type)" @click="handlePlay" />
      <img class="player-btns" src="@/assets/icon/playbar_play_grey.svg"
        v-else-if="!isCurrentTrack && !['album', 'searchAlbum', 'searchPlaylist'].includes(type)" @click="handlePlay" />
      <img class="player-btns" src="@/assets/icon/playbar_pause_grey.svg" v-else-if="isCurrentTrack && isPlay"
        @click="handleMusicPlayerPlayStatus" />
      <img class="player-btns" src="@/assets/icon/playbar_play_grey.svg" v-else-if="isCurrentTrack && !isPlay"
        @click="handlePlay" />
    </template>
  </div>
</template>
<script>
import {
  defineComponent, computed,
  // watch
} from 'vue';
// import { useMessage } from 'naive-ui';
// import { v4 as uuid } from 'uuid';
// import { useI18n } from 'vue-i18n';
// import useRequest from '@/hooks/useRequest';
// import { getAlbumAPI } from '@/api/getAlbums';
// import { getPlaylistAPI } from '@/api/getPlaylists';
// import { getAccountPlaylistAPI } from '@/api/myMusicPlaylists';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useNewMusicPlayerStore } from '@/store/newMusicPlayer';
import useMusicPlayer from '@/hooks/useMusicPlayer';
// import trackWaveSource from '@/utils/trackWaveSource';

export default defineComponent({
  name: 'PlayPause',
  props: {
    data: {
      type: Object,
      default: () => { },
    },
    styleType: {
      type: String,
      default: 'circle',
    },
    color: {
      type: String,
      default: 'grey',
    },
    type: {
      type: String,
      defualt: 'playlist', // 'playlists', 'albums', 'tracks', 'musicPlayer', 'queue', 'theme', 'versions'
    },
    playType: {
      type: String,
      default: 'track',
    },
  },
  setup(props) {
    // const { t } = useI18n();
    // const { stopMusicPlayer,
    // playMusic,
    //  handleFromExternalTrack
    // } = useMusicPlayer();
    // const message = useMessage();
    // const musicPlayerStore = useMusicPlayerStore();
    // const { request: reqAlbum /* , response: albumData */ } = useRequest(getAlbumAPI);
    // const { request: reqAccountPlaylist, response: accountPlaylistData } = useRequest(getAccountPlaylistAPI);
    // const { request: reqPlaylist } = useRequest(getPlaylistAPI);
    const newMusicPlayerStore = useNewMusicPlayerStore();

    const isPlay = computed(() => {
      return newMusicPlayerStore.playControll.play;
    });

    const currTrackuid = computed(() => {
      return props.data?.trackUid || props.data?.originUid || props.data?.track?.trackUid;
    });

    const isCurrentTrack = computed(() => {
      const trackUid = props.data?.trackUid || props.data?.originUid || props.data?.track?.trackUid;
      return newMusicPlayerStore.currentPlayTrackInfo?.track?.trackUid === trackUid;
    });

    // 播放曲目時
    const playTrack = () => {
      console.log('newMusicPlayerStore', newMusicPlayerStore);
      const trackUid = props.data?.trackUid || props.data?.originUid || props.data.track.trackUid;
      console.log('trackUid', trackUid);
      // newMusicPlayerStore.fetchTrackFile(trackUid);
      newMusicPlayerStore.handleTrack(trackUid);
    };

    const playAlbum = () => {
      const albumUid = props.data?.albumUid || props.data?.album?.albumUid;
      newMusicPlayerStore.handleAlbum(albumUid);
    };

    // 點選播放器上的開關時
    const handleMusicPlayerPlayStatus = () => {
      console.log(isPlay.value);
      newMusicPlayerStore.setControllPlayerStatus(!isPlay.value);
    };

    const playQueueTrack = () => {
      const { trackUid } = props.data.track;
      newMusicPlayerStore.handleQueueTrack(trackUid);
    };

    const playMobileQueueTrack = () => {
      const { trackUid } = props.data;
      newMusicPlayerStore.handleQueueTrack(trackUid);
    };

    const playVersion = () => {
      const { trackUid } = props.data.track;
      newMusicPlayerStore.handleVersionTrack(trackUid);
    };

    const playMobileVersion = () => {
      const { trackUid } = props.data.track;
      newMusicPlayerStore.handleVersionTrack(trackUid);
    };

    const playSearchTrack = () => {
      const { trackUid } = props.data;
      console.log('trackUid', trackUid);
      newMusicPlayerStore.handleSearchTrack(trackUid);
    };

    const playPlaylist = () => {
      const { playlistUid } = props.data;
      console.log('playlistUid', playlistUid);
      newMusicPlayerStore.handlePlaylist(playlistUid);
    };

    const playVersionInTrack = () => {
      const { trackUid } = props.data;
      console.log('trackUid', trackUid);
      console.log('playVersionInTrack');
      newMusicPlayerStore.handlesVersionInTrack(trackUid);
    };

    const playMemberMyTracks = () => {
      const { accountPlaylistUid } = props.data;
      console.log('accountPlaylistUid', accountPlaylistUid);
      newMusicPlayerStore.handleMemberMyTracks(accountPlaylistUid);
    };

    const playMemberMyAlbum = () => {
      const { accountPlaylistUid } = props.data;
      newMusicPlayerStore.handleMemberMyAlbum(accountPlaylistUid);
    };

    const handlePlay = () => {
      newMusicPlayerStore.toggleShowMusicPlayer(true);

      const playStrategies = {
        track: playTrack,
        musicPlayer: handleMusicPlayerPlayStatus,
        album: playAlbum,
        queueTrack: playQueueTrack,
        mobileQueueTrack: playMobileQueueTrack,
        version: playVersion, // Version 播放清單中
        mobileVersion: playMobileVersion,
        searchTrack: playSearchTrack,
        searchAlbum: playAlbum,
        playlist: playPlaylist,
        searchPlaylist: playPlaylist,
        versionInTrack: playVersionInTrack,
        memberMyTracks: playMemberMyTracks,
        memberMyAlbum: playMemberMyAlbum,
      };

      playStrategies[props.type]();
    };

    // const handlePlayFromMusicPlayer = () => {
    //   if (!musicPlayerStore.info && !props.data?.albumInfo) return;
    //   playMusic(musicPlayerStore.info || props.data?.albumInfo);
    // };

    // const handlePlayFromQueue = () => {
    //   playMusic(props.data?.albumInfo);
    // };

    // const handlePlayVersion = () => {
    //   playMusic({ ...props.data.albumInfo, isMasterVersion: false });
    // };

    // const handlePlayList = (accountPlaylistUid) => {
    //   if (!accountPlaylistUid) return;
    //   reqAccountPlaylist({ in: accountPlaylistUid });
    // };

    // const isPlaying = computed(() => {
    //   switch (props.type) {
    //     case 'musicPlayer':
    //       return musicPlayerStore.info?.musicFileUrl && (musicPlayerStore.controll.isPlaying || musicPlayerStore.controll.audioElePlayStatus);
    //     case 'versions':
    //       return (
    //         musicPlayerStore.controll.isPlaying && musicPlayerStore.info.trackUid === props.data?.albumInfo?.trackUid
    //       );
    //     case 'queue':
    //       return (
    //         musicPlayerStore.controll.isPlaying &&
    //         musicPlayerStore.info.uuid === props.data?.albumInfo?.uuid &&
    //         musicPlayerStore.info.isMasterVersion
    //       );
    //     case 'playlist':
    //     case 'tag':
    //     case 'theme':
    //     case 'label':
    //       return (
    //         (musicPlayerStore.controll.isPlaying || musicPlayerStore.controll.audioElePlayStatus) &&
    //         musicPlayerStore.info.trackUid === (props.data?.trackUid || props.data.originUid)
    //       );
    //     case 'searchPlaylistTrack':
    //       return (
    //         musicPlayerStore.controll.isPlaying &&
    //         musicPlayerStore.info.trackUid === props.data.track.trackUid
    //       );

    //     default:
    //       return false;
    //   }
    // });

    // const addToSingleTrackToQueue = (track) => {
    //   let trackData = props.data;

    //   if (track) {
    //     trackData = track;
    //   }

    //   musicPlayerStore.handleAddToPlayQueue([
    //     {
    //       duration: trackData.duration || trackData.description,
    //       id: `${trackData.albumCode || trackData.note || trackData.album?.albumCode}${trackData.trackNumber ? `-${trackData.trackNumber}` : ''
    //       }`,
    //       title: trackData.trackTitleDisplay || trackData.targetTitle,
    //       trackUid: trackData.trackUid || trackData.trackUid,
    //       artworkInfo: trackData.artworkInfo,
    //       albumInfo: {
    //         isMasterVersion: trackData.isMasterVersion,
    //         albumCoverUrl: trackData.artworkInfo,
    //         albumName: trackData.albumTitleDisplay,
    //         albumUid: trackData.albumUid,
    //         songName: trackData.trackTitleDisplay,
    //         creator: trackData.albumCode || trackData.album?.albumCode,
    //         duration: trackData.duration,
    //         trackUid: trackData.trackUid,
    //         trackNumber: trackData.trackNumber,
    //         trackWave: trackWaveSource(trackData.trackWave || trackData.artworkInfo),
    //         // trackWave: trackWaveSource('point-group-public-resource/wave/VT035_67_Electrosonic_IDs_And_Logos_#3(main).png'),
    //         // v
    //         // trackWave: 'https://storage.googleapis.com/point-group-public-resource/wave/AUTH001_001_Let_Em_Loose(Full_Mix).png',
    //         // x
    //         // trackWave: 'https://storage.googleapis.com/point-group-public-resource/wave/AUTH001_001_Let_Em_Loose%28Full_Mix%29.png',
    //         // x
    //         // trackWave: 'https://storage.googleapis.com/point-group-public-resource/wave/VT035_67_Electrosonic_IDs_And_Logos_#3(main).png',
    //         // x
    //         // trackWave: 'https://storage.googleapis.com/point-group-public-resource/wave/VT035_67_Electrosonic_IDs_And_Logos_%233(main).png',
    //         // x
    //         // trackWave: `https://storage.googleapis.com/${escape('point-group-public-resource/wave/VT035_67_Electrosonic_IDs_And_Logos_#3(main).png')}`,
    //         // x
    //         // trackWave: 'https://storage.googleapis.com/point-group-public-resource/wave/VT035_67_Electrosonic_IDs_And_Logos_%233%28main%29.png',

    //         uuid: uuid(),
    //         isrc: props.data.isrc || '',
    //         iswc: props.data.isrc || '',
    //         labelCode: props.data.label?.labelCode || props.data.publisherAndLabel ? props.data.publisherAndLabel[0]?.label?.labelCode : '',
    //       },
    //     },
    //   ]);

    //   handlePlayFromQueue();
    // };

    // const addToQueue = (tracks) => {
    //   console.log('2', props.data);

    //   // 加入整張專輯
    //   if ((props.data?.tracks && props.data?.tracks.length) || tracks) {
    //     const trackList = props.data?.tracks || tracks;
    //     musicPlayerStore.handleAddToPlayQueue(
    //       trackList.map((item) => ({
    //         duration: item?.duration,
    //         id: `${item?.albumCode}-${item?.trackNumber}`,
    //         title: item?.trackTitleDisplay,
    //         trackUid: item?.trackUid,
    //         artworkInfo: item?.artworkInfo,
    //         albumInfo: {
    //           isMasterVersion: item?.isMasterVersion,
    //           albumCoverUrl: props.data?.artworkInfo || item?.artworkInfo,
    //           albumName: props.data?.albumTitleDisplay,
    //           albumUid: props.data?.albumUid || item?.albumUid,
    //           songName: item?.trackTitleDisplay,
    //           creator: item?.albumCode,
    //           duration: item?.duration,
    //           trackUid: item?.trackUid,
    //           trackWave: trackWaveSource(item?.trackWave),
    //           trackNumber: item?.trackNumber,
    //           uuid: uuid(),
    //           isrc: props.data.isrc || '',
    //           iswc: props.data.isrc || '',
    //           labelCode: props.data.label?.labelCode || props.data.publisherAndLabel ? props.data.publisherAndLabel[0]?.label?.labelCode : '',
    //         },
    //       })),
    //     );

    //     message.success(t('global.startPlaying'));
    //     handlePlayFromQueue();
    //     return;
    //   }
    //   // 加入單首歌曲
    //   addToSingleTrackToQueue();
    //   // message.success('加入成功');
    // };

    // const getAlbumDataToQueue = async (albumUids = []) => {
    //   console.log('albumUids', albumUids);

    //   const albumReqList = albumUids.map((albumUid) => reqAlbum({ in: albumUid }));
    //   const albumResList = await Promise.all(albumReqList);
    //   const tracks = albumResList.reduce((acc, item) => {
    //     acc.push(
    //       ...item?.[1]?.getAlbum?.tracks.map((trackItems) => ({
    //         ...trackItems,
    //         albumUid: item?.[1]?.getAlbum?.albumUid,
    //       })),
    //     );
    //     return acc;
    //   }, []);

    //   console.log('tracks', tracks);
    //   addToQueue(tracks);
    // };

    // const getPlaylistDataToQueue = async (playlistUid) => {
    //   const [, res] = await reqPlaylist({
    //     playlistUid,
    //     relation: true,
    //   });

    //   const tracks = res.getPlaylist.tracks.map((item) => ({
    //     ...item.track,
    //     ...item.album,
    //   }));

    //   addToQueue(tracks);
    // };

    // watch(accountPlaylistData, async (v) => {
    //   const [, res] = v;
    //   if (!res.getAccountPlaylist) return;
    //   if (props.type === 'playListTrack') {
    //     const tracks = res.getAccountPlaylist.accountPlaylistContents.map(({ track }) => ({
    //       ...track,
    //       albumUid: track?.album?.albumUid,
    //     }));
    //     addToQueue(tracks);
    //     return;
    //   }
    //   const albumUids = res.getAccountPlaylist.accountPlaylistContents.map(({ album }) => album?.albumUid);
    //   console.log('albumUids', albumUids);

    //   getAlbumDataToQueue(albumUids);
    // });

    // const actionAssign = () => {
    //   switch (props.type) {
    //     case 'musicPlayer':
    //       handlePlayFromMusicPlayer();
    //       break;
    //     case 'queue':
    //       handlePlayFromQueue();
    //       break;
    //     case 'tag':
    //       handleFromExternalTrack(props.data.trackUid);
    //       break;
    //     case 'theme':
    //       handleFromExternalTrack(props.data.originUid);
    //       break;
    //     case 'versions':
    //       handlePlayVersion();
    //       break;
    //     case 'playListTrack':
    //     case 'playListAlbum':
    //       handlePlayList(props.data?.accountPlaylistUid);
    //       break;
    //     case 'playListAlbumUid':
    //       getAlbumDataToQueue([props.data?.album?.albumUid]);
    //       break;
    //     case 'searchAlbum':
    //       getAlbumDataToQueue([props.data?.albumUid]);
    //       break;
    //     case 'searchPlaylist':
    //       getPlaylistDataToQueue(props.data?.playlistUid);
    //       break;
    //     case 'searchPlaylistTrack':
    //       addToSingleTrackToQueue({
    //         albumUid: props.data.track.album.albumUid,
    //         ...props.data.track,
    //       });
    //       break;
    //     default:

    //       addToQueue();
    //       break;
    //   }
    // };

    return {
      isPlay,
      handlePlay,
      // isPlaying,
      // actionAssign,
      // stopMusicPlayer,
      handleMusicPlayerPlayStatus,
      isCurrentTrack,
      // musicControll: computed(() => musicPlayerStore.controll),
      currTrackuid,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';

.c-play-pause {
  display: inline-block;
  color: black;
}

.player-btns {
  cursor: pointer;

  &--music-player {
    width: 30px;
  }
}
</style>
