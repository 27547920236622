export default {
  about: '音韶是全台湾最大的版权音乐代理商，汇集超过百万首的音乐曲库，为您提供最专业及优质的服务。',
  explore: '搜索属于 您的灵感',
  hot: {
    title: '热门推荐',
    Albums: '推荐专辑',
    Playlists: '推荐歌单',
    Genres: '推荐精选曲风',
    Moods: '推荐精选气氛',
  },
  inherit: {
    title: '让音乐延续下去',
    text: '免费注册立即试听音乐，建立您专属的音乐收藏，创造感动体验。',
  },
  sloganA: '音韶为台湾最大版权音乐代理商，汇集超过百万首音乐，为您提供多样化选择，',
  sloganB: '齐全、丰富的音乐，可满足各类需求。',
  learnMore: '查看更多',
};
