export default {
  accountData: '帐号资料',
  changePw: '更改密码 ',
  preferSetting: '偏好设定 ',
  pointClient: '音韶客服',
  memberType: '会员类型',
  downloadAuth: '下载权限',
  downloadCount: '已下载数量/可下载数量',
  contractPeriod: '契约起讫期间',
  emailNotice: '电子邮箱即为您的登录帐号，如有异动需求请联系我们',
  verified: '已验证',
  unverify: '未验证',
  sendVerifyEmail: '发送验证信',
  verifyEmailSent: '验证信已发送',
  accountModifySuccess: '帐号资料修改成功',
  changePwSuccess: '密码更改成功',
  defaultDownloadExt: '下载格式',
  searchResultPrefer: '搜寻结果偏好',
  wave: '波形',
  desc: '描述',
  tag: '标签',
  csNotice1: '如果您有任何疑问，请联系下列专属客服。或前往',
  csNoticeContact: '联络我们',
  csNotice2: '留下您的问题，我们会尽快与您联络！',
  cs: '客服',
  saveSuccess: '储存成功',
  notSupportMobile: '手机不支援此页面',
  modify: '更换',
  formerMember: '正式会员',
  normalMember: '一般会员',
  inReview: '审核中',
  unverified: '尚未验证',
  waitForAudit: '待审核',
  fileLimit1mb: '档案大小不得超过 1MB',
  updateAvatarSuccess: '更新头像成功',
  myAccount: '我的帐号',
  downloadPeriod: '限制下载期间',
  unlimited: '无限制',
  askingDownloadFormat: '格式询问',
  downloadAlbum: '下载专辑',
  downloadPlaylist: '下载歌单',
  downloadTrack: '下载曲目',
  selectDownloadFormat: '请选择格式',
  downloadFormat: '下载格式',
  musicPlayerStyle: '播放器预设样式',
  musicPlayerStyleFull: '完整',
  musicPlayerStyleSimple: '简易',
  musicPlayerStyleCircle: '象征（收起小圆）',
};
