import { createI18n } from 'vue-i18n';
import en from '@/i18n/en';
import zhTw from '@/i18n/zh-tw';
import zhCn from '@/i18n/zh-cn';
import cusStorage from '@/utils/cusStorage';

const i18nData = {
  en,
  'zh-tw': zhTw,
  'zh-cn': zhCn,
};

// 其他語言後台未設定完成，怕資料殘缺，先不跟瀏覽器，待後台處理完成把下面註解打開
const lang = (() => {
  const usedLang = cusStorage.getItem('lang');
  if (usedLang) {
    return usedLang;
  }

  // const browserLang = navigator.language.toLocaleLowerCase();

  // // 英語系走這 en, en-us, en-gb ... etc.
  // if (browserLang.includes('en')) {
  //   return 'en';
  // }

  // // 對面來的走這
  // if (browserLang === 'zh-cn') {
  //   return 'zh-cn';
  // }

  return 'zh-tw';
})();

cusStorage.setItem('lang', lang);

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: lang, // set locale
  fallbackLocale: 'zh-tw', // set fallback locale
  messages: i18nData,
});

// console.log('i18n', i18n.global.t('home.learnMore'));

export default i18n;
export { i18nData };
