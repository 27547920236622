export default {
  title: 'License',
  cookiesTitle: 'COOKIES Policy',
  termsTitle: 'Terms of Use',
  privacyTitle: 'Privacy Policy',
  customization: 'Customize Service',
  contents: [
    {
      title: 'License',
      desc: [
        'Over millions of premium quality music, advanced search engine, flexible licensing methods, meet your budgets.',
      ],
    },
    {
      title: 'NeedleDrop',
      desc: [
        'We offer the largest music library with wide varieties of genres to enrich your projects. Search and Listen immediately, make your works perfectly!',
      ],
    },
    {
      title: 'Blanket License',
      desc: [
        'We offer diverse annual licensing (albums or tracks) for TV station, broadcast facilities, recording studios, games and social media.',
      ],
    },
    {
      title: 'Project License',
      desc: ['We tailor made licensing bundle for Documentary Films, Variety Shows, Dramas, Corporate Films, Audible Stories, Big Screen Films, Podcast and much more. Contact us for more details.'],
    },
    {
      title: 'Sound Effect',
      desc: ['Looking for competitive sound effect to push your projects to the next level? We offer wide varieties of Sound Effects just for one time payment, contact us for more details.'],
    },
    {

      title: 'In Store Music',
      desc: [
        'Right music playing in storefront lets customers to enjoy their experience while they shop. We offer music for different shopping environments such as sports, vogues, children, holidays, special occasion, to make your shopping place a second to none.',
      ],
    },
    {
      title: 'Customized Composing Service',
      desc: [
        'Our Award Winning Composers are here to tailor made for your Opening Sequences, Dramas, Corporate Jingles, Games, Commercials, Songs.',
      ],
    },
    {
      title: 'Point Music will cooperate with Music Streaming Service for personal offline listening.',
      desc: [
        'As a music collection enthusiast, we\'ve heard you. We offer wide varieties of trendy music playlists on major Music Stream Services, just subscribe and download them all to create your personal ring tones.',
      ],
    },
  ],
};
