export default {
  tags: '标签',
  tracks: '曲目',
  albums: '专辑',
  playlists: '歌单',
  lyrics: '歌词',
  composers: '作曲者',
  filterCondtions: '筛选条件',
  length: '长度',
  availableTags: '可选择标签',
  removeAll: '清除全部',
  searchType: '搜索类别',
  firstLayer: {
    instruments: '乐器',
    tempos: '速度',
    countryList: '国家',
    editType: '版本长度',
    versionType: '版本类型',
    musicStyles: '曲风',
    moods: '气氛',
    musicFor: '音乐用途',
    eras: '年代',
    albumCompany: '品牌',
  },
  searchConditionChange: '搜寻条件已变更，请重新执行搜寻',
  pleaseFillSearchCondition: '请填写/选择搜寻条件',
  noData: '查无资料',
  searchResult: '搜寻结果',
  pleaseEnterKeyword: '请输入关键字',
  selectedTags: '已选择标签{count}个',
};
