import { computed } from 'vue';

export default (props) => {
  // 上架狀態
  const isOnShelfStatus = computed(() => {
    switch (props?.offShelfConfig.type) {
      case 'TRACK':
        return props?.data?.track?.status === 'Activate';
      case 'ALBUM':
        return props?.data?.album?.status === 'Activate';
      case 'VERSION':
        return props?.data?.status === 'Activate';
      case 'NOTE':
        return props?.data?.status;
      default:
        return true;
    }
  });

  return {
    isOnShelfStatus,

  };
};
