import { defineStore } from 'pinia';
import { isEmpty } from 'lodash-es';
import cusStorage from '@/utils/cusStorage';

export const useMusicPlayerStore = defineStore({
  id: 'musicPlayer',
  state: () => ({
    played: false, // 是否觸發播放過
    playFromSpecificPosition: {
      status: false,
      position: 0,
    },
    // 現正播放
    info: {
      albumCoverUrl: '',
      // albumCoverUrl: image,
      musicFileUrl: '',
      albumName: '',
      songName: '',
      singer: '',
      creator: '',
      trackUid: '',
      trackWave: '',
      trackNumber: '',
      duration: '',
      uuid: null,
      isMasterVersion: true,
      label: [],
      isrc: '',
      iswc: '',
    },
    controll: {
      isPlaying: false,
      currentTime: 0, // 正在播放到的位置
      volume: {
        progressPersentage: 100, // 現在播放位置百分比
      },

      // currentTimeLength: '00', // 正在播放到的位置換算秒數
      // totalTime: '00:00', // 整首歌長度
      // totalTimeLength: '0', // 整首歌長度換算秒數
      // progressPersentage: '0', // 現在播放位置百分比
      audioElePlayStatus: false,
    },
    selected: [],
    forceToTime: 0,
    forceToVolume: 100,
    lastStoppedAt: 0,
    shareTrackUid: '',
    shareTrack: {
      trackUid: '',
      albumUid: '',
      startAt: null,
    },
    track: {
      version: '0',
      // queue: '0',
    },
    testing: 'aaa',
    footerBottomExtraPaddingType: 'circle',

    // 播放佇列
    playQueue: [],
    // 版本
    versions: [],
    musicPlayerStyle: 'circle',
  }),

  actions: {
    init() {
      const info = cusStorage.getItem('info');
      if (!isEmpty(info)) this.info = info;
      const controll = cusStorage.getItem('controll');
      if (!isEmpty(controll)) this.controll = { ...controll, isPlaying: false };
      const playQueue = cusStorage.getItem('playQueue');
      if (playQueue?.length) this.playQueue = playQueue;
      const versions = cusStorage.getItem('versions');
      if (versions?.length) this.versions = versions;
      this.lastStoppedAt = cusStorage.getItem('lastStoppedAt') || 0;
      this.controll.audioElePlayStatus = false;
    },
    setPlayFromSpecificPosition(status, position) {
      this.playFromSpecificPosition = {
        status,
        position,
      };
    },
    setAudioElePlayStatus(status) {
      this.controll.audioElePlayStatus = status;
    },
    setMusicFile(url) {
      this.info.musicFileUrl = url;
      cusStorage.setItem('info', this.info);
    },
    setShareData(type, data) {
      this.shareTrack[type] = data;
    },
    setShareStartAt(sec) {
      this.shareTrack.startAt = sec;
    },
    handleSelect(uuid, status) {
      if (this.selected.includes(uuid) && !status) {
        this.selected = this.selected.filter((item) => item !== uuid);
        return;
      }
      this.selected.push(uuid);
    },
    handleSetInfo(data) {
      this.info = data;

      cusStorage.setItem('info', data);
      cusStorage.setItem('controll', { ...this.controll });
    },
    handleAddToVersions(list = []) {
      this.versions = list;
      cusStorage.setItem('versions', this.versions);
    },
    handleAddToPlayQueue(list) {
      this.playQueue = list;
      this.track.queue = list?.length || 0;
      cusStorage.setItem('playQueue', this.playQueue);
    },
    handleAppendToPlayQueue(list) {
      const { id } = list[0];
      const isInQueue = this.playQueue.find((item) => item.id === id);
      if (isInQueue) {
        window.$message.error('該歌曲已在播放佇列');
        return;
      }

      this.playQueue = [...this.playQueue, ...list];
      this.track.queue = list?.length || 0;
      cusStorage.setItem('playQueue', this.playQueue);
      window.$message.success('加入成功');
    },
    handleRemoveFromPlayQueue(uuid) {
      // console.log('uuid', uuid);
      if (typeof uuid === 'string') {
        this.playQueue = this.playQueue.filter((item) => item.albumInfo.uuid !== uuid);
      }

      if (uuid?.length) {
        // console.log('this.playQueue1', this.playQueue);
        this.playQueue = this.playQueue.filter((item) => !uuid.includes(item.albumInfo.uuid));
        // console.log('this.playQueue2', this.playQueue);
        this.selected = [];
      }
      cusStorage.setItem('playQueue', this.playQueue);
    },
    handlePlayStatus(status) {
      // console.log('status', status);
      if (status) this.played = true;
      this.controll.isPlaying = status;
      // console.log('this.controll', this.controll);
      cusStorage.setItem('controll', this.controll);
    },
    handlePrevNext(request, type, isEnded, accountUid = '') {
      const currentIndex = this.playQueue.findIndex((item) => item.albumInfo.uuid === this.info.uuid);
      const targetIndex = { prev: currentIndex - 1, next: currentIndex + 1 }[type];
      if (currentIndex !== -1 && this.playQueue[targetIndex]) {
        this.handlePlayStatus(false);
        this.handleSetInfo({ ...this.playQueue[targetIndex].albumInfo });
        request({ trackUid: this.playQueue[targetIndex].albumInfo.trackUid, accountUid });
        if (isEnded) {
          this.playQueue.splice(currentIndex, 1);
          cusStorage.setItem('playQueue', this.playQueue);
        }
      }
    },
    handlePrev(request, accountUid) {
      this.handlePrevNext(request, 'prev', false, accountUid);
    },
    handleNext(request, isEnded, accountUid) {
      this.handlePrevNext(request, 'next', isEnded, accountUid);
    },
    setLastStoppedAt(time) {
      this.lastStoppedAt = time;
      cusStorage.setItem('lastStoppedAt', time);
    },
    setCurrentTime(time) {
      // console.log('???');
      this.controll.currentTime = time;
      cusStorage.setItem('controll', this.controll);
    },
    setFooterBottomExtraPaddingType(type) {
      this.footerBottomExtraPaddingType = type;
    },
    setForceToTime(sec) {
      this.forceToTime = sec;
    },
    setForceToVolumn(num) {
      this.forceToVolume = num;
      cusStorage.setItem('controll', {
        ...this.controll,
        volume: {
          progressPersentage: num * 100,
        },
      });
    },
    handleClearMusicPlayer() {
      this.handlePlayStatus(false);
      cusStorage.removeItem('info');
      cusStorage.removeItem('controll');
      cusStorage.removeItem('playQueue');
      cusStorage.removeItem('versions');
      this.handleSetInfo({});
      this.handleAddToPlayQueue([]);
      this.handleAddToVersions([]);
    },
    setMusicPlayerStyle(type) {
      this.musicPlayerStyle = type;
    },
  },
});

export const a = '';
