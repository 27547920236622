export default [
  {
    path: '/my-profile',
    component: () => import(/* webpackChunkName: "LayoutWithHeader" */ '@/layout/LayoutWithHeader.vue'),
    redirect: '/my-profile/personal-details',
    meta: {
      title: 'my-profile',
      key: 'my-profile',
    },
    children: [
      {
        path: 'personal-details',
        name: 'my-profile-personal-details',
        component: () => import(/* webpackChunkName: "PersonalDetails" */ '@/views/MyProfile/PersonalDetails.vue'),
        meta: {
          auth: true,
          title: '帳戶資料',
          titleI18nKey: 'member.accountData',
          layout: {
            header: 'my-profile',
          },
        },
      },
      {
        path: 'change-password',
        name: 'my-profile-change-password',
        component: () => import(/* webpackChunkName: "ChangePassword" */ '@/views/MyProfile/ChangePassword.vue'),
        meta: {
          auth: true,
          title: '更改密碼',
          titleI18nKey: 'member.changePw',
          layout: {
            header: 'my-profile',
          },
        },
      },
      {
        path: 'my-prefer',
        name: 'my-profile-my-prefer',
        component: () => import(/* webpackChunkName: "MyPrefer" */ '@/views/MyProfile/MyPrefer.vue'),
        meta: {
          auth: true,
          title: '偏好設定',
          titleI18nKey: 'member.preferSetting',
          layout: {
            header: 'my-profile',
          },
        },
      },
      {
        path: 'customer-service',
        name: 'my-profile-customer-service',
        component: () => import(/* webpackChunkName: "CustomerService" */ '@/views/MyProfile/CustomerService.vue'),
        meta: {
          auth: true,
          title: '音韶客服',
          titleI18nKey: 'member.pointClient',
          layout: {
            header: 'my-profile',
          },
        },
      },
    ],
  },
];
