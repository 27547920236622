<template>
  <transition name="fade">
    <div class="re-dialog" v-show="visible" @click.self="closeDialog">
      <div
        class="re-dialog__box"
        :class="[
          {
            're-dialog__box--slide-top-in': visible,
            're-dialog__box--slide-top-out': !visible,
          },
        ]"
      >
        <div class="re-dialog__box__body">
          <slot>body content</slot>

          <div class="btn-wrap">
            <!-- <mi-button @click="handleConfirm">{{ formatConfirmBtnText }}</mi-button> -->
            <mi-button @click="handleConfirm">{{ confirmBtnText  }}</mi-button>
            <mi-button @click="close" v-if="showClose" ml>{{ cancelBtnText }}</mi-button>
            <!-- // <pr-button @click="handleCancel">{{ cancelBtnText }}</pr-button> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  defineComponent, watch, getCurrentInstance, ref,
  // computed,
} from 'vue';
// import { useI18n } from 'vue-i18n';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'AsyncDialog',
  components: {
    MiButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    footerPosition: {
      type: String,
      default: 'left',
    },
    template: {
      default: () => {},
    },
    data: {
      dafeult: () => ({}),
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    confirmFn: {
      default: null,
    },
    cancelFn: {
      defualt: null,
    },
    confirmBtnText: {
      type: String,
      default: '',
    },
    callback: {
      type: Function,
      default: () => {},
    },
    isOverlayUseCallback: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    cancelBtnText: {
      type: String,
      default: '',
    },
  },
  emits: ['beforeClose', 'update:visible'],
  setup(props, { emit }) {
    const internalInstance = getCurrentInstance();
    const isExecute = ref(false);
    // const { t } = useI18n();

    const executeCallback = () => {
      if (!isExecute.value) {
        props.callback();
      }
      isExecute.value = true;
    };

    // 有callback
    const closeDialog = (status) => {
      if (props.isOverlayUseCallback && props.callback) {
        executeCallback();
      }

      emit('update:visible', status);
    };

    // 純粹關閉
    const close = () => {
      emit('update:visible', false);
    };

    watch(props.visible, (val) => {
      if (val) {
        document.body.classList.add('freeze-body');
        if (props.appendToBody) {
          document.body.appendChild(internalInstance);
        }
      } else {
        document.body.classList.remove('freeze-body');
      }
    });

    const handleConfirm = () => {
      if (props.callback) {
        executeCallback();
      }
      closeDialog(true);
    };

    // const formatConfirmBtnText = computed(() => {
    //   if (!props.confirmBtnText) {
    //     return t('global.confirm');
    //   }

    //   return props.confirmBtnText;
    // });

    // const handleCancel = () => {
    //   if (props.cancelFn) {
    //     props.cancelFn();
    //   }

    //   closeDialog(false);
    // };

    return {
      closeDialog,
      handleConfirm,
      close,
      // formatConfirmBtnText,
      // handleCancel,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/styles/utils/_transition.scss';

.freeze-body {
  overflow: hidden;
}

.re-dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zi-dialog;
  width: 100%;
  height: 100%;
  background-color: rgba(#333, 0.5);

  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 320px;
    transform: translate(-50%, -50%);
    background-color: $c-white;
    border-radius: 20px;

    &__body {
      /* @include padding(10px); */
      @include padding(50px 75px);
    }
  }
}

.btn-wrap {
  margin-top: 30px;
  @include flex(center);
}

@keyframes slideTopIn {
  from {
    transform: translate(-50%, calc(-50% - 30px));
  }

  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes slideTopOut {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, calc(-50% - 30px));
  }
}
</style>
