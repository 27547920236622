export default {
  tags: 'Tags',
  tracks: 'Tracks',
  albums: 'Albums',
  playlists: 'Playlists',
  lyrics: 'Lyrics',
  composers: 'Composer',
  filterCondtions: 'Filters',
  length: 'Length',
  availableTags: 'Remaining Tags',

  removeAll: 'Remove All',
  searchType: 'Search Type',
  firstLayer: {
    instruments: 'Instruments',
    tempos: 'Tempos',
    countryList: 'Country List',
    editType: 'Edit Type',
    versionType: 'Version Type',
    musicStyles: 'Music Styles',
    moods: 'Moods',
    musicFor: 'Music For',
    eras: 'Eras',
    albumCompany: 'Labels',
  },
  searchConditionChange: 'Search conditions have been changed, please hit search button again.',
  pleaseFillSearchCondition: 'Please select your search conditions.',
  noData: 'No Data',
  searchResult: 'Search Result',
  pleaseEnterKeyword: 'Please Entry Keyword',
  selectedTags: 'Selected Count {count}',
};
