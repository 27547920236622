export default {
  contactUs: '联络我们',
  leaveMessage: '留言给我们',
  contactInfo: '联络资讯',
  name: '用戶名',
  appellation: '性别',
  male: '先生',
  female: '小姐',
  email: '电子邮箱',
  questionType: '问题类型',
  questionContent: '问题内容',
  pleaseEnterEmail: '请输入电子邮箱',
  pleaseEnterQuestion: '请输入问题',
  pleaseSelectQuestionType: '请选择问题类型',
  submit: '送出',
  contact: '联络资讯',
  pointAddress: '105台北市松山区八德路四段165号4楼',
  quesOptAuthz: '音乐授权事宜',
  quesOptPick: '协助挑选音乐',
  quesOptCompose: '定制化作曲',
  quesOptInquiry: '询价',
  quesOptOperate: '网站操作相关问题',
  quesOptOpinion: '意见反馈',
  quesOptScore: '定制化配乐',
  quesOptOther: '其他',
};
