<template>
  <div class="ly-menu-search-pop">
    <img class="ly-menu-search-pop__close" src="@/assets/searchResults/icon_popup_close.svg" @click="closePop" />

    <div class="ly-menu-search-pop__content">
      <search-input isSearchPopMobile showFullMatch @handleSearch="handleSearch" @toSearchPage="toSearchPage" :updateUrl="false" :showDropdown="false"/>

      <div class="tags show-tablet">
        <p class="tags__title">
          <span class="tags__title__text">{{$t('search.tags')}}</span>
          <span class="tags__title__count">({{ tagListLen }})</span>
        </p>
        <div class="tags__wrap">
          <label-item v-for="l of tagList" :label="l" :key="l" isSearchPop searchTagImmediately allowClick @closePop="closePop"/>
        </div>
      </div>

      <div class="tabs">
        <search-tabs :tabs="searchTabs" v-model:activeTab="activeTab" @handleTab="handleTab" isSearchPop />
      </div>

      <div class="search-result">
        <ul class="search-box-list">
          <li class="search-box-list__item" v-for="(d, idx) of showList" :key="idx">
            <div class="search-box-list__item__box">
              <search-box-item :data="d" :type="activeTab" @closePop="closePop"/>
            </div>
          </li>
        </ul>
      </div>

      <div class="btn-wrap">
        <red-circle-button :label="$t('global.learnMore')" label-color="#ffffff" label-bg="#000000" @click="toSearchPage" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { cloneDeep } from 'lodash-es';
import SearchInput from '@/components/SearchResult/SearchInput.vue';
import LabelItem from '@/components/Global/LabelItem.vue';
import SearchBoxItem from '@/components/Global/SearchBoxItem.vue';
import RedCircleButton from '@/components/Home/RedCircleButton.vue';
import SearchTabs from '@/components/SearchResult/SearchTabs.vue';
import { useSiteStore } from '@/store/site';
import { useSearchStore } from '@/store/search';
import { searchAllAPI } from '@/api/search';
// import useGtm from '@/hooks/useGtm';
import useRequest from '@/hooks/useRequest';
import cusStorage from '@/utils/cusStorage';

export default defineComponent({
  name: 'MenuSearchPop',
  components: {
    SearchInput,
    LabelItem,
    SearchBoxItem,
    RedCircleButton,
    SearchTabs,
  },
  emits: ['update:showSearchPop'],
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();
    const searchStore = useSearchStore();
    const { request, response } = useRequest(searchAllAPI);
    const labelWith = ref([]);
    const tagList = ref([]);
    const activeTab = ref('tracks');
    const searchResult = ref({});
    const showList = ref([]);
    // const { handleGtm } = useGtm();

    const localMemberInfo = cusStorage.getItem('memberInfo') || {};
    const accountUid = computed(() => localMemberInfo?.accountUid || siteStore.memberInfo?.accountUid || '');
    const tagListLen = computed(() => (tagList.value.length >= 30 ? '30+' : tagList.value.length));

    const handleSearch = () => {
      const { searchValue } = searchStore;
      const { keyword } = cloneDeep(searchValue);

      request({
        in: {
          accountUid: accountUid.value,
          keyword,
        },
      });
    };

    const searchTabs = ref([
      {
        label: 'TRACKS',
        key: 'tracks',
        i18nKey: 'search.tracks',
        total: 0,
      },
      {
        label: 'ALBUMS',
        key: 'albums',
        i18nKey: 'search.albums',
        total: 0,
      },
      {
        label: 'PLAYLISTS',
        key: 'playlists',
        i18nKey: 'search.playlists',
        total: 0,
      },
    ]);

    watch(response, () => {
      const [, res] = response.value;

      const { albums, tags, tracks, playlists } = res.searchAll;

      // handleGtm('SEARCH', {
      //   searchResultTotal: albums.length + tags.length + tracks.length + playlists.length,
      //   type: 'all',
      // });

      tagList.value = tags.map((item) => {
        const { name, tagGroupName, tagUid } = item;
        return {
          label: tagGroupName ? `${tagGroupName}/${name}` : name,
          tagUid,
        };
      });

      searchResult.value = {
        tracks,
        albums,
        playlists,
      };

      searchTabs.value = searchTabs.value.map((item) => {
        const searchLength = searchResult.value[item.key].length;
        const length = searchLength >= 25 ? '25+' : searchLength;
        item.length = searchLength;
        item.total = length;

        return item;
      });

      const indicateActiveTab = searchTabs.value.find((item) => item.length > 0);
      activeTab.value = indicateActiveTab ? indicateActiveTab.key : 'tracks';

      showList.value = searchResult.value[activeTab.value];
    });

    // const labelWith = [
    //   '勵志 Inspirational',
    //   '孩子般 Childlike',
    //   '快樂/正面 Happy/Positive',
    //   '振奮 Uplifting',
    //   '期望 Expectation',
    //   '溫暖 Warm',
    //   '滿足 Satisfied',
    //   '無憂無慮 Carefree',
    // ];

    const closePop = () => {
      siteStore.toggleShowSearchPop(false);
    };

    const handleTab = (key) => {
      activeTab.value = key;
      showList.value = searchResult.value[activeTab.value];
    };

    const toSearchPage = () => {
      const { keyword } = searchStore.searchValue;

      router.push({
        name: 'search',
        query: {
          type: activeTab.value,
          page: 'search',
          keyword,
        },
      });
      closePop();
    };

    const initSearch = () => {
      const { keyword } = searchStore.searchValue;
      if (keyword) {
        handleSearch();
      }
    };

    onMounted(() => {
      initSearch();
    });

    return {
      activeTab,
      labelWith,
      searchTabs,
      handleTab,
      closePop,
      toSearchPage,
      handleSearch,
      tagList,
      searchResult,
      showList,
      tagListLen,
    };
  },
});
</script>
<style lang="scss" scoped>
.ly-menu-search-pop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: $c-black;
  z-index: $zi-search-pop;

  &__close {
    @include position(tr, 15px, 0px);
    cursor: pointer;
  }

  &__content {
    @include max-width(900);
    margin-top: 90px;
  }
}

.tags {
  margin-top: 50px;
  &__title {
    &__text {
      @include font-style($c-white, 20, normal, 1px, 26px);
    }

    &__count {
      margin-left: 3px;
      @include font-style($c-assist4, 12, normal, 0.6px, 16px);
    }
  }

  &__wrap {
    margin-top: 17px;
  }
}

.tabs {
  margin-top: 50px;
}

.search-result {
  margin-top: 27px;
}

.search-box-list {
  width: 100%;
  @include flex(flex-start, flex-start, column);
  margin: 0 -10px;

  &__item {
    flex: none;
    width: 100%;
    margin-top: 24px;

    & + & {
      margin-top: 20px;
    }

    &__box {
      @include padding(0 10px);
    }
  }
}

.btn-wrap {
  margin-top: 45px;
  margin-bottom: 45px;
  @include flex(center);
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $tablet) {
  .show-mobile {
    display: none;
  }

  .show-tablet {
    display: block;
  }
  .ly-menu-search-pop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $c-black;
    z-index: $zi-search-pop;

    &__close {
      @include position(tr, 15px, 90px);
      cursor: pointer;
    }

    &__content {
      @include max-width(900);
      margin-top: 90px;
    }
  }

  .tags {
    margin-top: 50px;
    &__title {
      &__text {
        @include font-style($c-white, 20, normal, 1px, 26px);
      }

      &__count {
        margin-left: 3px;
        @include font-style($c-assist4, 12, normal, 0.6px, 16px);
      }
    }

    &__wrap {
      margin-top: 17px;
    }
  }

  .tabs {
    margin-top: 50px;
  }

  .search-result {
    margin-top: 27px;
  }

  .search-box-list {
    width: 100%;
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
    margin: 0 -10px;

    &__item {
      flex: none;
      width: 25%;
      margin-top: 24px;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0px;
      }

      &__box {
        @include padding(0 10px);
      }
    }
  }

  .btn-wrap {
    margin-top: 45px;
    @include flex(center);
  }
}
</style>
