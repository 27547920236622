import request from '@/api';
import { getHighlightAlbum, getAlbumList, getAlbum, searchAlbumRelated, getSearchAlbum, getAlbumOnlyTracks } from '@/api/gql/getAlbums.gql';

export const getHighlightAlbumAPI = async (payload) => {
  const res = await request(getHighlightAlbum, payload);

  return res;
};

export const getAlbumListAPI = async (payload) => {
  const res = await request(getAlbumList, payload);

  return res;
};

export const getAlbumAPI = async (payload) => {
  const res = await request(getAlbum, payload);

  return res;
};

export const searchAlbumRelatedAPI = async (payload) => {
  const res = await request(searchAlbumRelated, payload);

  return res;
};

export const getSearchAlbumAPI = async (payload) => {
  const res = await request(getSearchAlbum, payload);

  return res;
};

export const getAlbumOnlyTracksAPI = async (payload) => {
  const res = await request(getAlbumOnlyTracks, payload);

  return res;
};
